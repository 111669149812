import {
  UserCredential,
  GoogleAuthProvider,
  signInWithPopup,
  signOut,
  getAuth,
} from 'firebase/auth'

export async function loginWithGoogle(): Promise<UserCredential | null> {
  const provider = new GoogleAuthProvider()
  try {
    const user = await signInWithPopup(getAuth(), provider)
    return user
  } catch (error) {
    console.error('login failed', error)
    return null
  }
}

export async function logout(): Promise<void> {
  await signOut(getAuth()).catch((error) => {
    console.error('login failed', error)
  })
}
