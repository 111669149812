import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  AdditionalFees: any;
  CancelPolicyRates: any;
  ClosingDays: any;
  Json: any;
  LongStayDiscounts: any;
  OptionSets: any;
  PersonFeeArray: any;
  RoomFeeArray: any;
  Time: any;
  Timestamp: any;
  Upload: any;
};

export type AdditionalFee = {
  __typename?: 'AdditionalFee';
  name: Scalars['String'];
  fee: Scalars['Int'];
  startDay: Scalars['Timestamp'];
  endDay: Scalars['Timestamp'];
};


export type AllFeeDetail = {
  __typename?: 'AllFeeDetail';
  basicFee: Scalars['Int'];
  discountFee?: Maybe<Scalars['Int']>;
  additionalFee?: Maybe<Scalars['Int']>;
};

export type AskedQuestion = {
  __typename?: 'AskedQuestion';
  id: Scalars['ID'];
  question: Scalars['String'];
  answer: Scalars['String'];
  hotel: Hotel;
};

export type BankAccount = {
  __typename?: 'BankAccount';
  id: Scalars['ID'];
  bankCode: Scalars['String'];
  bankName: Scalars['String'];
  branchCode: Scalars['String'];
  branchName: Scalars['String'];
  accountNumber: Scalars['String'];
  accountHolderName: Scalars['String'];
  company?: Maybe<Company>;
  hotels?: Maybe<Array<Maybe<Hotel>>>;
  createdAt: Scalars['Timestamp'];
  updatedAt: Scalars['Timestamp'];
};

export type BankPayout = {
  __typename?: 'BankPayout';
  id: Scalars['ID'];
  amount: Scalars['Int'];
  bankAccount: BankAccount;
  createdAt: Scalars['Timestamp'];
};

export enum BedType {
  Single = 'SINGLE',
  SemiDouble = 'SEMI_DOUBLE',
  Double = 'DOUBLE',
  Queen = 'QUEEN',
  King = 'KING',
  Twin = 'TWIN',
  SuperKing = 'SUPER_KING'
}

export type Calendar = {
  __typename?: 'Calendar';
  dateInfos?: Maybe<Array<DateInfo>>;
};

export type CancelJammyPlanSubscriptionInput = {
  id: Scalars['ID'];
};

export type CancelPolicyRate = {
  __typename?: 'CancelPolicyRate';
  deadLineNum: Scalars['Int'];
  percentage: Scalars['Int'];
};


export type CancelReservationInput = {
  id: Scalars['ID'];
};

export enum CardBrandType {
  Visa = 'VISA',
  Mastercard = 'MASTERCARD',
  Amex = 'AMEX',
  Diners = 'DINERS',
  Jcb = 'JCB'
}

export type ChangeBankAccountInput = {
  bankCode: Scalars['String'];
  bankName: Scalars['String'];
  branchCode: Scalars['String'];
  branchName: Scalars['String'];
  accountNumber: Scalars['String'];
  accountHolderName: Scalars['String'];
};

export type ChangeBankAccountPayload = {
  __typename?: 'ChangeBankAccountPayload';
  bankAccount?: Maybe<BankAccount>;
};

export type ChangeCurrentManageHotelInput = {
  id: Scalars['ID'];
};

export type ChangeCurrentManageHotelPayload = {
  __typename?: 'ChangeCurrentManageHotelPayload';
  hotel?: Maybe<Hotel>;
};

export type ChangeReservationStatusInput = {
  id: Scalars['ID'];
  stayStatus?: Maybe<StayStatusType>;
};

export type CheckInReservationInput = {
  id: Scalars['ID'];
};

export type ClosingDay = {
  __typename?: 'ClosingDay';
  startDay: Scalars['Timestamp'];
  endDay: Scalars['Timestamp'];
};

export type ClosingDayInput = {
  startDay: Scalars['Timestamp'];
  endDay: Scalars['Timestamp'];
};


export type Company = {
  __typename?: 'Company';
  id: Scalars['ID'];
  name: Scalars['String'];
  nameFurigana: Scalars['String'];
  phoneNumber: Scalars['String'];
  email: Scalars['String'];
  prefCode: Scalars['String'];
  address: Scalars['String'];
  postalCode: Scalars['String'];
  buildingName?: Maybe<Scalars['String']>;
  bankAccounts?: Maybe<Array<BankAccount>>;
  hotelList?: Maybe<PaginateHotel>;
  operatorList?: Maybe<PaginateOperator>;
  createdAt: Scalars['Timestamp'];
  updatedAt: Scalars['Timestamp'];
};


export type CompanyHotelListArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  sortBy?: Maybe<SortType>;
};


export type CompanyOperatorListArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  sortBy?: Maybe<SortType>;
};

export enum ConfirmationMethodType {
  Auto = 'AUTO',
  Manual = 'MANUAL'
}

export type CreateAskedQuestionInput = {
  question: Scalars['String'];
  answer: Scalars['String'];
  hotelId: Scalars['ID'];
};

export type CreateAskedQuestionPayload = {
  __typename?: 'CreateAskedQuestionPayload';
  askedQuestion?: Maybe<AskedQuestion>;
};

export type CreateBankAccountInput = {
  bankCode: Scalars['String'];
  bankName: Scalars['String'];
  branchCode: Scalars['String'];
  branchName: Scalars['String'];
  accountNumber: Scalars['String'];
  accountHolderName: Scalars['String'];
  hotelId: Scalars['String'];
};

export type CreateBankAccountPayload = {
  __typename?: 'CreateBankAccountPayload';
  bankAccount?: Maybe<BankAccount>;
};

export type CreateBankPayoutInput = {
  hotelId: Scalars['String'];
  amount: Scalars['Int'];
};

export type CreateBankPayoutPayload = {
  __typename?: 'CreateBankPayoutPayload';
  result?: Maybe<Scalars['Boolean']>;
};

export type CreateCompanyInput = {
  name: Scalars['String'];
  nameFurigana: Scalars['String'];
  phoneNumber: Scalars['String'];
  email: Scalars['String'];
  prefCode: Scalars['String'];
  postalCode: Scalars['String'];
  address: Scalars['String'];
  buildingName?: Maybe<Scalars['String']>;
};

export type CreateCompanyPayload = {
  __typename?: 'CreateCompanyPayload';
  company?: Maybe<Company>;
};

export type CreateCustomerInput = {
  firebaseUserUid: Scalars['String'];
  firstName: Scalars['String'];
  firstNameFurigana: Scalars['String'];
  lastName: Scalars['String'];
  lastNameFurigana: Scalars['String'];
  postalCode: Scalars['String'];
  country: Scalars['String'];
  prefCode?: Maybe<Scalars['String']>;
  address: Scalars['String'];
  buildingName?: Maybe<Scalars['String']>;
  workPlace?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  phoneNumber: Scalars['String'];
  birthday?: Maybe<Scalars['Timestamp']>;
  gender?: Maybe<GenderType>;
  familyPhone?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  images?: Maybe<Array<Scalars['String']>>;
  status?: Maybe<CustomerStatus>;
  papers?: Maybe<Scalars['String']>;
  hotelId: Scalars['ID'];
};

export type CreateCustomerPayload = {
  __typename?: 'CreateCustomerPayload';
  customer?: Maybe<Customer>;
};

export type CreateHotelInput = {
  name: Scalars['String'];
  nameFurigana: Scalars['String'];
  url: Scalars['String'];
  type: HotelType;
  phoneNumber: Scalars['String'];
  faxNumber?: Maybe<Scalars['String']>;
  prefCode: Scalars['String'];
  postalCode: Scalars['String'];
  address: Scalars['String'];
  buildingName?: Maybe<Scalars['String']>;
  licenseNumber: Scalars['String'];
  logo?: Maybe<Scalars['String']>;
  images?: Maybe<Array<Scalars['String']>>;
  description?: Maybe<Scalars['String']>;
  isSuspend?: Maybe<Scalars['Boolean']>;
  closingDays?: Maybe<Scalars['Json']>;
  companyId: Scalars['ID'];
  operatorId?: Maybe<Scalars['ID']>;
};

export type CreateHotelPayload = {
  __typename?: 'CreateHotelPayload';
  hotel?: Maybe<Hotel>;
};

export type CreateJammyPlanInput = {
  name: Scalars['String'];
  description: Scalars['String'];
  operatorLimit?: Maybe<Scalars['Int']>;
  roomLimit?: Maybe<Scalars['Int']>;
  fee: Scalars['Int'];
};

export type CreateJammyPlanPayload = {
  __typename?: 'CreateJammyPlanPayload';
  jammyPlan?: Maybe<JammyPlan>;
};

export type CreateJammyPlanSubscriptionInput = {
  hotelId: Scalars['ID'];
  jammyPlanId: Scalars['ID'];
  status?: Maybe<JammyPlanSubscriptionStatus>;
  trialPeriodDays: Scalars['Int'];
};

export type CreateJammyPlanSubscriptionPayload = {
  __typename?: 'CreateJammyPlanSubscriptionPayload';
  jammyPlanSubscription?: Maybe<JammyPlanSubscription>;
};

export type CreateManyRoomInput = {
  createRoomInput?: Maybe<Array<CreateRoomInput>>;
};

export type CreateManyRoomPayload = {
  __typename?: 'CreateManyRoomPayload';
  room?: Maybe<Array<Room>>;
};

export type CreateNoticeInput = {
  image?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  body: Scalars['String'];
};

export type CreateNoticePayload = {
  __typename?: 'CreateNoticePayload';
  notice?: Maybe<Notice>;
};

export type CreateOperatorInput = {
  fullName: Scalars['String'];
  fullNameFurigana: Scalars['String'];
  email: Scalars['String'];
  phoneNumber: Scalars['String'];
  companyId: Scalars['ID'];
};

export type CreateOperatorPayload = {
  __typename?: 'CreateOperatorPayload';
  operator?: Maybe<Operator>;
};

export type CreateOptionInput = {
  name: Scalars['String'];
  description: Scalars['String'];
  unit: UnitType;
  fee: Scalars['Int'];
  images?: Maybe<Array<Maybe<Scalars['String']>>>;
  isPublic: Scalars['Boolean'];
  hotelId: Scalars['ID'];
};

export type CreateOptionPayload = {
  __typename?: 'CreateOptionPayload';
  option?: Maybe<Option>;
};

export type CreateOptionSet = {
  optionId: Scalars['ID'];
  name: Scalars['String'];
  unit: UnitType;
  fee: Scalars['Int'];
  number: Scalars['Int'];
};

export type CreatePaymentMethodForHotelInput = {
  hotelId: Scalars['ID'];
  token: Scalars['String'];
};

export type CreatePaymentMethodForHotelPayload = {
  __typename?: 'CreatePaymentMethodForHotelPayload';
  paymentMethodForHotel?: Maybe<PaymentMethodForHotel>;
};

export type CreatePaymentMethodInput = {
  token: Scalars['String'];
};

export type CreatePaymentMethodPayload = {
  __typename?: 'CreatePaymentMethodPayload';
  paymentMethod?: Maybe<PaymentMethod>;
};

export type CreatePlanInput = {
  name: Scalars['String'];
  description: Scalars['String'];
  images?: Maybe<Array<Scalars['String']>>;
  checkInStartTime: Scalars['Time'];
  checkInEndTime: Scalars['Time'];
  checkOutStartTime: Scalars['Time'];
  checkOutEndTime: Scalars['Time'];
  haveBreakfast: Scalars['Boolean'];
  haveDinner: Scalars['Boolean'];
  canLocalPayment?: Maybe<Scalars['Boolean']>;
  reservationConfirmationMethod?: Maybe<ConfirmationMethodType>;
  reservationDeadline?: Maybe<Scalars['Int']>;
  isPublic: Scalars['Boolean'];
  feeType: FeeType;
  cancelPolicy: Scalars['String'];
  info?: Maybe<Scalars['String']>;
  percentageForBaby: Scalars['Int'];
  percentageForChild: Scalars['Int'];
  longStayDiscounts?: Maybe<Scalars['Json']>;
  additionalFees?: Maybe<Scalars['Json']>;
  cancelPolicyRates?: Maybe<Scalars['Json']>;
  reservationStartDate?: Maybe<Scalars['Timestamp']>;
  reservationEndDate?: Maybe<Scalars['Timestamp']>;
  optionIds?: Maybe<Array<Scalars['ID']>>;
  hotelId: Scalars['ID'];
};

export type CreatePlanPayload = {
  __typename?: 'CreatePlanPayload';
  plan?: Maybe<Plan>;
};

export type CreatePlansOnOptionsInput = {
  planId: Scalars['ID'];
  optionId: Scalars['ID'];
};

export type CreatePricePairingInput = {
  planId: Scalars['ID'];
  roomTypeId: Scalars['ID'];
};

export type CreatePricePairingPayload = {
  __typename?: 'CreatePricePairingPayload';
  pricePairing?: Maybe<PricePairing>;
};

export type CreateRegularOperatorInput = {
  fullName: Scalars['String'];
  fullNameFurigana: Scalars['String'];
  email: Scalars['String'];
  phoneNumber: Scalars['String'];
  companyId: Scalars['ID'];
  hotelId: Scalars['ID'];
};

export type CreateReservationByCustomerInput = {
  checkIn: Scalars['Timestamp'];
  checkInTime: Scalars['Time'];
  checkOut: Scalars['Timestamp'];
  adultCount: Scalars['Int'];
  childCount?: Maybe<Scalars['Int']>;
  babyCount?: Maybe<Scalars['Int']>;
  roomCount: Scalars['Int'];
  firstName?: Maybe<Scalars['String']>;
  firstNameFurigana?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  lastNameFurigana?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  prefCode?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  buildingName?: Maybe<Scalars['String']>;
  workPlace?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  stayStatus?: Maybe<StayStatusType>;
  paymentType?: Maybe<PaymentType>;
  isPaid?: Maybe<Scalars['Boolean']>;
  roomId?: Maybe<Scalars['ID']>;
  planId: Scalars['ID'];
  roomTypeId: Scalars['ID'];
  paymentMethodId?: Maybe<Scalars['ID']>;
  optionSets?: Maybe<Scalars['Json']>;
};

export type CreateReservationByOperatorInput = {
  checkIn: Scalars['Timestamp'];
  checkInTime: Scalars['Time'];
  checkOut: Scalars['Timestamp'];
  adultCount: Scalars['Int'];
  childCount?: Maybe<Scalars['Int']>;
  babyCount?: Maybe<Scalars['Int']>;
  roomCount: Scalars['Int'];
  firstName: Scalars['String'];
  firstNameFurigana: Scalars['String'];
  lastName: Scalars['String'];
  lastNameFurigana: Scalars['String'];
  country?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  prefCode?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  buildingName?: Maybe<Scalars['String']>;
  workPlace?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  phoneNumber: Scalars['String'];
  stayStatus?: Maybe<StayStatusType>;
  roomId?: Maybe<Scalars['ID']>;
  planId: Scalars['ID'];
  roomTypeId: Scalars['ID'];
  hotelId: Scalars['ID'];
  optionSets?: Maybe<Scalars['Json']>;
};

export type CreateReservationPayload = {
  __typename?: 'CreateReservationPayload';
  reservation?: Maybe<Reservation>;
};

export type CreateReservationsOnRoomsInput = {
  reservationId: Scalars['ID'];
  roomIds?: Maybe<Array<Scalars['ID']>>;
};

export type CreateRoomInput = {
  name: Scalars['String'];
  roomTypeId: Scalars['ID'];
  hotelId: Scalars['ID'];
};

export type CreateRoomPayload = {
  __typename?: 'CreateRoomPayload';
  room: Room;
};

export type CreateRoomTypeInput = {
  name: Scalars['String'];
  description: Scalars['String'];
  images?: Maybe<Array<Scalars['String']>>;
  isPublic: Scalars['Boolean'];
  limit: Scalars['Int'];
  roomSize: Scalars['Float'];
  canSmoking: Scalars['Boolean'];
  bedType: BedType;
  bedNum: Scalars['Int'];
  hotelId: Scalars['ID'];
  amenityCodes?: Maybe<Array<Scalars['String']>>;
};

export type CreateRoomTypePayload = {
  __typename?: 'CreateRoomTypePayload';
  roomType?: Maybe<RoomType>;
};

export type CreateStripePersonInput = {
  firstNameKana?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastNameKana?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  birthYear?: Maybe<Scalars['Int']>;
  birthMonth?: Maybe<Scalars['Int']>;
  birthday?: Maybe<Scalars['Int']>;
  relationshipTitle?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postalCodeKana?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  town?: Maybe<Scalars['String']>;
  line1?: Maybe<Scalars['String']>;
  stateKana?: Maybe<Scalars['String']>;
  cityKana?: Maybe<Scalars['String']>;
  townKana?: Maybe<Scalars['String']>;
  line1Kana?: Maybe<Scalars['String']>;
  frontFile?: Maybe<Scalars['Upload']>;
  backFile?: Maybe<Scalars['Upload']>;
};

export type Customer = {
  __typename?: 'Customer';
  id: Scalars['ID'];
  firstName: Scalars['String'];
  firstNameFurigana: Scalars['String'];
  lastName: Scalars['String'];
  lastNameFurigana: Scalars['String'];
  postalCode: Scalars['String'];
  country: Scalars['String'];
  prefCode?: Maybe<Scalars['String']>;
  address: Scalars['String'];
  buildingName?: Maybe<Scalars['String']>;
  workPlace?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  phoneNumber: Scalars['String'];
  birthday?: Maybe<Scalars['Timestamp']>;
  gender?: Maybe<GenderType>;
  familyPhone?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  images?: Maybe<Array<Scalars['String']>>;
  status?: Maybe<CustomerStatus>;
  papers?: Maybe<Scalars['String']>;
  hotel?: Maybe<Hotel>;
  paymentMethods?: Maybe<Array<PaymentMethod>>;
  reservationList?: Maybe<PaginateReservation>;
  createdAt?: Maybe<Scalars['Timestamp']>;
  updatedAt?: Maybe<Scalars['Timestamp']>;
};


export type CustomerReservationListArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  sortBy?: Maybe<SortType>;
};

export enum CustomerStatus {
  Normal = 'NORMAL',
  DenyList = 'DENY_LIST'
}

export type DateInfo = {
  __typename?: 'DateInfo';
  date: Scalars['Timestamp'];
  vacantRoomNum: Scalars['Int'];
};

export enum DayType {
  Monday = 'MONDAY',
  Tuesday = 'TUESDAY',
  Wednesday = 'WEDNESDAY',
  Thursday = 'THURSDAY',
  Friday = 'FRIDAY',
  Saturday = 'SATURDAY',
  Sunday = 'SUNDAY',
  Holiday = 'HOLIDAY',
  BeforeHoliday = 'BEFORE_HOLIDAY'
}

export type DeleteAskedQuestionInput = {
  id: Scalars['ID'];
};

export type DeleteAskedQuestionPayload = {
  __typename?: 'DeleteAskedQuestionPayload';
  askedQuestion?: Maybe<AskedQuestion>;
};

export type DeleteCompanyInput = {
  id: Scalars['ID'];
};

export type DeleteCompanyPayload = {
  __typename?: 'DeleteCompanyPayload';
  company?: Maybe<Company>;
};

export type DeleteCustomerInput = {
  id: Scalars['ID'];
};

export type DeleteCustomerPayload = {
  __typename?: 'DeleteCustomerPayload';
  customer?: Maybe<Customer>;
};

export type DeleteHotelInput = {
  id: Scalars['ID'];
};

export type DeleteHotelPayload = {
  __typename?: 'DeleteHotelPayload';
  hotel?: Maybe<Hotel>;
};

export type DeleteJammyPlanInput = {
  id: Scalars['ID'];
};

export type DeleteJammyPlanPayload = {
  __typename?: 'DeleteJammyPlanPayload';
  jammyPlan?: Maybe<JammyPlan>;
};

export type DeleteNoticeInput = {
  id: Scalars['ID'];
};

export type DeleteNoticePayload = {
  __typename?: 'DeleteNoticePayload';
  notice?: Maybe<Notice>;
};

export type DeleteOperatorInput = {
  id: Scalars['ID'];
};

export type DeleteOperatorPayload = {
  __typename?: 'DeleteOperatorPayload';
  operator?: Maybe<Operator>;
};

export type DeleteOptionInput = {
  id: Scalars['ID'];
};

export type DeleteOptionPayload = {
  __typename?: 'DeleteOptionPayload';
  option?: Maybe<Option>;
};

export type DeletePaymentMethodForHotelInput = {
  id: Scalars['ID'];
};

export type DeletePaymentMethodForHotelPayload = {
  __typename?: 'DeletePaymentMethodForHotelPayload';
  paymentMethodForHotel?: Maybe<PaymentMethodForHotel>;
};

export type DeletePaymentMethodInput = {
  paymentMethodId: Scalars['ID'];
};

export type DeletePaymentMethodPayload = {
  __typename?: 'DeletePaymentMethodPayload';
  paymentMethod?: Maybe<PaymentMethod>;
};

export type DeletePlanInput = {
  id: Scalars['ID'];
};

export type DeletePlanPayload = {
  __typename?: 'DeletePlanPayload';
  plan?: Maybe<Plan>;
};

export type DeletePlansOnOptionsInput = {
  planId: Scalars['ID'];
  optionId: Scalars['ID'];
};

export type DeletePricePairingInput = {
  id: Scalars['ID'];
};

export type DeletePricePairingPayload = {
  __typename?: 'DeletePricePairingPayload';
  pricePairing?: Maybe<PricePairing>;
};

export type DeleteReservationInput = {
  id: Scalars['ID'];
};

export type DeleteReservationPayload = {
  __typename?: 'DeleteReservationPayload';
  reservation?: Maybe<Reservation>;
};

export type DeleteReservationsOnRoomsInput = {
  reservationId: Scalars['ID'];
  roomId: Scalars['ID'];
};

export type DeleteRoomInput = {
  id: Scalars['ID'];
};

export type DeleteRoomPayload = {
  __typename?: 'DeleteRoomPayload';
  room: Room;
};

export type DeleteRoomTypeInput = {
  id: Scalars['ID'];
};

export type DeleteRoomTypePayload = {
  __typename?: 'DeleteRoomTypePayload';
  roomType?: Maybe<RoomType>;
};

export enum FeeType {
  PerPerson = 'PER_PERSON',
  PerRoom = 'PER_ROOM'
}

export enum GenderType {
  Male = 'MALE',
  Female = 'FEMALE',
  Other = 'OTHER'
}

export type Hotel = {
  __typename?: 'Hotel';
  id: Scalars['ID'];
  name: Scalars['String'];
  nameFurigana: Scalars['String'];
  url: Scalars['String'];
  type: HotelType;
  phoneNumber: Scalars['String'];
  faxNumber?: Maybe<Scalars['String']>;
  prefCode: Scalars['String'];
  postalCode: Scalars['String'];
  address: Scalars['String'];
  buildingName?: Maybe<Scalars['String']>;
  licenseNumber: Scalars['String'];
  logo?: Maybe<Scalars['String']>;
  images?: Maybe<Array<Scalars['String']>>;
  thumbnail?: Maybe<Array<Scalars['String']>>;
  description?: Maybe<Scalars['String']>;
  isSuspend?: Maybe<Scalars['Boolean']>;
  closingDays?: Maybe<Scalars['ClosingDays']>;
  bankAccount?: Maybe<BankAccount>;
  paymentMethodsForHotel?: Maybe<Array<PaymentMethodForHotel>>;
  defaultPaymentMethodId?: Maybe<Scalars['ID']>;
  company: Company;
  jammyPlanSubscription?: Maybe<JammyPlanSubscription>;
  invoices?: Maybe<Array<Invoice>>;
  invoice?: Maybe<Invoice>;
  rateOfOperation?: Maybe<Scalars['Int']>;
  planList?: Maybe<PaginatePlan>;
  roomTypeListByCustomer?: Maybe<PaginateRoomType>;
  planListByOperator?: Maybe<PaginatePlan>;
  roomTypeList?: Maybe<PaginateRoomType>;
  reservationList?: Maybe<PaginateReservation>;
  reservationListByMonth?: Maybe<Array<Reservation>>;
  roomList?: Maybe<PaginateRoom>;
  optionList?: Maybe<PaginateOption>;
  customerList?: Maybe<PaginateCustomer>;
  monthlyIncomeList?: Maybe<PaginateMonthlyIncome>;
  monthlyIncome?: Maybe<MonthlyIncome>;
  operatorList?: Maybe<PaginateOperator>;
  askedQuestionList?: Maybe<PaginateAskedQuestion>;
  calendar?: Maybe<Calendar>;
  noticeList?: Maybe<PaginateNotice>;
  createdAt: Scalars['Timestamp'];
  updatedAt: Scalars['Timestamp'];
};


export type HotelInvoiceArgs = {
  invoiceId: Scalars['String'];
};


export type HotelPlanListArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  sortBy?: Maybe<SortType>;
  isPublic?: Maybe<Scalars['Boolean']>;
  checkIn?: Maybe<Scalars['Timestamp']>;
  checkOut?: Maybe<Scalars['Timestamp']>;
};


export type HotelRoomTypeListByCustomerArgs = {
  checkIn?: Maybe<Scalars['Timestamp']>;
  checkOut?: Maybe<Scalars['Timestamp']>;
  adultCount?: Maybe<Scalars['Int']>;
  childCount?: Maybe<Scalars['Int']>;
  babyCount?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type HotelPlanListByOperatorArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  sortBy?: Maybe<SortType>;
  isPublic?: Maybe<Scalars['Boolean']>;
  checkIn?: Maybe<Scalars['Timestamp']>;
  checkOut?: Maybe<Scalars['Timestamp']>;
};


export type HotelRoomTypeListArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  sortBy?: Maybe<SortType>;
  name?: Maybe<Scalars['String']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  checkIn?: Maybe<Scalars['Timestamp']>;
  checkOut?: Maybe<Scalars['Timestamp']>;
  adultCount?: Maybe<Scalars['Int']>;
  childCount?: Maybe<Scalars['Int']>;
  babyCount?: Maybe<Scalars['Int']>;
  roomCount?: Maybe<Scalars['Int']>;
};


export type HotelReservationListArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  sortBy?: Maybe<SortType>;
  roomTypeId?: Maybe<Scalars['ID']>;
  checkIn?: Maybe<Scalars['Timestamp']>;
  checkOut?: Maybe<Scalars['Timestamp']>;
  status?: Maybe<StayStatusType>;
};


export type HotelReservationListByMonthArgs = {
  month?: Maybe<Scalars['String']>;
};


export type HotelRoomListArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  sortBy?: Maybe<SortType>;
};


export type HotelOptionListArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  sortBy?: Maybe<SortType>;
};


export type HotelCustomerListArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  sortBy?: Maybe<SortType>;
};


export type HotelMonthlyIncomeListArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  sortBy?: Maybe<SortType>;
};


export type HotelMonthlyIncomeArgs = {
  month?: Maybe<Scalars['String']>;
};


export type HotelOperatorListArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  sortBy?: Maybe<SortType>;
};


export type HotelAskedQuestionListArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  sortBy?: Maybe<SortType>;
};


export type HotelCalendarArgs = {
  startDay: Scalars['Timestamp'];
  lastDay: Scalars['Timestamp'];
};


export type HotelNoticeListArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  sortBy?: Maybe<SortType>;
};

export enum HotelType {
  Hotel = 'HOTEL',
  Ryokan = 'RYOKAN',
  GuestHouse = 'GUEST_HOUSE',
  Minpaku = 'MINPAKU',
  Camp = 'CAMP',
  Cottage = 'COTTAGE',
  Villa = 'VILLA',
  Other = 'OTHER'
}

export type Invoice = {
  __typename?: 'Invoice';
  id: Scalars['String'];
  amount: Scalars['Int'];
  isPaid: Scalars['Boolean'];
  stripeInvoiceNum: Scalars['String'];
  paidAt?: Maybe<Scalars['Timestamp']>;
};

export type JammyPlan = {
  __typename?: 'JammyPlan';
  id: Scalars['ID'];
  name: Scalars['String'];
  description: Scalars['String'];
  operatorLimit?: Maybe<Scalars['Int']>;
  roomLimit?: Maybe<Scalars['Int']>;
  fee: Scalars['Int'];
  stripeId: Scalars['String'];
  createdAt: Scalars['Timestamp'];
  updatedAt: Scalars['Timestamp'];
};

export type JammyPlanSubscription = {
  __typename?: 'JammyPlanSubscription';
  id: Scalars['ID'];
  jammyPlan: JammyPlan;
  status: JammyPlanSubscriptionStatus;
  hotel: Hotel;
  stripeSubscriptionId: Scalars['ID'];
  cancelTargetMonth?: Maybe<Scalars['String']>;
  createdAt: Scalars['Timestamp'];
  updatedAt: Scalars['Timestamp'];
};

export enum JammyPlanSubscriptionStatus {
  Trialing = 'TRIALING',
  Active = 'ACTIVE',
  Incomplete = 'INCOMPLETE',
  PastDue = 'PAST_DUE',
  Canceled = 'CANCELED',
  Unpaid = 'UNPAID'
}


export type LoginCustomerInput = {
  hotelId: Scalars['String'];
  firebaseUserUid: Scalars['String'];
};

export type LoginCustomerPayload = {
  __typename?: 'LoginCustomerPayload';
  token?: Maybe<Scalars['String']>;
};

export type LongStayDiscount = {
  __typename?: 'LongStayDiscount';
  stayNightNum: Scalars['Int'];
  percentage: Scalars['Int'];
};


export type MonthlyIncome = {
  __typename?: 'MonthlyIncome';
  amount: Scalars['Int'];
  targetYearMonth: Scalars['String'];
  hotel: Hotel;
};

export type Mutation = {
  __typename?: 'Mutation';
  createRoomType?: Maybe<CreateRoomTypePayload>;
  updateRoomType?: Maybe<UpdateRoomTypePayload>;
  deleteRoomType?: Maybe<DeleteRoomTypePayload>;
  updateRoom?: Maybe<UpdateRoomPayload>;
  deleteRoom?: Maybe<DeleteRoomPayload>;
  createManyRoom?: Maybe<Scalars['Boolean']>;
  createPlan?: Maybe<CreatePlanPayload>;
  updatePlan?: Maybe<UpdatePlanPayload>;
  deletePlan?: Maybe<DeletePlanPayload>;
  createHotel?: Maybe<CreateHotelPayload>;
  updateHotel?: Maybe<UpdateHotelPayload>;
  deleteHotel?: Maybe<DeleteHotelPayload>;
  createOption?: Maybe<CreateOptionPayload>;
  updateOption?: Maybe<UpdateOptionPayload>;
  deleteOption?: Maybe<DeleteOptionPayload>;
  createOperator?: Maybe<CreateOperatorPayload>;
  updateOperator?: Maybe<UpdateOperatorPayload>;
  deleteOperator?: Maybe<DeleteOperatorPayload>;
  createRegularOperator?: Maybe<CreateOperatorPayload>;
  createCompany?: Maybe<CreateCompanyPayload>;
  updateCompany?: Maybe<UpdateCompanyPayload>;
  deleteCompany?: Maybe<DeleteCompanyPayload>;
  createCustomer?: Maybe<CreateCustomerPayload>;
  loginCustomer?: Maybe<LoginCustomerPayload>;
  updateCustomer?: Maybe<UpdateCustomerPayload>;
  deleteCustomer?: Maybe<DeleteCustomerPayload>;
  createReservationByCustomer?: Maybe<CreateReservationPayload>;
  createReservationByOperator?: Maybe<CreateReservationPayload>;
  updateReservation?: Maybe<UpdateReservationPayload>;
  changeReservationStatus?: Maybe<Scalars['Boolean']>;
  checkInReservation?: Maybe<Scalars['Boolean']>;
  cancelReservation?: Maybe<Scalars['Boolean']>;
  paymentReservationCharge?: Maybe<Scalars['Boolean']>;
  paymentCancelCharge?: Maybe<Scalars['Boolean']>;
  deleteReservation?: Maybe<DeleteReservationPayload>;
  createBankAccount?: Maybe<CreateBankAccountPayload>;
  changeBankAccount?: Maybe<ChangeBankAccountPayload>;
  createBankPayout?: Maybe<CreateBankPayoutPayload>;
  createPaymentMethod?: Maybe<CreatePaymentMethodPayload>;
  deletePaymentMethod?: Maybe<DeletePaymentMethodPayload>;
  createPaymentMethodForHotel?: Maybe<CreatePaymentMethodForHotelPayload>;
  deletePaymentMethodForHotel?: Maybe<DeletePaymentMethodForHotelPayload>;
  setDefaultPaymentMethodForHotel?: Maybe<Scalars['Boolean']>;
  createJammyPlan?: Maybe<CreateJammyPlanPayload>;
  updateJammyPlan?: Maybe<UpdateJammyPlanPayload>;
  deleteJammyPlan?: Maybe<DeleteJammyPlanPayload>;
  createAskedQuestion?: Maybe<CreateAskedQuestionPayload>;
  updateAskedQuestion?: Maybe<UpdateAskedQuestionPayload>;
  deleteAskedQuestion?: Maybe<DeleteAskedQuestionPayload>;
  createJammyPlanSubscription?: Maybe<CreateJammyPlanSubscriptionPayload>;
  cancelJammyPlanSubscription?: Maybe<Scalars['Boolean']>;
  selectJammyPlan?: Maybe<SelectJammyPlanPayload>;
  createPricePairing?: Maybe<CreatePricePairingPayload>;
  updatePricePairing?: Maybe<UpdatePricePairingPayload>;
  deletePricePairing?: Maybe<Scalars['Boolean']>;
  createPlansOnOptions?: Maybe<Scalars['Boolean']>;
  deletePlansOnOptions?: Maybe<Scalars['Boolean']>;
  createReservationsOnRooms?: Maybe<Scalars['Boolean']>;
  updateReservationsOnRooms?: Maybe<Scalars['Boolean']>;
  deleteReservationsOnRooms?: Maybe<Scalars['Boolean']>;
  changeCurrentManageHotel?: Maybe<ChangeCurrentManageHotelPayload>;
  updateStripeCompany?: Maybe<Scalars['Boolean']>;
  createStripePerson?: Maybe<Scalars['Boolean']>;
  uploadMultiFile?: Maybe<UploadMultiFilePayload>;
  uploadSingleFile?: Maybe<UploadSingleFilePayload>;
  createNotice?: Maybe<CreateNoticePayload>;
  updateNotice?: Maybe<UpdateNoticePayload>;
  deleteNotice?: Maybe<DeleteNoticePayload>;
};


export type MutationCreateRoomTypeArgs = {
  input: CreateRoomTypeInput;
};


export type MutationUpdateRoomTypeArgs = {
  input: UpdateRoomTypeInput;
};


export type MutationDeleteRoomTypeArgs = {
  input: DeleteRoomTypeInput;
};


export type MutationUpdateRoomArgs = {
  input: UpdateRoomInput;
};


export type MutationDeleteRoomArgs = {
  input: DeleteRoomInput;
};


export type MutationCreateManyRoomArgs = {
  input?: Maybe<Array<CreateRoomInput>>;
};


export type MutationCreatePlanArgs = {
  input: CreatePlanInput;
};


export type MutationUpdatePlanArgs = {
  input: UpdatePlanInput;
};


export type MutationDeletePlanArgs = {
  input: DeletePlanInput;
};


export type MutationCreateHotelArgs = {
  input: CreateHotelInput;
};


export type MutationUpdateHotelArgs = {
  input: UpdateHotelInput;
};


export type MutationDeleteHotelArgs = {
  input: DeleteHotelInput;
};


export type MutationCreateOptionArgs = {
  input: CreateOptionInput;
};


export type MutationUpdateOptionArgs = {
  input: UpdateOptionInput;
};


export type MutationDeleteOptionArgs = {
  input: DeleteOptionInput;
};


export type MutationCreateOperatorArgs = {
  input: CreateOperatorInput;
};


export type MutationUpdateOperatorArgs = {
  input: UpdateOperatorInput;
};


export type MutationDeleteOperatorArgs = {
  input: DeleteOperatorInput;
};


export type MutationCreateRegularOperatorArgs = {
  input: CreateRegularOperatorInput;
};


export type MutationCreateCompanyArgs = {
  input: CreateCompanyInput;
};


export type MutationUpdateCompanyArgs = {
  input: UpdateCompanyInput;
};


export type MutationDeleteCompanyArgs = {
  input: DeleteCompanyInput;
};


export type MutationCreateCustomerArgs = {
  input: CreateCustomerInput;
};


export type MutationLoginCustomerArgs = {
  input: LoginCustomerInput;
};


export type MutationUpdateCustomerArgs = {
  input: UpdateCustomerInput;
};


export type MutationDeleteCustomerArgs = {
  input: DeleteCustomerInput;
};


export type MutationCreateReservationByCustomerArgs = {
  input: CreateReservationByCustomerInput;
};


export type MutationCreateReservationByOperatorArgs = {
  input: CreateReservationByOperatorInput;
};


export type MutationUpdateReservationArgs = {
  input: UpdateReservationInput;
};


export type MutationChangeReservationStatusArgs = {
  input: ChangeReservationStatusInput;
};


export type MutationCheckInReservationArgs = {
  input?: Maybe<CheckInReservationInput>;
};


export type MutationCancelReservationArgs = {
  input?: Maybe<CancelReservationInput>;
};


export type MutationPaymentReservationChargeArgs = {
  input: PaymentReservationChargeInput;
};


export type MutationPaymentCancelChargeArgs = {
  input: PaymentReservationChargeInput;
};


export type MutationDeleteReservationArgs = {
  input: DeleteReservationInput;
};


export type MutationCreateBankAccountArgs = {
  input: CreateBankAccountInput;
};


export type MutationChangeBankAccountArgs = {
  input: ChangeBankAccountInput;
};


export type MutationCreateBankPayoutArgs = {
  input: CreateBankPayoutInput;
};


export type MutationCreatePaymentMethodArgs = {
  input: CreatePaymentMethodInput;
};


export type MutationDeletePaymentMethodArgs = {
  input: DeletePaymentMethodInput;
};


export type MutationCreatePaymentMethodForHotelArgs = {
  input: CreatePaymentMethodForHotelInput;
};


export type MutationDeletePaymentMethodForHotelArgs = {
  input: DeletePaymentMethodForHotelInput;
};


export type MutationSetDefaultPaymentMethodForHotelArgs = {
  input: SetDefaultPaymentMethodInput;
};


export type MutationCreateJammyPlanArgs = {
  input: CreateJammyPlanInput;
};


export type MutationUpdateJammyPlanArgs = {
  input: UpdateJammyPlanInput;
};


export type MutationDeleteJammyPlanArgs = {
  input: DeleteJammyPlanInput;
};


export type MutationCreateAskedQuestionArgs = {
  input: CreateAskedQuestionInput;
};


export type MutationUpdateAskedQuestionArgs = {
  input: UpdateAskedQuestionInput;
};


export type MutationDeleteAskedQuestionArgs = {
  input: DeleteAskedQuestionInput;
};


export type MutationCreateJammyPlanSubscriptionArgs = {
  input: CreateJammyPlanSubscriptionInput;
};


export type MutationCancelJammyPlanSubscriptionArgs = {
  input: CancelJammyPlanSubscriptionInput;
};


export type MutationSelectJammyPlanArgs = {
  input: SelectJammyPlanInput;
};


export type MutationCreatePricePairingArgs = {
  input: CreatePricePairingInput;
};


export type MutationUpdatePricePairingArgs = {
  input: UpdatePricePairingInput;
};


export type MutationDeletePricePairingArgs = {
  input: DeletePricePairingInput;
};


export type MutationCreatePlansOnOptionsArgs = {
  input: CreatePlansOnOptionsInput;
};


export type MutationDeletePlansOnOptionsArgs = {
  input: DeletePlansOnOptionsInput;
};


export type MutationCreateReservationsOnRoomsArgs = {
  input: CreateReservationsOnRoomsInput;
};


export type MutationUpdateReservationsOnRoomsArgs = {
  input: UpdateReservationsOnRoomsInput;
};


export type MutationDeleteReservationsOnRoomsArgs = {
  input: DeleteReservationsOnRoomsInput;
};


export type MutationChangeCurrentManageHotelArgs = {
  input: ChangeCurrentManageHotelInput;
};


export type MutationUpdateStripeCompanyArgs = {
  input?: Maybe<UpdateStripeCompanyInput>;
};


export type MutationCreateStripePersonArgs = {
  input?: Maybe<CreateStripePersonInput>;
};


export type MutationUploadMultiFileArgs = {
  input: UploadMultiFileInput;
};


export type MutationUploadSingleFileArgs = {
  input: UploadSingleFileInput;
};


export type MutationCreateNoticeArgs = {
  input: CreateNoticeInput;
};


export type MutationUpdateNoticeArgs = {
  input: UpdateNoticeInput;
};


export type MutationDeleteNoticeArgs = {
  input: DeleteNoticeInput;
};

export type Notice = {
  __typename?: 'Notice';
  id: Scalars['ID'];
  createdAt: Scalars['Timestamp'];
  updatedAt: Scalars['Timestamp'];
  publishedAt: Scalars['Timestamp'];
  image?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  body: Scalars['String'];
};

export type Operator = {
  __typename?: 'Operator';
  id: Scalars['ID'];
  fullName: Scalars['String'];
  fullNameFurigana: Scalars['String'];
  email: Scalars['String'];
  phoneNumber: Scalars['String'];
  stripeAccountBalance?: Maybe<Scalars['Int']>;
  stripeAccount?: Maybe<StripeAccount>;
  company: Company;
  paymentMethod?: Maybe<PaymentMethodForHotel>;
  hotelList?: Maybe<PaginateHotel>;
  bankPayoutList?: Maybe<PaginateBankPayout>;
  createdAt: Scalars['Timestamp'];
  updatedAt: Scalars['Timestamp'];
};


export type OperatorPaymentMethodArgs = {
  currentManageHotelId: Scalars['ID'];
};


export type OperatorHotelListArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  sortBy?: Maybe<SortType>;
};

export enum OperatorRole {
  Admin = 'ADMIN',
  Normal = 'NORMAL'
}

export type Option = {
  __typename?: 'Option';
  id: Scalars['ID'];
  name: Scalars['String'];
  description: Scalars['String'];
  unit: UnitType;
  fee: Scalars['Int'];
  images?: Maybe<Array<Scalars['String']>>;
  isPublic: Scalars['Boolean'];
  plan?: Maybe<Plan>;
  createdAt: Scalars['Timestamp'];
  updatedAt: Scalars['Timestamp'];
};

export type OptionSet = {
  __typename?: 'OptionSet';
  optionId: Scalars['ID'];
  name: Scalars['String'];
  unit: UnitType;
  fee: Scalars['Int'];
  number: Scalars['Int'];
};


export type PaginateAskedQuestion = {
  __typename?: 'PaginateAskedQuestion';
  items: Array<AskedQuestion>;
  total: Scalars['Int'];
};

export type PaginateBankAccount = {
  __typename?: 'PaginateBankAccount';
  items?: Maybe<Array<BankAccount>>;
  total: Scalars['Int'];
};

export type PaginateBankPayout = {
  __typename?: 'PaginateBankPayout';
  items: Array<BankPayout>;
  total: Scalars['Int'];
};

export type PaginateCompany = {
  __typename?: 'PaginateCompany';
  items: Array<Company>;
  total: Scalars['Int'];
};

export type PaginateCustomer = {
  __typename?: 'PaginateCustomer';
  items: Array<Customer>;
  total: Scalars['Int'];
};

export type PaginateHotel = {
  __typename?: 'PaginateHotel';
  items: Array<Hotel>;
  total: Scalars['Int'];
};

export type PaginateJammyPlan = {
  __typename?: 'PaginateJammyPlan';
  items: Array<JammyPlan>;
  total: Scalars['Int'];
};

export type PaginateMonthlyIncome = {
  __typename?: 'PaginateMonthlyIncome';
  items: Array<MonthlyIncome>;
  total: Scalars['Int'];
};

export type PaginateNotice = {
  __typename?: 'PaginateNotice';
  items: Array<Notice>;
  total: Scalars['Int'];
};

export type PaginateOperator = {
  __typename?: 'PaginateOperator';
  items: Array<Operator>;
  total: Scalars['Int'];
};

export type PaginateOption = {
  __typename?: 'PaginateOption';
  items?: Maybe<Array<Option>>;
  total: Scalars['Int'];
};

export type PaginatePlan = {
  __typename?: 'PaginatePlan';
  items: Array<Plan>;
  total: Scalars['Int'];
};

export type PaginateReservation = {
  __typename?: 'PaginateReservation';
  items: Array<Reservation>;
  total: Scalars['Int'];
};

export type PaginateRoom = {
  __typename?: 'PaginateRoom';
  items: Array<Room>;
  total: Scalars['Int'];
};

export type PaginateRoomType = {
  __typename?: 'PaginateRoomType';
  items: Array<RoomType>;
  total: Scalars['Int'];
};

export type PaymentMethod = {
  __typename?: 'PaymentMethod';
  id: Scalars['ID'];
  brand: CardBrandType;
  last4: Scalars['String'];
  stripeId: Scalars['String'];
  createdAt: Scalars['Timestamp'];
  updatedAt: Scalars['Timestamp'];
};

export type PaymentMethodForHotel = {
  __typename?: 'PaymentMethodForHotel';
  id: Scalars['ID'];
  brand: CardBrandType;
  last4: Scalars['String'];
  stripeId: Scalars['String'];
  createdAt: Scalars['Timestamp'];
  updatedAt: Scalars['Timestamp'];
};

export type PaymentReservationChargeInput = {
  reservationId?: Maybe<Scalars['String']>;
};

export enum PaymentType {
  CardPayment = 'CARD_PAYMENT',
  LocalPayment = 'LOCAL_PAYMENT'
}

export type PersonFee = {
  __typename?: 'PersonFee';
  targetDay: DayType;
  number: Scalars['Int'];
  fee: Scalars['Int'];
};


export type Plan = {
  __typename?: 'Plan';
  id: Scalars['ID'];
  name: Scalars['String'];
  description: Scalars['String'];
  images?: Maybe<Array<Scalars['String']>>;
  checkInStartTime: Scalars['Time'];
  checkInEndTime: Scalars['Time'];
  checkOutStartTime: Scalars['Time'];
  checkOutEndTime: Scalars['Time'];
  haveBreakfast: Scalars['Boolean'];
  haveDinner: Scalars['Boolean'];
  viewCount?: Maybe<Scalars['Int']>;
  isPublic: Scalars['Boolean'];
  feeType: FeeType;
  cancelPolicy: Scalars['String'];
  info?: Maybe<Scalars['String']>;
  canLocalPayment?: Maybe<Scalars['Boolean']>;
  reservationStartDate?: Maybe<Scalars['Timestamp']>;
  reservationEndDate?: Maybe<Scalars['Timestamp']>;
  options?: Maybe<Array<Option>>;
  roomTypeList?: Maybe<PaginateRoomType>;
  percentageForBaby: Scalars['Int'];
  percentageForChild: Scalars['Int'];
  longStayDiscounts?: Maybe<Scalars['LongStayDiscounts']>;
  additionalFees?: Maybe<Scalars['AdditionalFees']>;
  cancelPolicyRates?: Maybe<Scalars['CancelPolicyRates']>;
  reservationConfirmationMethod: ConfirmationMethodType;
  reservationDeadline?: Maybe<Scalars['Int']>;
  pricePairings?: Maybe<Array<PricePairing>>;
  createdAt: Scalars['Timestamp'];
  updatedAt: Scalars['Timestamp'];
};


export type PlanRoomTypeListArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  sortBy?: Maybe<SortType>;
  name?: Maybe<Scalars['String']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  checkIn?: Maybe<Scalars['Timestamp']>;
  checkOut?: Maybe<Scalars['Timestamp']>;
  adultCount?: Maybe<Scalars['Int']>;
  childCount?: Maybe<Scalars['Int']>;
  babyCount?: Maybe<Scalars['Int']>;
  roomCount?: Maybe<Scalars['Int']>;
};


export type PlanPricePairingsArgs = {
  adultCount?: Maybe<Scalars['Int']>;
  childCount?: Maybe<Scalars['Int']>;
  babyCount?: Maybe<Scalars['Int']>;
};

export type PricePairing = {
  __typename?: 'PricePairing';
  id: Scalars['ID'];
  roomFeeArray?: Maybe<Scalars['RoomFeeArray']>;
  personFeeArray?: Maybe<Scalars['PersonFeeArray']>;
  calculateTotalFee?: Maybe<Scalars['Int']>;
  plan?: Maybe<Plan>;
  roomType?: Maybe<RoomType>;
};


export type PricePairingCalculateTotalFeeArgs = {
  checkIn?: Maybe<Scalars['Timestamp']>;
  checkOut?: Maybe<Scalars['Timestamp']>;
  adultCount?: Maybe<Scalars['Int']>;
  childCount?: Maybe<Scalars['Int']>;
  babyCount?: Maybe<Scalars['Int']>;
  roomCount?: Maybe<Scalars['Int']>;
};

export type Query = {
  __typename?: 'Query';
  plan?: Maybe<Plan>;
  roomType?: Maybe<RoomType>;
  room?: Maybe<Room>;
  operator?: Maybe<Operator>;
  currentOperator?: Maybe<Operator>;
  calculateTotalFee?: Maybe<Scalars['Int']>;
  getAllFeeDetail?: Maybe<AllFeeDetail>;
  option?: Maybe<Option>;
  currentCustomer?: Maybe<Customer>;
  customer?: Maybe<Customer>;
  reservation?: Maybe<Reservation>;
  hotel?: Maybe<Hotel>;
  hotelList?: Maybe<PaginateHotel>;
  company?: Maybe<Company>;
  companyList?: Maybe<PaginateCompany>;
  monthlyIncome?: Maybe<MonthlyIncome>;
  monthlyIncomeList?: Maybe<PaginateMonthlyIncome>;
  jammyPlan?: Maybe<JammyPlan>;
  jammyPlanList?: Maybe<PaginateJammyPlan>;
  pricePairing?: Maybe<PricePairing>;
  notice?: Maybe<Notice>;
  noticeList?: Maybe<PaginateNotice>;
};


export type QueryPlanArgs = {
  id: Scalars['ID'];
};


export type QueryRoomTypeArgs = {
  id: Scalars['ID'];
};


export type QueryRoomArgs = {
  id: Scalars['ID'];
};


export type QueryOperatorArgs = {
  id: Scalars['ID'];
};


export type QueryCalculateTotalFeeArgs = {
  roomTypeId: Scalars['ID'];
  planId: Scalars['ID'];
  roomCount?: Maybe<Scalars['Int']>;
  babyCount?: Maybe<Scalars['Int']>;
  childCount?: Maybe<Scalars['Int']>;
  adultCount?: Maybe<Scalars['Int']>;
  checkOut?: Maybe<Scalars['Timestamp']>;
  checkIn?: Maybe<Scalars['Timestamp']>;
};


export type QueryGetAllFeeDetailArgs = {
  roomTypeId: Scalars['ID'];
  planId: Scalars['ID'];
  roomCount?: Maybe<Scalars['Int']>;
  babyCount?: Maybe<Scalars['Int']>;
  childCount?: Maybe<Scalars['Int']>;
  adultCount?: Maybe<Scalars['Int']>;
  checkOut?: Maybe<Scalars['Timestamp']>;
  checkIn?: Maybe<Scalars['Timestamp']>;
};


export type QueryOptionArgs = {
  id: Scalars['ID'];
};


export type QueryCustomerArgs = {
  id: Scalars['ID'];
};


export type QueryReservationArgs = {
  id: Scalars['ID'];
};


export type QueryHotelArgs = {
  id: Scalars['ID'];
};


export type QueryHotelListArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  sortBy?: Maybe<SortType>;
};


export type QueryCompanyArgs = {
  id: Scalars['ID'];
};


export type QueryCompanyListArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  sortBy?: Maybe<SortType>;
};


export type QueryMonthlyIncomeArgs = {
  id: Scalars['ID'];
};


export type QueryMonthlyIncomeListArgs = {
  hotelId: Scalars['ID'];
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  sortBy?: Maybe<SortType>;
};


export type QueryJammyPlanArgs = {
  id: Scalars['ID'];
};


export type QueryJammyPlanListArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  sortBy?: Maybe<SortType>;
};


export type QueryPricePairingArgs = {
  id: Scalars['ID'];
};


export type QueryNoticeArgs = {
  id: Scalars['ID'];
};


export type QueryNoticeListArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  sortBy?: Maybe<SortType>;
};

export type Reservation = {
  __typename?: 'Reservation';
  id: Scalars['ID'];
  checkIn: Scalars['Timestamp'];
  checkInTime: Scalars['Time'];
  checkOut: Scalars['Timestamp'];
  totalFee: Scalars['Int'];
  cancelFee?: Maybe<Scalars['Int']>;
  adultCount: Scalars['Int'];
  childCount?: Maybe<Scalars['Int']>;
  babyCount?: Maybe<Scalars['Int']>;
  roomCount: Scalars['Int'];
  firstName?: Maybe<Scalars['String']>;
  firstNameFurigana?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  lastNameFurigana?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  prefCode?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  buildingName?: Maybe<Scalars['String']>;
  workPlace?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  stayStatus?: Maybe<StayStatusType>;
  paymentType?: Maybe<PaymentType>;
  isPaid?: Maybe<Scalars['Boolean']>;
  rooms?: Maybe<Array<Room>>;
  plan: Plan;
  roomType: RoomType;
  optionSets?: Maybe<Scalars['OptionSets']>;
  customer?: Maybe<Customer>;
  paymentMethod?: Maybe<PaymentMethod>;
  createdAt: Scalars['Timestamp'];
  updatedAt: Scalars['Timestamp'];
  closedAt?: Maybe<Scalars['Timestamp']>;
};

export type Room = {
  __typename?: 'Room';
  id: Scalars['ID'];
  name: Scalars['String'];
  roomType: RoomType;
  createdAt: Scalars['Timestamp'];
  updatedAt: Scalars['Timestamp'];
};

export type RoomFee = {
  __typename?: 'RoomFee';
  targetDay: DayType;
  fee: Scalars['Int'];
};


export type RoomType = {
  __typename?: 'RoomType';
  id: Scalars['ID'];
  name: Scalars['String'];
  description: Scalars['String'];
  images?: Maybe<Array<Scalars['String']>>;
  isPublic: Scalars['Boolean'];
  limit: Scalars['Int'];
  roomSize: Scalars['Float'];
  canSmoking: Scalars['Boolean'];
  bedType: BedType;
  bedNum: Scalars['Int'];
  vacantRoomNum?: Maybe<Scalars['Int']>;
  plans?: Maybe<Array<Maybe<Plan>>>;
  rooms?: Maybe<Array<Maybe<Room>>>;
  amenityCodes?: Maybe<Array<Scalars['String']>>;
  pricePairings?: Maybe<Array<Maybe<PricePairing>>>;
  createdAt: Scalars['Timestamp'];
  updatedAt: Scalars['Timestamp'];
};


export type RoomTypeVacantRoomNumArgs = {
  checkIn?: Maybe<Scalars['Timestamp']>;
  checkOut?: Maybe<Scalars['Timestamp']>;
};

export type SelectJammyPlanInput = {
  hotelId: Scalars['ID'];
  jammyPlanId: Scalars['ID'];
};

export type SelectJammyPlanPayload = {
  __typename?: 'SelectJammyPlanPayload';
  result?: Maybe<Scalars['Boolean']>;
};

export type SetDefaultPaymentMethodInput = {
  hotelId: Scalars['ID'];
  paymentMethodId: Scalars['ID'];
};

export enum SortType {
  Asc = 'ASC',
  Desc = 'DESC'
}

export enum StatusType {
  Active = 'ACTIVE',
  Deactivate = 'DEACTIVATE',
  Maintenance = 'MAINTENANCE'
}

export enum StayStatusType {
  TemporaryReservation = 'TEMPORARY_RESERVATION',
  Confirm = 'CONFIRM',
  BeforeCheckIn = 'BEFORE_CHECK_IN',
  DuringStay = 'DURING_STAY',
  AfterCheckOut = 'AFTER_CHECK_OUT',
  Cancel = 'CANCEL',
  NoShow = 'NO_SHOW'
}

export type StripeAccount = {
  __typename?: 'StripeAccount';
  balance?: Maybe<StripeBalance>;
  hasCompany?: Maybe<Scalars['Boolean']>;
  hasPerson?: Maybe<Scalars['Boolean']>;
};

export type StripeBalance = {
  __typename?: 'StripeBalance';
  pending?: Maybe<Scalars['Int']>;
  available?: Maybe<Scalars['Int']>;
};



export enum UnitType {
  Person = 'PERSON',
  Group = 'GROUP',
  StayNight = 'STAY_NIGHT',
  Hour = 'HOUR',
  Times = 'TIMES'
}

export type UpdateAskedQuestionInput = {
  id: Scalars['ID'];
  question?: Maybe<Scalars['String']>;
  answer?: Maybe<Scalars['String']>;
};

export type UpdateAskedQuestionPayload = {
  __typename?: 'UpdateAskedQuestionPayload';
  question?: Maybe<Scalars['String']>;
};

export type UpdateCompanyInput = {
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  nameFurigana?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  prefCode?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  buildingName?: Maybe<Scalars['String']>;
};

export type UpdateCompanyPayload = {
  __typename?: 'UpdateCompanyPayload';
  company?: Maybe<Company>;
};

export type UpdateCustomerInput = {
  firstName?: Maybe<Scalars['String']>;
  firstNameFurigana?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  lastNameFurigana?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  prefCode?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  buildingName?: Maybe<Scalars['String']>;
  workPlace?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  birthday?: Maybe<Scalars['Timestamp']>;
  gender?: Maybe<GenderType>;
  familyPhone?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  images?: Maybe<Array<Scalars['String']>>;
  status?: Maybe<CustomerStatus>;
};

export type UpdateCustomerPayload = {
  __typename?: 'UpdateCustomerPayload';
  customer?: Maybe<Customer>;
};

export type UpdateHotelInput = {
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  nameFurigana?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  type?: Maybe<HotelType>;
  phoneNumber?: Maybe<Scalars['String']>;
  faxNumber?: Maybe<Scalars['String']>;
  prefCode?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  buildingName?: Maybe<Scalars['String']>;
  licenseNumber?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  images?: Maybe<Array<Scalars['String']>>;
  description?: Maybe<Scalars['String']>;
  isSuspend?: Maybe<Scalars['Boolean']>;
  closingDays?: Maybe<Scalars['Json']>;
  operatorId?: Maybe<Scalars['ID']>;
  defaultPaymentMethodId?: Maybe<Scalars['ID']>;
};

export type UpdateHotelPayload = {
  __typename?: 'UpdateHotelPayload';
  hotel?: Maybe<Hotel>;
};

export type UpdateJammyPlanInput = {
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  operatorLimit?: Maybe<Scalars['Int']>;
  roomLimit?: Maybe<Scalars['Int']>;
  fee?: Maybe<Scalars['Int']>;
};

export type UpdateJammyPlanPayload = {
  __typename?: 'UpdateJammyPlanPayload';
  jammyPlan?: Maybe<JammyPlan>;
};

export type UpdateJammyPlanSubscriptionInput = {
  id: Scalars['ID'];
  jammyPlanId?: Maybe<Scalars['ID']>;
  cancelTargetMonth?: Maybe<Scalars['String']>;
  status?: Maybe<JammyPlanSubscriptionStatus>;
};

export type UpdateJammyPlanSubscriptionPayload = {
  __typename?: 'UpdateJammyPlanSubscriptionPayload';
  jammyPlanSubscription?: Maybe<JammyPlanSubscription>;
};

export type UpdateNoticeInput = {
  id: Scalars['ID'];
  image?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
};

export type UpdateNoticePayload = {
  __typename?: 'UpdateNoticePayload';
  notice?: Maybe<Notice>;
};

export type UpdateOperatorInput = {
  id: Scalars['ID'];
  fullName?: Maybe<Scalars['String']>;
  fullNameFurigana?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  companyId?: Maybe<Scalars['ID']>;
};

export type UpdateOperatorPayload = {
  __typename?: 'UpdateOperatorPayload';
  operator?: Maybe<Operator>;
};

export type UpdateOptionInput = {
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  unit?: Maybe<UnitType>;
  fee?: Maybe<Scalars['Int']>;
  images?: Maybe<Array<Maybe<Scalars['String']>>>;
  isPublic?: Maybe<Scalars['Boolean']>;
};

export type UpdateOptionPayload = {
  __typename?: 'UpdateOptionPayload';
  option?: Maybe<Option>;
};

export type UpdatePlanInput = {
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  images?: Maybe<Array<Scalars['String']>>;
  checkInStartTime?: Maybe<Scalars['Time']>;
  checkInEndTime?: Maybe<Scalars['Time']>;
  checkOutStartTime?: Maybe<Scalars['Time']>;
  checkOutEndTime?: Maybe<Scalars['Time']>;
  haveBreakfast?: Maybe<Scalars['Boolean']>;
  haveDinner?: Maybe<Scalars['Boolean']>;
  canLocalPayment?: Maybe<Scalars['Boolean']>;
  reservationConfirmationMethod?: Maybe<ConfirmationMethodType>;
  reservationDeadline?: Maybe<Scalars['Int']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  feeType?: Maybe<FeeType>;
  cancelPolicy?: Maybe<Scalars['String']>;
  info?: Maybe<Scalars['String']>;
  reservationStartDate?: Maybe<Scalars['Timestamp']>;
  reservationEndDate?: Maybe<Scalars['Timestamp']>;
  percentageForBaby?: Maybe<Scalars['Int']>;
  percentageForChild?: Maybe<Scalars['Int']>;
  longStayDiscounts?: Maybe<Scalars['Json']>;
  additionalFees?: Maybe<Scalars['Json']>;
  cancelPolicyRates?: Maybe<Scalars['Json']>;
  optionIds?: Maybe<Array<Scalars['ID']>>;
};

export type UpdatePlanPayload = {
  __typename?: 'UpdatePlanPayload';
  plan?: Maybe<Plan>;
};

export type UpdatePricePairingInput = {
  id: Scalars['ID'];
  roomFeeArray?: Maybe<Scalars['Json']>;
  personFeeArray?: Maybe<Scalars['Json']>;
};

export type UpdatePricePairingPayload = {
  __typename?: 'UpdatePricePairingPayload';
  pricePairing?: Maybe<PricePairing>;
};

export type UpdateReservationInput = {
  id: Scalars['ID'];
  checkIn?: Maybe<Scalars['Timestamp']>;
  checkInTime?: Maybe<Scalars['Time']>;
  checkOut?: Maybe<Scalars['Timestamp']>;
  adultCount?: Maybe<Scalars['Int']>;
  childCount?: Maybe<Scalars['Int']>;
  babyCount?: Maybe<Scalars['Int']>;
  roomCount?: Maybe<Scalars['Int']>;
  stayStatus?: Maybe<StayStatusType>;
  paymentType?: Maybe<PaymentType>;
  isPaid?: Maybe<Scalars['Boolean']>;
  roomId?: Maybe<Scalars['ID']>;
  planId?: Maybe<Scalars['ID']>;
  roomTypeId?: Maybe<Scalars['ID']>;
  customerId?: Maybe<Scalars['ID']>;
  firstName?: Maybe<Scalars['String']>;
  firstNameFurigana?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  lastNameFurigana?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  prefCode?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  buildingName?: Maybe<Scalars['String']>;
  workPlace?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
};

export type UpdateReservationPayload = {
  __typename?: 'UpdateReservationPayload';
  reservation?: Maybe<Reservation>;
};

export type UpdateReservationsOnRoomsInput = {
  reservationId: Scalars['ID'];
  roomIds?: Maybe<Array<Scalars['ID']>>;
};

export type UpdateRoomInput = {
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  roomTypeId?: Maybe<Scalars['ID']>;
};

export type UpdateRoomPayload = {
  __typename?: 'UpdateRoomPayload';
  room: Room;
};

export type UpdateRoomTypeInput = {
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  images?: Maybe<Array<Scalars['String']>>;
  isPublic?: Maybe<Scalars['Boolean']>;
  limit?: Maybe<Scalars['Int']>;
  roomSize?: Maybe<Scalars['Float']>;
  canSmoking?: Maybe<Scalars['Boolean']>;
  bedType?: Maybe<BedType>;
  bedNum?: Maybe<Scalars['Int']>;
  amenityCodes?: Maybe<Array<Scalars['String']>>;
};

export type UpdateRoomTypePayload = {
  __typename?: 'UpdateRoomTypePayload';
  roomType?: Maybe<RoomType>;
};

export type UpdateStripeCompanyInput = {
  name?: Maybe<Scalars['String']>;
  nameKana?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postalCodeKana?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  town?: Maybe<Scalars['String']>;
  line1?: Maybe<Scalars['String']>;
  stateKana?: Maybe<Scalars['String']>;
  cityKana?: Maybe<Scalars['String']>;
  townKana?: Maybe<Scalars['String']>;
  line1Kana?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  taxId?: Maybe<Scalars['String']>;
  tosAcceptanceIP?: Maybe<Scalars['String']>;
  tosAcceptanceDate?: Maybe<Scalars['Timestamp']>;
};


export type UploadMultiFileInput = {
  hotelId: Scalars['String'];
  files?: Maybe<Array<Scalars['Upload']>>;
};

export type UploadMultiFilePayload = {
  __typename?: 'UploadMultiFilePayload';
  fileUrls?: Maybe<Array<Scalars['String']>>;
};

export type UploadSingleFileInput = {
  hotelId: Scalars['String'];
  file: Scalars['Upload'];
};

export type UploadSingleFilePayload = {
  __typename?: 'UploadSingleFilePayload';
  fileUrl: Scalars['String'];
};

export type OptionSelectListQueryVariables = Exact<{
  hotelId: Scalars['ID'];
}>;


export type OptionSelectListQuery = (
  { __typename?: 'Query' }
  & { hotel?: Maybe<(
    { __typename?: 'Hotel' }
    & Pick<Hotel, 'id'>
    & { optionList?: Maybe<(
      { __typename?: 'PaginateOption' }
      & { items?: Maybe<Array<(
        { __typename?: 'Option' }
        & Pick<Option, 'id' | 'name'>
      )>> }
    )> }
  )> }
);

export type UploadFileMutationVariables = Exact<{
  uploadSingleFileInput: UploadSingleFileInput;
}>;


export type UploadFileMutation = (
  { __typename?: 'Mutation' }
  & { uploadSingleFile?: Maybe<(
    { __typename?: 'UploadSingleFilePayload' }
    & Pick<UploadSingleFilePayload, 'fileUrl'>
  )> }
);

export type UploadMultiFileMutationVariables = Exact<{
  uploadMultiFileInput: UploadMultiFileInput;
}>;


export type UploadMultiFileMutation = (
  { __typename?: 'Mutation' }
  & { uploadMultiFile?: Maybe<(
    { __typename?: 'UploadMultiFilePayload' }
    & Pick<UploadMultiFilePayload, 'fileUrls'>
  )> }
);

export type PlanListItemFragment = (
  { __typename?: 'Plan' }
  & Pick<Plan, 'id' | 'name' | 'description' | 'feeType' | 'isPublic'>
);

export type PlanDetailModalFragment = (
  { __typename?: 'Plan' }
  & Pick<Plan, 'id' | 'name' | 'description' | 'cancelPolicy' | 'images' | 'haveBreakfast' | 'haveDinner' | 'checkInStartTime' | 'checkInEndTime' | 'checkOutStartTime' | 'checkOutEndTime' | 'cancelPolicyRates'>
);

export type GetReservationRoomListQueryVariables = Exact<{
  hotelId: Scalars['ID'];
  roomTypeId?: Maybe<Scalars['ID']>;
  checkIn?: Maybe<Scalars['Timestamp']>;
  checkOut?: Maybe<Scalars['Timestamp']>;
}>;


export type GetReservationRoomListQuery = (
  { __typename?: 'Query' }
  & { hotel?: Maybe<(
    { __typename?: 'Hotel' }
    & { reservationList?: Maybe<(
      { __typename?: 'PaginateReservation' }
      & { items: Array<(
        { __typename?: 'Reservation' }
        & Pick<Reservation, 'id'>
        & { rooms?: Maybe<Array<(
          { __typename?: 'Room' }
          & Pick<Room, 'id' | 'name'>
        )>> }
      )> }
    )> }
  )> }
);

export type OptionListItemFragment = (
  { __typename?: 'Option' }
  & Pick<Option, 'id' | 'name' | 'unit' | 'fee'>
);

export type GetCompanyHotelListQueryVariables = Exact<{
  companyId: Scalars['ID'];
}>;


export type GetCompanyHotelListQuery = (
  { __typename?: 'Query' }
  & { company?: Maybe<(
    { __typename?: 'Company' }
    & Pick<Company, 'id'>
    & { hotelList?: Maybe<(
      { __typename?: 'PaginateHotel' }
      & { items: Array<(
        { __typename?: 'Hotel' }
        & Pick<Hotel, 'id' | 'name'>
      )> }
    )> }
  )> }
);

export type GetCurrentOperatorHotelListQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCurrentOperatorHotelListQuery = (
  { __typename?: 'Query' }
  & { currentOperator?: Maybe<(
    { __typename?: 'Operator' }
    & Pick<Operator, 'id'>
    & { hotelList?: Maybe<(
      { __typename?: 'PaginateHotel' }
      & { items: Array<(
        { __typename?: 'Hotel' }
        & Pick<Hotel, 'id' | 'name'>
      )> }
    )> }
  )> }
);

export type PlanSelectListQueryVariables = Exact<{
  hotelId: Scalars['ID'];
}>;


export type PlanSelectListQuery = (
  { __typename?: 'Query' }
  & { hotel?: Maybe<(
    { __typename?: 'Hotel' }
    & Pick<Hotel, 'id'>
    & { planList?: Maybe<(
      { __typename?: 'PaginatePlan' }
      & { items: Array<(
        { __typename?: 'Plan' }
        & Pick<Plan, 'id' | 'name'>
      )> }
    )> }
  )> }
);

export type RoomSelectListQueryVariables = Exact<{
  hotelId: Scalars['ID'];
}>;


export type RoomSelectListQuery = (
  { __typename?: 'Query' }
  & { hotel?: Maybe<(
    { __typename?: 'Hotel' }
    & Pick<Hotel, 'id'>
    & { roomList?: Maybe<(
      { __typename?: 'PaginateRoom' }
      & { items: Array<(
        { __typename?: 'Room' }
        & Pick<Room, 'id' | 'name'>
      )> }
    )> }
  )> }
);

export type RoomTypeSelectListQueryVariables = Exact<{
  hotelId: Scalars['ID'];
}>;


export type RoomTypeSelectListQuery = (
  { __typename?: 'Query' }
  & { hotel?: Maybe<(
    { __typename?: 'Hotel' }
    & Pick<Hotel, 'id'>
    & { roomTypeList?: Maybe<(
      { __typename?: 'PaginateRoomType' }
      & { items: Array<(
        { __typename?: 'RoomType' }
        & Pick<RoomType, 'id' | 'name'>
      )> }
    )> }
  )> }
);

export type StripeAccountBalanceFragment = (
  { __typename?: 'StripeAccount' }
  & { balance?: Maybe<(
    { __typename?: 'StripeBalance' }
    & Pick<StripeBalance, 'pending' | 'available'>
  )> }
);

export type BankPayoutDetailFragment = (
  { __typename?: 'BankPayout' }
  & Pick<BankPayout, 'id' | 'amount' | 'createdAt'>
  & { bankAccount: (
    { __typename?: 'BankAccount' }
    & Pick<BankAccount, 'id' | 'bankName' | 'branchName'>
  ) }
);

export type CreateBankPayoutMutationVariables = Exact<{
  createBankPayoutInput: CreateBankPayoutInput;
}>;


export type CreateBankPayoutMutation = (
  { __typename?: 'Mutation' }
  & { createBankPayout?: Maybe<(
    { __typename?: 'CreateBankPayoutPayload' }
    & Pick<CreateBankPayoutPayload, 'result'>
  )> }
);

export type GetStripeAccountBalanceQueryVariables = Exact<{ [key: string]: never; }>;


export type GetStripeAccountBalanceQuery = (
  { __typename?: 'Query' }
  & { currentOperator?: Maybe<(
    { __typename?: 'Operator' }
    & Pick<Operator, 'id'>
    & { stripeAccount?: Maybe<(
      { __typename?: 'StripeAccount' }
      & StripeAccountBalanceFragment
    )>, bankPayoutList?: Maybe<(
      { __typename?: 'PaginateBankPayout' }
      & { items: Array<(
        { __typename?: 'BankPayout' }
        & BankPayoutDetailFragment
      )> }
    )> }
  )> }
);

export type CurrentOperatorDetailFragment = (
  { __typename?: 'Operator' }
  & Pick<Operator, 'id' | 'fullName' | 'fullNameFurigana' | 'email' | 'phoneNumber'>
  & { company: (
    { __typename?: 'Company' }
    & Pick<Company, 'id' | 'name'>
  ), hotelList?: Maybe<(
    { __typename?: 'PaginateHotel' }
    & { items: Array<(
      { __typename?: 'Hotel' }
      & Pick<Hotel, 'id' | 'name'>
    )> }
  )> }
);

export type GetCurrentOperatorQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCurrentOperatorQuery = (
  { __typename?: 'Query' }
  & { currentOperator?: Maybe<(
    { __typename?: 'Operator' }
    & CurrentOperatorDetailFragment
  )> }
);

export type GetCustomerQueryVariables = Exact<{
  customerId: Scalars['ID'];
}>;


export type GetCustomerQuery = (
  { __typename?: 'Query' }
  & { customer?: Maybe<(
    { __typename?: 'Customer' }
    & Pick<Customer, 'id' | 'firstName' | 'firstNameFurigana' | 'lastName' | 'lastNameFurigana' | 'postalCode' | 'country' | 'prefCode' | 'address' | 'email' | 'phoneNumber' | 'workPlace' | 'description' | 'images' | 'status'>
    & { reservationList?: Maybe<(
      { __typename?: 'PaginateReservation' }
      & { items: Array<(
        { __typename?: 'Reservation' }
        & Pick<Reservation, 'id' | 'checkIn' | 'checkOut' | 'totalFee' | 'adultCount' | 'childCount' | 'babyCount'>
        & { plan: (
          { __typename?: 'Plan' }
          & Pick<Plan, 'id' | 'name'>
        ), roomType: (
          { __typename?: 'RoomType' }
          & Pick<RoomType, 'id' | 'name'>
        ) }
      )> }
    )> }
  )> }
);

export type CreateCustomerMutationVariables = Exact<{
  createCustomerInput: CreateCustomerInput;
}>;


export type CreateCustomerMutation = (
  { __typename?: 'Mutation' }
  & { createCustomer?: Maybe<(
    { __typename?: 'CreateCustomerPayload' }
    & { customer?: Maybe<(
      { __typename?: 'Customer' }
      & Pick<Customer, 'id' | 'firstName' | 'firstNameFurigana' | 'lastName' | 'lastNameFurigana' | 'postalCode' | 'country' | 'prefCode' | 'address' | 'buildingName' | 'workPlace' | 'email' | 'phoneNumber' | 'birthday' | 'gender' | 'description' | 'images' | 'status'>
    )> }
  )> }
);

export type GetCustomerListQueryVariables = Exact<{
  hotelId: Scalars['ID'];
}>;


export type GetCustomerListQuery = (
  { __typename?: 'Query' }
  & { hotel?: Maybe<(
    { __typename?: 'Hotel' }
    & Pick<Hotel, 'id'>
    & { customerList?: Maybe<(
      { __typename?: 'PaginateCustomer' }
      & { items: Array<(
        { __typename?: 'Customer' }
        & Pick<Customer, 'id' | 'firstName' | 'firstNameFurigana' | 'lastName' | 'lastNameFurigana' | 'email' | 'phoneNumber' | 'status'>
      )> }
    )> }
  )> }
);

export type GetReservationDashBoardByMonthQueryVariables = Exact<{
  hotelId: Scalars['ID'];
  month?: Maybe<Scalars['String']>;
}>;


export type GetReservationDashBoardByMonthQuery = (
  { __typename?: 'Query' }
  & { hotel?: Maybe<(
    { __typename?: 'Hotel' }
    & Pick<Hotel, 'id' | 'rateOfOperation'>
    & { reservationListByMonth?: Maybe<Array<(
      { __typename?: 'Reservation' }
      & Pick<Reservation, 'id' | 'totalFee' | 'cancelFee'>
    )>> }
  )> }
);

export type CreateBankAccountMutationVariables = Exact<{
  createBankAccountInput: CreateBankAccountInput;
}>;


export type CreateBankAccountMutation = (
  { __typename?: 'Mutation' }
  & { createBankAccount?: Maybe<(
    { __typename?: 'CreateBankAccountPayload' }
    & { bankAccount?: Maybe<(
      { __typename?: 'BankAccount' }
      & Pick<BankAccount, 'id' | 'bankCode' | 'bankName' | 'branchCode' | 'branchName' | 'accountNumber' | 'accountHolderName'>
    )> }
  )> }
);

export type ChangeBankAccountMutationVariables = Exact<{
  changeBankAccountInput: ChangeBankAccountInput;
}>;


export type ChangeBankAccountMutation = (
  { __typename?: 'Mutation' }
  & { changeBankAccount?: Maybe<(
    { __typename?: 'ChangeBankAccountPayload' }
    & { bankAccount?: Maybe<(
      { __typename?: 'BankAccount' }
      & Pick<BankAccount, 'id' | 'bankCode' | 'bankName' | 'branchCode' | 'branchName' | 'accountNumber' | 'accountHolderName'>
    )> }
  )> }
);

export type UpdateStripeCompanyMutationVariables = Exact<{
  input?: Maybe<UpdateStripeCompanyInput>;
}>;


export type UpdateStripeCompanyMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateStripeCompany'>
);

export type CreateStripePersonMutationVariables = Exact<{
  input?: Maybe<CreateStripePersonInput>;
}>;


export type CreateStripePersonMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createStripePerson'>
);

export type GetStripeAccountInfoQueryVariables = Exact<{ [key: string]: never; }>;


export type GetStripeAccountInfoQuery = (
  { __typename?: 'Query' }
  & { currentOperator?: Maybe<(
    { __typename?: 'Operator' }
    & Pick<Operator, 'id'>
    & { stripeAccount?: Maybe<(
      { __typename?: 'StripeAccount' }
      & Pick<StripeAccount, 'hasCompany' | 'hasPerson'>
    )> }
  )> }
);

export type UpdateHotelMutationVariables = Exact<{
  updateHotelInput: UpdateHotelInput;
}>;


export type UpdateHotelMutation = (
  { __typename?: 'Mutation' }
  & { updateHotel?: Maybe<(
    { __typename?: 'UpdateHotelPayload' }
    & { hotel?: Maybe<(
      { __typename?: 'Hotel' }
      & Pick<Hotel, 'id' | 'name' | 'nameFurigana' | 'images' | 'url' | 'type' | 'phoneNumber' | 'prefCode' | 'postalCode' | 'address' | 'buildingName' | 'licenseNumber'>
    )> }
  )> }
);

export type CurrentManageHotelFragment = (
  { __typename?: 'Hotel' }
  & Pick<Hotel, 'id' | 'name' | 'nameFurigana' | 'logo' | 'images' | 'url' | 'type' | 'phoneNumber' | 'prefCode' | 'postalCode' | 'address' | 'buildingName' | 'licenseNumber' | 'description' | 'defaultPaymentMethodId' | 'closingDays'>
  & { bankAccount?: Maybe<(
    { __typename?: 'BankAccount' }
    & Pick<BankAccount, 'id' | 'bankCode' | 'bankName' | 'branchCode' | 'branchName' | 'accountNumber' | 'accountHolderName'>
  )>, paymentMethodsForHotel?: Maybe<Array<(
    { __typename?: 'PaymentMethodForHotel' }
    & Pick<PaymentMethodForHotel, 'id' | 'brand' | 'last4' | 'stripeId'>
  )>> }
);

export type CreateHotelMutationVariables = Exact<{
  createHotelInput: CreateHotelInput;
}>;


export type CreateHotelMutation = (
  { __typename?: 'Mutation' }
  & { createHotel?: Maybe<(
    { __typename?: 'CreateHotelPayload' }
    & { hotel?: Maybe<(
      { __typename?: 'Hotel' }
      & Pick<Hotel, 'id' | 'name' | 'nameFurigana' | 'images' | 'url' | 'type' | 'phoneNumber' | 'prefCode' | 'postalCode' | 'address' | 'buildingName' | 'licenseNumber'>
    )> }
  )> }
);

export type GetCurrentManageHotelSettingQueryVariables = Exact<{
  hotelId: Scalars['ID'];
}>;


export type GetCurrentManageHotelSettingQuery = (
  { __typename?: 'Query' }
  & { hotel?: Maybe<(
    { __typename?: 'Hotel' }
    & CurrentManageHotelFragment
  )> }
);

export type GetInvoicesQueryVariables = Exact<{
  hotelId: Scalars['ID'];
}>;


export type GetInvoicesQuery = (
  { __typename?: 'Query' }
  & { hotel?: Maybe<(
    { __typename?: 'Hotel' }
    & Pick<Hotel, 'id'>
    & { invoices?: Maybe<Array<(
      { __typename?: 'Invoice' }
      & Pick<Invoice, 'id' | 'amount' | 'isPaid' | 'stripeInvoiceNum' | 'paidAt'>
    )>> }
  )> }
);

export type GetInvoiceQueryVariables = Exact<{
  hotelId: Scalars['ID'];
  invoiceId: Scalars['String'];
}>;


export type GetInvoiceQuery = (
  { __typename?: 'Query' }
  & { hotel?: Maybe<(
    { __typename?: 'Hotel' }
    & Pick<Hotel, 'id'>
    & { invoice?: Maybe<(
      { __typename?: 'Invoice' }
      & Pick<Invoice, 'id' | 'amount' | 'isPaid' | 'stripeInvoiceNum' | 'paidAt'>
    )> }
  )> }
);

export type GetReservationListByMonthQueryVariables = Exact<{
  hotelId: Scalars['ID'];
  month?: Maybe<Scalars['String']>;
}>;


export type GetReservationListByMonthQuery = (
  { __typename?: 'Query' }
  & { hotel?: Maybe<(
    { __typename?: 'Hotel' }
    & Pick<Hotel, 'id'>
    & { reservationListByMonth?: Maybe<Array<(
      { __typename?: 'Reservation' }
      & Pick<Reservation, 'id' | 'checkIn' | 'checkOut' | 'firstName' | 'lastName' | 'stayStatus' | 'roomCount' | 'totalFee' | 'cancelFee' | 'closedAt' | 'adultCount' | 'childCount' | 'babyCount' | 'createdAt'>
      & { plan: (
        { __typename?: 'Plan' }
        & Pick<Plan, 'id' | 'name'>
      ), customer?: Maybe<(
        { __typename?: 'Customer' }
        & Pick<Customer, 'id' | 'firstName' | 'firstNameFurigana' | 'lastName' | 'lastNameFurigana'>
      )> }
    )>> }
  )> }
);

export type GetMonthlyIncomeListQueryVariables = Exact<{
  hotelId: Scalars['ID'];
}>;


export type GetMonthlyIncomeListQuery = (
  { __typename?: 'Query' }
  & { hotel?: Maybe<(
    { __typename?: 'Hotel' }
    & Pick<Hotel, 'id'>
    & { monthlyIncomeList?: Maybe<(
      { __typename?: 'PaginateMonthlyIncome' }
      & { items: Array<(
        { __typename?: 'MonthlyIncome' }
        & Pick<MonthlyIncome, 'amount' | 'targetYearMonth'>
      )> }
    )> }
  )> }
);

export type UpdateOperatorMutationVariables = Exact<{
  updateOperatorInput: UpdateOperatorInput;
}>;


export type UpdateOperatorMutation = (
  { __typename?: 'Mutation' }
  & { updateOperator?: Maybe<(
    { __typename?: 'UpdateOperatorPayload' }
    & { operator?: Maybe<(
      { __typename?: 'Operator' }
      & Pick<Operator, 'id' | 'fullName' | 'fullNameFurigana' | 'email' | 'phoneNumber'>
    )> }
  )> }
);

export type GetOperatorDetailQueryVariables = Exact<{
  operatorId: Scalars['ID'];
}>;


export type GetOperatorDetailQuery = (
  { __typename?: 'Query' }
  & { operator?: Maybe<(
    { __typename?: 'Operator' }
    & Pick<Operator, 'id' | 'fullName' | 'fullNameFurigana' | 'email' | 'phoneNumber'>
  )> }
);

export type DeleteOperatorMutationVariables = Exact<{
  input: DeleteOperatorInput;
}>;


export type DeleteOperatorMutation = (
  { __typename?: 'Mutation' }
  & { deleteOperator?: Maybe<(
    { __typename?: 'DeleteOperatorPayload' }
    & { operator?: Maybe<(
      { __typename?: 'Operator' }
      & Pick<Operator, 'id'>
    )> }
  )> }
);

export type CreateOperatorMutationVariables = Exact<{
  createOperatorInput: CreateOperatorInput;
}>;


export type CreateOperatorMutation = (
  { __typename?: 'Mutation' }
  & { createOperator?: Maybe<(
    { __typename?: 'CreateOperatorPayload' }
    & { operator?: Maybe<(
      { __typename?: 'Operator' }
      & Pick<Operator, 'id' | 'fullName' | 'fullNameFurigana' | 'email' | 'phoneNumber'>
      & { hotelList?: Maybe<(
        { __typename?: 'PaginateHotel' }
        & { items: Array<(
          { __typename?: 'Hotel' }
          & Pick<Hotel, 'id' | 'name'>
        )> }
      )> }
    )> }
  )> }
);

export type CreateRegularOperatorMutationVariables = Exact<{
  createRegularOperatorInput: CreateRegularOperatorInput;
}>;


export type CreateRegularOperatorMutation = (
  { __typename?: 'Mutation' }
  & { createRegularOperator?: Maybe<(
    { __typename?: 'CreateOperatorPayload' }
    & { operator?: Maybe<(
      { __typename?: 'Operator' }
      & Pick<Operator, 'id' | 'fullName' | 'fullNameFurigana' | 'email' | 'phoneNumber'>
      & { hotelList?: Maybe<(
        { __typename?: 'PaginateHotel' }
        & { items: Array<(
          { __typename?: 'Hotel' }
          & Pick<Hotel, 'id' | 'name'>
        )> }
      )> }
    )> }
  )> }
);

export type GetOperatorListQueryVariables = Exact<{
  hotelId: Scalars['ID'];
}>;


export type GetOperatorListQuery = (
  { __typename?: 'Query' }
  & { hotel?: Maybe<(
    { __typename?: 'Hotel' }
    & Pick<Hotel, 'id'>
    & { jammyPlanSubscription?: Maybe<(
      { __typename?: 'JammyPlanSubscription' }
      & Pick<JammyPlanSubscription, 'id'>
      & { jammyPlan: (
        { __typename?: 'JammyPlan' }
        & Pick<JammyPlan, 'id' | 'operatorLimit' | 'roomLimit'>
      ) }
    )>, operatorList?: Maybe<(
      { __typename?: 'PaginateOperator' }
      & Pick<PaginateOperator, 'total'>
      & { items: Array<(
        { __typename?: 'Operator' }
        & Pick<Operator, 'id' | 'fullName' | 'email' | 'phoneNumber'>
      )> }
    )> }
  )> }
);

export type UpdateOptionMutationVariables = Exact<{
  updateOptionInput: UpdateOptionInput;
}>;


export type UpdateOptionMutation = (
  { __typename?: 'Mutation' }
  & { updateOption?: Maybe<(
    { __typename?: 'UpdateOptionPayload' }
    & { option?: Maybe<(
      { __typename?: 'Option' }
      & Pick<Option, 'id' | 'name' | 'description' | 'unit' | 'fee' | 'images' | 'isPublic'>
    )> }
  )> }
);

export type DeleteOptionMutationVariables = Exact<{
  deleteOptionInput: DeleteOptionInput;
}>;


export type DeleteOptionMutation = (
  { __typename?: 'Mutation' }
  & { deleteOption?: Maybe<(
    { __typename?: 'DeleteOptionPayload' }
    & { option?: Maybe<(
      { __typename?: 'Option' }
      & Pick<Option, 'id' | 'name'>
    )> }
  )> }
);

export type OptionDetailFragment = (
  { __typename?: 'Option' }
  & Pick<Option, 'id' | 'name' | 'description' | 'unit' | 'fee' | 'images' | 'isPublic'>
);

export type GetOptionQueryVariables = Exact<{
  optionId: Scalars['ID'];
}>;


export type GetOptionQuery = (
  { __typename?: 'Query' }
  & { option?: Maybe<(
    { __typename?: 'Option' }
    & OptionDetailFragment
  )> }
);

export type CreateOptionMutationVariables = Exact<{
  createOptionInput: CreateOptionInput;
}>;


export type CreateOptionMutation = (
  { __typename?: 'Mutation' }
  & { createOption?: Maybe<(
    { __typename?: 'CreateOptionPayload' }
    & { option?: Maybe<(
      { __typename?: 'Option' }
      & Pick<Option, 'id' | 'name' | 'description' | 'unit' | 'fee'>
    )> }
  )> }
);

export type GetOptionListQueryVariables = Exact<{
  hotelId: Scalars['ID'];
}>;


export type GetOptionListQuery = (
  { __typename?: 'Query' }
  & { hotel?: Maybe<(
    { __typename?: 'Hotel' }
    & Pick<Hotel, 'id'>
    & { optionList?: Maybe<(
      { __typename?: 'PaginateOption' }
      & { items?: Maybe<Array<(
        { __typename?: 'Option' }
        & Pick<Option, 'id' | 'images' | 'description' | 'name' | 'fee' | 'unit'>
      )>> }
    )> }
  )> }
);

export type CreatePaymentMethodForHotelMutationVariables = Exact<{
  input: CreatePaymentMethodForHotelInput;
}>;


export type CreatePaymentMethodForHotelMutation = (
  { __typename?: 'Mutation' }
  & { createPaymentMethodForHotel?: Maybe<(
    { __typename?: 'CreatePaymentMethodForHotelPayload' }
    & { paymentMethodForHotel?: Maybe<(
      { __typename?: 'PaymentMethodForHotel' }
      & Pick<PaymentMethodForHotel, 'id' | 'brand' | 'last4'>
    )> }
  )> }
);

export type SetDefaultPaymentMethodForHotelMutationVariables = Exact<{
  input: SetDefaultPaymentMethodInput;
}>;


export type SetDefaultPaymentMethodForHotelMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'setDefaultPaymentMethodForHotel'>
);

export type UpdatePlanMutationVariables = Exact<{
  updatePlanInput: UpdatePlanInput;
}>;


export type UpdatePlanMutation = (
  { __typename?: 'Mutation' }
  & { updatePlan?: Maybe<(
    { __typename?: 'UpdatePlanPayload' }
    & { plan?: Maybe<(
      { __typename?: 'Plan' }
      & Pick<Plan, 'id' | 'name' | 'description' | 'checkInStartTime' | 'checkInEndTime' | 'checkOutStartTime' | 'checkOutEndTime' | 'isPublic'>
    )> }
  )> }
);

export type DeletePlanMutationVariables = Exact<{
  deletePlanInput: DeletePlanInput;
}>;


export type DeletePlanMutation = (
  { __typename?: 'Mutation' }
  & { deletePlan?: Maybe<(
    { __typename?: 'DeletePlanPayload' }
    & { plan?: Maybe<(
      { __typename?: 'Plan' }
      & Pick<Plan, 'id' | 'name'>
    )> }
  )> }
);

export type PlanDetailFragment = (
  { __typename?: 'Plan' }
  & Pick<Plan, 'id' | 'name' | 'description' | 'cancelPolicy' | 'images' | 'haveBreakfast' | 'haveDinner' | 'checkInStartTime' | 'checkInEndTime' | 'checkOutStartTime' | 'checkOutEndTime' | 'reservationConfirmationMethod' | 'reservationDeadline' | 'info' | 'percentageForBaby' | 'percentageForChild' | 'feeType' | 'isPublic' | 'reservationStartDate' | 'reservationEndDate' | 'canLocalPayment' | 'longStayDiscounts' | 'cancelPolicyRates'>
  & { roomTypeList?: Maybe<(
    { __typename?: 'PaginateRoomType' }
    & { items: Array<(
      { __typename?: 'RoomType' }
      & Pick<RoomType, 'id' | 'name' | 'images' | 'limit'>
    )> }
  )>, options?: Maybe<Array<(
    { __typename?: 'Option' }
    & OptionListItemFragment
  )>>, pricePairings?: Maybe<Array<(
    { __typename?: 'PricePairing' }
    & Pick<PricePairing, 'id' | 'roomFeeArray' | 'personFeeArray'>
    & { roomType?: Maybe<(
      { __typename?: 'RoomType' }
      & Pick<RoomType, 'id' | 'name'>
    )> }
  )>> }
);

export type CreatePlansOnOptionsMutationVariables = Exact<{
  input: CreatePlansOnOptionsInput;
}>;


export type CreatePlansOnOptionsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createPlansOnOptions'>
);

export type DeletePlansOnOptionsMutationVariables = Exact<{
  input: DeletePlansOnOptionsInput;
}>;


export type DeletePlansOnOptionsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deletePlansOnOptions'>
);

export type CreatePricePairingMutationVariables = Exact<{
  input: CreatePricePairingInput;
}>;


export type CreatePricePairingMutation = (
  { __typename?: 'Mutation' }
  & { createPricePairing?: Maybe<(
    { __typename?: 'CreatePricePairingPayload' }
    & { pricePairing?: Maybe<(
      { __typename?: 'PricePairing' }
      & Pick<PricePairing, 'id'>
      & { plan?: Maybe<(
        { __typename?: 'Plan' }
        & Pick<Plan, 'id' | 'feeType'>
      )> }
    )> }
  )> }
);

export type DeletePricePairingMutationVariables = Exact<{
  input: DeletePricePairingInput;
}>;


export type DeletePricePairingMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deletePricePairing'>
);

export type GetPlanQueryVariables = Exact<{
  planId: Scalars['ID'];
}>;


export type GetPlanQuery = (
  { __typename?: 'Query' }
  & { plan?: Maybe<(
    { __typename?: 'Plan' }
    & PlanDetailFragment
  )> }
);

export type CreatePlanMutationVariables = Exact<{
  createPlanInput: CreatePlanInput;
}>;


export type CreatePlanMutation = (
  { __typename?: 'Mutation' }
  & { createPlan?: Maybe<(
    { __typename?: 'CreatePlanPayload' }
    & { plan?: Maybe<(
      { __typename?: 'Plan' }
      & Pick<Plan, 'id' | 'name' | 'description' | 'images' | 'checkInStartTime' | 'checkInEndTime' | 'checkOutStartTime' | 'checkOutEndTime' | 'haveBreakfast' | 'haveDinner' | 'reservationConfirmationMethod' | 'reservationDeadline' | 'feeType'>
    )> }
  )> }
);

export type CalculateTotalFeeQueryVariables = Exact<{
  roomTypeId: Scalars['ID'];
  planId: Scalars['ID'];
  roomCount?: Maybe<Scalars['Int']>;
  babyCount?: Maybe<Scalars['Int']>;
  childCount?: Maybe<Scalars['Int']>;
  adultCount?: Maybe<Scalars['Int']>;
  checkOut?: Maybe<Scalars['Timestamp']>;
  checkIn?: Maybe<Scalars['Timestamp']>;
}>;


export type CalculateTotalFeeQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'calculateTotalFee'>
);

export type GetPlanListByOperatorQueryVariables = Exact<{
  hotelId: Scalars['ID'];
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
}>;


export type GetPlanListByOperatorQuery = (
  { __typename?: 'Query' }
  & { hotel?: Maybe<(
    { __typename?: 'Hotel' }
    & Pick<Hotel, 'id'>
    & { jammyPlanSubscription?: Maybe<(
      { __typename?: 'JammyPlanSubscription' }
      & Pick<JammyPlanSubscription, 'id'>
    )>, planListByOperator?: Maybe<(
      { __typename?: 'PaginatePlan' }
      & Pick<PaginatePlan, 'total'>
      & { items: Array<(
        { __typename?: 'Plan' }
        & PlanListItemFragment
      )> }
    )> }
  )> }
);

export type UpdatePricePairingMutationVariables = Exact<{
  input: UpdatePricePairingInput;
}>;


export type UpdatePricePairingMutation = (
  { __typename?: 'Mutation' }
  & { updatePricePairing?: Maybe<(
    { __typename?: 'UpdatePricePairingPayload' }
    & { pricePairing?: Maybe<(
      { __typename?: 'PricePairing' }
      & Pick<PricePairing, 'id' | 'roomFeeArray' | 'personFeeArray'>
    )> }
  )> }
);

export type GetPricePairingQueryVariables = Exact<{
  pricePairingId: Scalars['ID'];
}>;


export type GetPricePairingQuery = (
  { __typename?: 'Query' }
  & { pricePairing?: Maybe<(
    { __typename?: 'PricePairing' }
    & Pick<PricePairing, 'id' | 'roomFeeArray' | 'personFeeArray'>
    & { plan?: Maybe<(
      { __typename?: 'Plan' }
      & Pick<Plan, 'id' | 'name' | 'feeType'>
    )>, roomType?: Maybe<(
      { __typename?: 'RoomType' }
      & Pick<RoomType, 'id' | 'name' | 'limit'>
    )> }
  )> }
);

export type ReservationDetailForEditFragment = (
  { __typename?: 'Reservation' }
  & Pick<Reservation, 'id' | 'checkIn' | 'checkInTime' | 'checkOut' | 'adultCount' | 'childCount' | 'babyCount' | 'roomCount'>
  & { plan: (
    { __typename?: 'Plan' }
    & Pick<Plan, 'id' | 'name'>
  ), roomType: (
    { __typename?: 'RoomType' }
    & Pick<RoomType, 'id' | 'name'>
  ) }
);

export type GetReservationForEditQueryVariables = Exact<{
  reservationId: Scalars['ID'];
}>;


export type GetReservationForEditQuery = (
  { __typename?: 'Query' }
  & { reservation?: Maybe<(
    { __typename?: 'Reservation' }
    & ReservationDetailForEditFragment
  )> }
);

export type ReservationCustomerDetailFragment = (
  { __typename?: 'Customer' }
  & Pick<Customer, 'id' | 'firstName' | 'firstNameFurigana' | 'lastName' | 'lastNameFurigana' | 'postalCode' | 'country' | 'prefCode' | 'address' | 'buildingName' | 'email' | 'phoneNumber' | 'description'>
  & { reservationList?: Maybe<(
    { __typename?: 'PaginateReservation' }
    & { items: Array<(
      { __typename?: 'Reservation' }
      & Pick<Reservation, 'id' | 'checkIn' | 'checkOut' | 'totalFee' | 'cancelFee'>
      & { roomType: (
        { __typename?: 'RoomType' }
        & Pick<RoomType, 'id' | 'name'>
      ), plan: (
        { __typename?: 'Plan' }
        & Pick<Plan, 'id' | 'name'>
      ) }
    )> }
  )> }
);

export type UpdateReservationMutationVariables = Exact<{
  input: UpdateReservationInput;
}>;


export type UpdateReservationMutation = (
  { __typename?: 'Mutation' }
  & { updateReservation?: Maybe<(
    { __typename?: 'UpdateReservationPayload' }
    & { reservation?: Maybe<(
      { __typename?: 'Reservation' }
      & Pick<Reservation, 'id' | 'checkIn' | 'checkInTime' | 'checkOut'>
    )> }
  )> }
);

export type DeleteReservationMutationVariables = Exact<{
  input: DeleteReservationInput;
}>;


export type DeleteReservationMutation = (
  { __typename?: 'Mutation' }
  & { deleteReservation?: Maybe<(
    { __typename?: 'DeleteReservationPayload' }
    & { reservation?: Maybe<(
      { __typename?: 'Reservation' }
      & Pick<Reservation, 'id'>
    )> }
  )> }
);

export type ChangeReservationStatusMutationVariables = Exact<{
  input: ChangeReservationStatusInput;
}>;


export type ChangeReservationStatusMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'changeReservationStatus'>
);

export type PaymentReservationChargeMutationVariables = Exact<{
  input: PaymentReservationChargeInput;
}>;


export type PaymentReservationChargeMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'paymentReservationCharge'>
);

export type PaymentCancelChargeMutationVariables = Exact<{
  input: PaymentReservationChargeInput;
}>;


export type PaymentCancelChargeMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'paymentCancelCharge'>
);

export type CheckInReservationMutationVariables = Exact<{
  input: CheckInReservationInput;
}>;


export type CheckInReservationMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'checkInReservation'>
);

export type CancelReservationMutationVariables = Exact<{
  input: CancelReservationInput;
}>;


export type CancelReservationMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'cancelReservation'>
);

export type GetReservationQueryVariables = Exact<{
  reservationId: Scalars['ID'];
}>;


export type GetReservationQuery = (
  { __typename?: 'Query' }
  & { reservation?: Maybe<(
    { __typename?: 'Reservation' }
    & Pick<Reservation, 'id' | 'stayStatus' | 'checkIn' | 'checkInTime' | 'checkOut' | 'totalFee' | 'cancelFee' | 'adultCount' | 'childCount' | 'babyCount' | 'roomCount' | 'paymentType' | 'firstName' | 'firstNameFurigana' | 'lastName' | 'lastNameFurigana' | 'email' | 'phoneNumber' | 'closedAt' | 'optionSets'>
    & { plan: (
      { __typename?: 'Plan' }
      & PlanDetailModalFragment
    ), roomType: (
      { __typename?: 'RoomType' }
      & RoomTypeDetailFragment
    ), rooms?: Maybe<Array<(
      { __typename?: 'Room' }
      & Pick<Room, 'id' | 'name'>
    )>>, customer?: Maybe<(
      { __typename?: 'Customer' }
      & ReservationCustomerDetailFragment
    )> }
  )> }
);

export type CreateReservationByOperatorMutationVariables = Exact<{
  input: CreateReservationByOperatorInput;
}>;


export type CreateReservationByOperatorMutation = (
  { __typename?: 'Mutation' }
  & { createReservationByOperator?: Maybe<(
    { __typename?: 'CreateReservationPayload' }
    & { reservation?: Maybe<(
      { __typename?: 'Reservation' }
      & Pick<Reservation, 'id' | 'checkIn' | 'checkInTime' | 'checkOut' | 'totalFee'>
    )> }
  )> }
);

export type CreateReservationsOnRoomsMutationVariables = Exact<{
  input: CreateReservationsOnRoomsInput;
}>;


export type CreateReservationsOnRoomsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createReservationsOnRooms'>
);

export type UpdateReservationsOnRoomsMutationVariables = Exact<{
  input: UpdateReservationsOnRoomsInput;
}>;


export type UpdateReservationsOnRoomsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateReservationsOnRooms'>
);

export type GetReservationListQueryVariables = Exact<{
  hotelId: Scalars['ID'];
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  checkIn?: Maybe<Scalars['Timestamp']>;
  checkOut?: Maybe<Scalars['Timestamp']>;
  status?: Maybe<StayStatusType>;
}>;


export type GetReservationListQuery = (
  { __typename?: 'Query' }
  & { hotel?: Maybe<(
    { __typename?: 'Hotel' }
    & Pick<Hotel, 'id'>
    & { bankAccount?: Maybe<(
      { __typename?: 'BankAccount' }
      & Pick<BankAccount, 'id' | 'bankCode' | 'bankName' | 'branchCode' | 'branchName' | 'accountNumber' | 'accountHolderName'>
    )>, paymentMethodsForHotel?: Maybe<Array<(
      { __typename?: 'PaymentMethodForHotel' }
      & Pick<PaymentMethodForHotel, 'id' | 'brand' | 'last4' | 'stripeId'>
    )>>, reservationList?: Maybe<(
      { __typename?: 'PaginateReservation' }
      & Pick<PaginateReservation, 'total'>
      & { items: Array<(
        { __typename?: 'Reservation' }
        & Pick<Reservation, 'id' | 'checkIn' | 'checkOut' | 'firstName' | 'lastName' | 'stayStatus' | 'roomCount' | 'paymentType' | 'totalFee' | 'adultCount' | 'childCount' | 'babyCount' | 'createdAt'>
        & { plan: (
          { __typename?: 'Plan' }
          & Pick<Plan, 'id' | 'name'>
        ), customer?: Maybe<(
          { __typename?: 'Customer' }
          & Pick<Customer, 'id' | 'firstName' | 'firstNameFurigana' | 'lastName' | 'lastNameFurigana'>
        )> }
      )> }
    )> }
  )> }
);

export type UpdateRoomTypeMutationVariables = Exact<{
  updateRoomTypeInput: UpdateRoomTypeInput;
}>;


export type UpdateRoomTypeMutation = (
  { __typename?: 'Mutation' }
  & { updateRoomType?: Maybe<(
    { __typename?: 'UpdateRoomTypePayload' }
    & { roomType?: Maybe<(
      { __typename?: 'RoomType' }
      & Pick<RoomType, 'id' | 'name' | 'description' | 'images' | 'isPublic' | 'limit' | 'roomSize' | 'canSmoking' | 'bedType' | 'bedNum' | 'amenityCodes'>
    )> }
  )> }
);

export type DeleteRoomTypeMutationVariables = Exact<{
  deleteRoomTypeInput: DeleteRoomTypeInput;
}>;


export type DeleteRoomTypeMutation = (
  { __typename?: 'Mutation' }
  & { deleteRoomType?: Maybe<(
    { __typename?: 'DeleteRoomTypePayload' }
    & { roomType?: Maybe<(
      { __typename?: 'RoomType' }
      & Pick<RoomType, 'id'>
    )> }
  )> }
);

export type RoomTypeDetailFragment = (
  { __typename?: 'RoomType' }
  & Pick<RoomType, 'id' | 'name' | 'description' | 'images' | 'isPublic' | 'amenityCodes' | 'limit' | 'roomSize' | 'canSmoking' | 'bedType' | 'bedNum'>
);

export type GetRoomTypeQueryVariables = Exact<{
  roomTypeId: Scalars['ID'];
}>;


export type GetRoomTypeQuery = (
  { __typename?: 'Query' }
  & { roomType?: Maybe<(
    { __typename?: 'RoomType' }
    & RoomTypeDetailFragment
  )> }
);

export type CreateRoomTypeMutationVariables = Exact<{
  createRoomTypeInput: CreateRoomTypeInput;
}>;


export type CreateRoomTypeMutation = (
  { __typename?: 'Mutation' }
  & { createRoomType?: Maybe<(
    { __typename?: 'CreateRoomTypePayload' }
    & { roomType?: Maybe<(
      { __typename?: 'RoomType' }
      & Pick<RoomType, 'id' | 'name' | 'description' | 'images' | 'isPublic' | 'limit' | 'roomSize' | 'canSmoking' | 'bedType' | 'bedNum'>
    )> }
  )> }
);

export type GetRoomTypeListQueryVariables = Exact<{
  hotelId: Scalars['ID'];
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
}>;


export type GetRoomTypeListQuery = (
  { __typename?: 'Query' }
  & { hotel?: Maybe<(
    { __typename?: 'Hotel' }
    & Pick<Hotel, 'id'>
    & { roomTypeList?: Maybe<(
      { __typename?: 'PaginateRoomType' }
      & Pick<PaginateRoomType, 'total'>
      & { items: Array<(
        { __typename?: 'RoomType' }
        & Pick<RoomType, 'id' | 'name' | 'isPublic' | 'limit' | 'bedNum' | 'roomSize' | 'amenityCodes' | 'canSmoking'>
      )> }
    )> }
  )> }
);

export type UpdateRoomMutationVariables = Exact<{
  updateRoomInput: UpdateRoomInput;
}>;


export type UpdateRoomMutation = (
  { __typename?: 'Mutation' }
  & { updateRoom?: Maybe<(
    { __typename?: 'UpdateRoomPayload' }
    & { room: (
      { __typename?: 'Room' }
      & Pick<Room, 'id' | 'name'>
      & { roomType: (
        { __typename?: 'RoomType' }
        & Pick<RoomType, 'id' | 'name'>
      ) }
    ) }
  )> }
);

export type DeleteRoomMutationVariables = Exact<{
  deleteRoomInput: DeleteRoomInput;
}>;


export type DeleteRoomMutation = (
  { __typename?: 'Mutation' }
  & { deleteRoom?: Maybe<(
    { __typename?: 'DeleteRoomPayload' }
    & { room: (
      { __typename?: 'Room' }
      & Pick<Room, 'id' | 'name'>
    ) }
  )> }
);

export type RoomDetailFragment = (
  { __typename?: 'Room' }
  & Pick<Room, 'id' | 'name'>
  & { roomType: (
    { __typename?: 'RoomType' }
    & Pick<RoomType, 'id' | 'name'>
  ) }
);

export type GetRoomQueryVariables = Exact<{
  roomId: Scalars['ID'];
}>;


export type GetRoomQuery = (
  { __typename?: 'Query' }
  & { room?: Maybe<(
    { __typename?: 'Room' }
    & RoomDetailFragment
  )> }
);

export type CreateManyRoomMutationVariables = Exact<{
  input?: Maybe<Array<CreateRoomInput> | CreateRoomInput>;
}>;


export type CreateManyRoomMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createManyRoom'>
);

export type GetRoomListQueryVariables = Exact<{
  hotelId: Scalars['ID'];
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
}>;


export type GetRoomListQuery = (
  { __typename?: 'Query' }
  & { hotel?: Maybe<(
    { __typename?: 'Hotel' }
    & Pick<Hotel, 'id'>
    & { jammyPlanSubscription?: Maybe<(
      { __typename?: 'JammyPlanSubscription' }
      & Pick<JammyPlanSubscription, 'id'>
      & { jammyPlan: (
        { __typename?: 'JammyPlan' }
        & Pick<JammyPlan, 'id' | 'roomLimit'>
      ) }
    )>, roomList?: Maybe<(
      { __typename?: 'PaginateRoom' }
      & Pick<PaginateRoom, 'total'>
      & { items: Array<(
        { __typename?: 'Room' }
        & Pick<Room, 'id' | 'name'>
        & { roomType: (
          { __typename?: 'RoomType' }
          & Pick<RoomType, 'id' | 'name'>
        ) }
      )> }
    )> }
  )> }
);

export type GetHotelSubscriptionQueryVariables = Exact<{
  hotelId: Scalars['ID'];
}>;


export type GetHotelSubscriptionQuery = (
  { __typename?: 'Query' }
  & { hotel?: Maybe<(
    { __typename?: 'Hotel' }
    & Pick<Hotel, 'id'>
    & { jammyPlanSubscription?: Maybe<(
      { __typename?: 'JammyPlanSubscription' }
      & Pick<JammyPlanSubscription, 'id'>
      & { jammyPlan: (
        { __typename?: 'JammyPlan' }
        & Pick<JammyPlan, 'id' | 'name' | 'operatorLimit' | 'roomLimit' | 'description' | 'fee'>
      ) }
    )>, paymentMethodsForHotel?: Maybe<Array<(
      { __typename?: 'PaymentMethodForHotel' }
      & Pick<PaymentMethodForHotel, 'id' | 'brand' | 'last4'>
    )>> }
  )> }
);

export const PlanListItemFragmentDoc = gql`
    fragment PlanListItem on Plan {
  id
  name
  description
  feeType
  isPublic
}
    `;
export const PlanDetailModalFragmentDoc = gql`
    fragment PlanDetailModal on Plan {
  id
  name
  description
  cancelPolicy
  images
  haveBreakfast
  haveDinner
  checkInStartTime
  checkInEndTime
  checkOutStartTime
  checkOutEndTime
  cancelPolicyRates
}
    `;
export const StripeAccountBalanceFragmentDoc = gql`
    fragment StripeAccountBalance on StripeAccount {
  balance {
    pending
    available
  }
}
    `;
export const BankPayoutDetailFragmentDoc = gql`
    fragment BankPayoutDetail on BankPayout {
  id
  amount
  createdAt
  bankAccount {
    id
    bankName
    branchName
  }
}
    `;
export const CurrentOperatorDetailFragmentDoc = gql`
    fragment CurrentOperatorDetail on Operator {
  id
  fullName
  fullNameFurigana
  email
  phoneNumber
  company {
    id
    name
  }
  hotelList {
    items {
      id
      name
    }
  }
}
    `;
export const CurrentManageHotelFragmentDoc = gql`
    fragment CurrentManageHotel on Hotel {
  id
  name
  nameFurigana
  logo
  images
  url
  type
  phoneNumber
  prefCode
  postalCode
  address
  buildingName
  licenseNumber
  description
  bankAccount {
    id
    bankCode
    bankName
    branchCode
    branchName
    accountNumber
    accountHolderName
  }
  paymentMethodsForHotel {
    id
    brand
    last4
    stripeId
  }
  defaultPaymentMethodId
  closingDays
}
    `;
export const OptionDetailFragmentDoc = gql`
    fragment OptionDetail on Option {
  id
  name
  description
  unit
  fee
  images
  isPublic
}
    `;
export const OptionListItemFragmentDoc = gql`
    fragment OptionListItem on Option {
  id
  name
  unit
  fee
}
    `;
export const PlanDetailFragmentDoc = gql`
    fragment PlanDetail on Plan {
  id
  name
  description
  cancelPolicy
  images
  haveBreakfast
  haveDinner
  checkInStartTime
  checkInEndTime
  checkOutStartTime
  checkOutEndTime
  reservationConfirmationMethod
  reservationDeadline
  info
  percentageForBaby
  percentageForChild
  feeType
  isPublic
  reservationStartDate
  reservationEndDate
  canLocalPayment
  roomTypeList {
    items {
      id
      name
      images
      limit
    }
  }
  options {
    ...OptionListItem
  }
  longStayDiscounts
  cancelPolicyRates
  pricePairings {
    id
    roomFeeArray
    personFeeArray
    roomType {
      id
      name
    }
  }
}
    ${OptionListItemFragmentDoc}`;
export const ReservationDetailForEditFragmentDoc = gql`
    fragment ReservationDetailForEdit on Reservation {
  id
  checkIn
  checkInTime
  checkOut
  adultCount
  childCount
  babyCount
  roomCount
  plan {
    id
    name
  }
  roomType {
    id
    name
  }
}
    `;
export const ReservationCustomerDetailFragmentDoc = gql`
    fragment ReservationCustomerDetail on Customer {
  id
  firstName
  firstNameFurigana
  lastName
  lastNameFurigana
  postalCode
  country
  prefCode
  address
  buildingName
  email
  phoneNumber
  description
  reservationList {
    items {
      id
      checkIn
      checkOut
      totalFee
      cancelFee
      roomType {
        id
        name
      }
      plan {
        id
        name
      }
    }
  }
}
    `;
export const RoomTypeDetailFragmentDoc = gql`
    fragment RoomTypeDetail on RoomType {
  id
  name
  description
  images
  isPublic
  amenityCodes
  limit
  roomSize
  canSmoking
  bedType
  bedNum
}
    `;
export const RoomDetailFragmentDoc = gql`
    fragment RoomDetail on Room {
  id
  name
  roomType {
    id
    name
  }
}
    `;
export const OptionSelectListDocument = gql`
    query OptionSelectList($hotelId: ID!) {
  hotel(id: $hotelId) {
    id
    optionList {
      items {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useOptionSelectListQuery__
 *
 * To run a query within a React component, call `useOptionSelectListQuery` and pass it any options that fit your needs.
 * When your component renders, `useOptionSelectListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOptionSelectListQuery({
 *   variables: {
 *      hotelId: // value for 'hotelId'
 *   },
 * });
 */
export function useOptionSelectListQuery(baseOptions: Apollo.QueryHookOptions<OptionSelectListQuery, OptionSelectListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OptionSelectListQuery, OptionSelectListQueryVariables>(OptionSelectListDocument, options);
      }
export function useOptionSelectListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OptionSelectListQuery, OptionSelectListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OptionSelectListQuery, OptionSelectListQueryVariables>(OptionSelectListDocument, options);
        }
export type OptionSelectListQueryHookResult = ReturnType<typeof useOptionSelectListQuery>;
export type OptionSelectListLazyQueryHookResult = ReturnType<typeof useOptionSelectListLazyQuery>;
export type OptionSelectListQueryResult = Apollo.QueryResult<OptionSelectListQuery, OptionSelectListQueryVariables>;
export const UploadFileDocument = gql`
    mutation UploadFile($uploadSingleFileInput: UploadSingleFileInput!) {
  uploadSingleFile(input: $uploadSingleFileInput) {
    fileUrl
  }
}
    `;
export type UploadFileMutationFn = Apollo.MutationFunction<UploadFileMutation, UploadFileMutationVariables>;

/**
 * __useUploadFileMutation__
 *
 * To run a mutation, you first call `useUploadFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadFileMutation, { data, loading, error }] = useUploadFileMutation({
 *   variables: {
 *      uploadSingleFileInput: // value for 'uploadSingleFileInput'
 *   },
 * });
 */
export function useUploadFileMutation(baseOptions?: Apollo.MutationHookOptions<UploadFileMutation, UploadFileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UploadFileMutation, UploadFileMutationVariables>(UploadFileDocument, options);
      }
export type UploadFileMutationHookResult = ReturnType<typeof useUploadFileMutation>;
export type UploadFileMutationResult = Apollo.MutationResult<UploadFileMutation>;
export type UploadFileMutationOptions = Apollo.BaseMutationOptions<UploadFileMutation, UploadFileMutationVariables>;
export const UploadMultiFileDocument = gql`
    mutation UploadMultiFile($uploadMultiFileInput: UploadMultiFileInput!) {
  uploadMultiFile(input: $uploadMultiFileInput) {
    fileUrls
  }
}
    `;
export type UploadMultiFileMutationFn = Apollo.MutationFunction<UploadMultiFileMutation, UploadMultiFileMutationVariables>;

/**
 * __useUploadMultiFileMutation__
 *
 * To run a mutation, you first call `useUploadMultiFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadMultiFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadMultiFileMutation, { data, loading, error }] = useUploadMultiFileMutation({
 *   variables: {
 *      uploadMultiFileInput: // value for 'uploadMultiFileInput'
 *   },
 * });
 */
export function useUploadMultiFileMutation(baseOptions?: Apollo.MutationHookOptions<UploadMultiFileMutation, UploadMultiFileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UploadMultiFileMutation, UploadMultiFileMutationVariables>(UploadMultiFileDocument, options);
      }
export type UploadMultiFileMutationHookResult = ReturnType<typeof useUploadMultiFileMutation>;
export type UploadMultiFileMutationResult = Apollo.MutationResult<UploadMultiFileMutation>;
export type UploadMultiFileMutationOptions = Apollo.BaseMutationOptions<UploadMultiFileMutation, UploadMultiFileMutationVariables>;
export const GetReservationRoomListDocument = gql`
    query GetReservationRoomList($hotelId: ID!, $roomTypeId: ID, $checkIn: Timestamp, $checkOut: Timestamp) {
  hotel(id: $hotelId) {
    reservationList(checkIn: $checkIn, checkOut: $checkOut, roomTypeId: $roomTypeId) {
      items {
        id
        rooms {
          id
          name
        }
      }
    }
  }
}
    `;

/**
 * __useGetReservationRoomListQuery__
 *
 * To run a query within a React component, call `useGetReservationRoomListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReservationRoomListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReservationRoomListQuery({
 *   variables: {
 *      hotelId: // value for 'hotelId'
 *      roomTypeId: // value for 'roomTypeId'
 *      checkIn: // value for 'checkIn'
 *      checkOut: // value for 'checkOut'
 *   },
 * });
 */
export function useGetReservationRoomListQuery(baseOptions: Apollo.QueryHookOptions<GetReservationRoomListQuery, GetReservationRoomListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetReservationRoomListQuery, GetReservationRoomListQueryVariables>(GetReservationRoomListDocument, options);
      }
export function useGetReservationRoomListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetReservationRoomListQuery, GetReservationRoomListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetReservationRoomListQuery, GetReservationRoomListQueryVariables>(GetReservationRoomListDocument, options);
        }
export type GetReservationRoomListQueryHookResult = ReturnType<typeof useGetReservationRoomListQuery>;
export type GetReservationRoomListLazyQueryHookResult = ReturnType<typeof useGetReservationRoomListLazyQuery>;
export type GetReservationRoomListQueryResult = Apollo.QueryResult<GetReservationRoomListQuery, GetReservationRoomListQueryVariables>;
export const GetCompanyHotelListDocument = gql`
    query GetCompanyHotelList($companyId: ID!) {
  company(id: $companyId) {
    id
    hotelList {
      items {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useGetCompanyHotelListQuery__
 *
 * To run a query within a React component, call `useGetCompanyHotelListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyHotelListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyHotelListQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useGetCompanyHotelListQuery(baseOptions: Apollo.QueryHookOptions<GetCompanyHotelListQuery, GetCompanyHotelListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCompanyHotelListQuery, GetCompanyHotelListQueryVariables>(GetCompanyHotelListDocument, options);
      }
export function useGetCompanyHotelListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCompanyHotelListQuery, GetCompanyHotelListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCompanyHotelListQuery, GetCompanyHotelListQueryVariables>(GetCompanyHotelListDocument, options);
        }
export type GetCompanyHotelListQueryHookResult = ReturnType<typeof useGetCompanyHotelListQuery>;
export type GetCompanyHotelListLazyQueryHookResult = ReturnType<typeof useGetCompanyHotelListLazyQuery>;
export type GetCompanyHotelListQueryResult = Apollo.QueryResult<GetCompanyHotelListQuery, GetCompanyHotelListQueryVariables>;
export const GetCurrentOperatorHotelListDocument = gql`
    query getCurrentOperatorHotelList {
  currentOperator {
    id
    hotelList {
      items {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useGetCurrentOperatorHotelListQuery__
 *
 * To run a query within a React component, call `useGetCurrentOperatorHotelListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentOperatorHotelListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentOperatorHotelListQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCurrentOperatorHotelListQuery(baseOptions?: Apollo.QueryHookOptions<GetCurrentOperatorHotelListQuery, GetCurrentOperatorHotelListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCurrentOperatorHotelListQuery, GetCurrentOperatorHotelListQueryVariables>(GetCurrentOperatorHotelListDocument, options);
      }
export function useGetCurrentOperatorHotelListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCurrentOperatorHotelListQuery, GetCurrentOperatorHotelListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCurrentOperatorHotelListQuery, GetCurrentOperatorHotelListQueryVariables>(GetCurrentOperatorHotelListDocument, options);
        }
export type GetCurrentOperatorHotelListQueryHookResult = ReturnType<typeof useGetCurrentOperatorHotelListQuery>;
export type GetCurrentOperatorHotelListLazyQueryHookResult = ReturnType<typeof useGetCurrentOperatorHotelListLazyQuery>;
export type GetCurrentOperatorHotelListQueryResult = Apollo.QueryResult<GetCurrentOperatorHotelListQuery, GetCurrentOperatorHotelListQueryVariables>;
export const PlanSelectListDocument = gql`
    query PlanSelectList($hotelId: ID!) {
  hotel(id: $hotelId) {
    id
    planList(isPublic: true) {
      items {
        id
        name
      }
    }
  }
}
    `;

/**
 * __usePlanSelectListQuery__
 *
 * To run a query within a React component, call `usePlanSelectListQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlanSelectListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlanSelectListQuery({
 *   variables: {
 *      hotelId: // value for 'hotelId'
 *   },
 * });
 */
export function usePlanSelectListQuery(baseOptions: Apollo.QueryHookOptions<PlanSelectListQuery, PlanSelectListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PlanSelectListQuery, PlanSelectListQueryVariables>(PlanSelectListDocument, options);
      }
export function usePlanSelectListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PlanSelectListQuery, PlanSelectListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PlanSelectListQuery, PlanSelectListQueryVariables>(PlanSelectListDocument, options);
        }
export type PlanSelectListQueryHookResult = ReturnType<typeof usePlanSelectListQuery>;
export type PlanSelectListLazyQueryHookResult = ReturnType<typeof usePlanSelectListLazyQuery>;
export type PlanSelectListQueryResult = Apollo.QueryResult<PlanSelectListQuery, PlanSelectListQueryVariables>;
export const RoomSelectListDocument = gql`
    query RoomSelectList($hotelId: ID!) {
  hotel(id: $hotelId) {
    id
    roomList {
      items {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useRoomSelectListQuery__
 *
 * To run a query within a React component, call `useRoomSelectListQuery` and pass it any options that fit your needs.
 * When your component renders, `useRoomSelectListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRoomSelectListQuery({
 *   variables: {
 *      hotelId: // value for 'hotelId'
 *   },
 * });
 */
export function useRoomSelectListQuery(baseOptions: Apollo.QueryHookOptions<RoomSelectListQuery, RoomSelectListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RoomSelectListQuery, RoomSelectListQueryVariables>(RoomSelectListDocument, options);
      }
export function useRoomSelectListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RoomSelectListQuery, RoomSelectListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RoomSelectListQuery, RoomSelectListQueryVariables>(RoomSelectListDocument, options);
        }
export type RoomSelectListQueryHookResult = ReturnType<typeof useRoomSelectListQuery>;
export type RoomSelectListLazyQueryHookResult = ReturnType<typeof useRoomSelectListLazyQuery>;
export type RoomSelectListQueryResult = Apollo.QueryResult<RoomSelectListQuery, RoomSelectListQueryVariables>;
export const RoomTypeSelectListDocument = gql`
    query RoomTypeSelectList($hotelId: ID!) {
  hotel(id: $hotelId) {
    id
    roomTypeList {
      items {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useRoomTypeSelectListQuery__
 *
 * To run a query within a React component, call `useRoomTypeSelectListQuery` and pass it any options that fit your needs.
 * When your component renders, `useRoomTypeSelectListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRoomTypeSelectListQuery({
 *   variables: {
 *      hotelId: // value for 'hotelId'
 *   },
 * });
 */
export function useRoomTypeSelectListQuery(baseOptions: Apollo.QueryHookOptions<RoomTypeSelectListQuery, RoomTypeSelectListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RoomTypeSelectListQuery, RoomTypeSelectListQueryVariables>(RoomTypeSelectListDocument, options);
      }
export function useRoomTypeSelectListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RoomTypeSelectListQuery, RoomTypeSelectListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RoomTypeSelectListQuery, RoomTypeSelectListQueryVariables>(RoomTypeSelectListDocument, options);
        }
export type RoomTypeSelectListQueryHookResult = ReturnType<typeof useRoomTypeSelectListQuery>;
export type RoomTypeSelectListLazyQueryHookResult = ReturnType<typeof useRoomTypeSelectListLazyQuery>;
export type RoomTypeSelectListQueryResult = Apollo.QueryResult<RoomTypeSelectListQuery, RoomTypeSelectListQueryVariables>;
export const CreateBankPayoutDocument = gql`
    mutation CreateBankPayout($createBankPayoutInput: CreateBankPayoutInput!) {
  createBankPayout(input: $createBankPayoutInput) {
    result
  }
}
    `;
export type CreateBankPayoutMutationFn = Apollo.MutationFunction<CreateBankPayoutMutation, CreateBankPayoutMutationVariables>;

/**
 * __useCreateBankPayoutMutation__
 *
 * To run a mutation, you first call `useCreateBankPayoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBankPayoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBankPayoutMutation, { data, loading, error }] = useCreateBankPayoutMutation({
 *   variables: {
 *      createBankPayoutInput: // value for 'createBankPayoutInput'
 *   },
 * });
 */
export function useCreateBankPayoutMutation(baseOptions?: Apollo.MutationHookOptions<CreateBankPayoutMutation, CreateBankPayoutMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateBankPayoutMutation, CreateBankPayoutMutationVariables>(CreateBankPayoutDocument, options);
      }
export type CreateBankPayoutMutationHookResult = ReturnType<typeof useCreateBankPayoutMutation>;
export type CreateBankPayoutMutationResult = Apollo.MutationResult<CreateBankPayoutMutation>;
export type CreateBankPayoutMutationOptions = Apollo.BaseMutationOptions<CreateBankPayoutMutation, CreateBankPayoutMutationVariables>;
export const GetStripeAccountBalanceDocument = gql`
    query GetStripeAccountBalance {
  currentOperator {
    id
    stripeAccount {
      ...StripeAccountBalance
    }
    bankPayoutList {
      items {
        ...BankPayoutDetail
      }
    }
  }
}
    ${StripeAccountBalanceFragmentDoc}
${BankPayoutDetailFragmentDoc}`;

/**
 * __useGetStripeAccountBalanceQuery__
 *
 * To run a query within a React component, call `useGetStripeAccountBalanceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStripeAccountBalanceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStripeAccountBalanceQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetStripeAccountBalanceQuery(baseOptions?: Apollo.QueryHookOptions<GetStripeAccountBalanceQuery, GetStripeAccountBalanceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStripeAccountBalanceQuery, GetStripeAccountBalanceQueryVariables>(GetStripeAccountBalanceDocument, options);
      }
export function useGetStripeAccountBalanceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStripeAccountBalanceQuery, GetStripeAccountBalanceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStripeAccountBalanceQuery, GetStripeAccountBalanceQueryVariables>(GetStripeAccountBalanceDocument, options);
        }
export type GetStripeAccountBalanceQueryHookResult = ReturnType<typeof useGetStripeAccountBalanceQuery>;
export type GetStripeAccountBalanceLazyQueryHookResult = ReturnType<typeof useGetStripeAccountBalanceLazyQuery>;
export type GetStripeAccountBalanceQueryResult = Apollo.QueryResult<GetStripeAccountBalanceQuery, GetStripeAccountBalanceQueryVariables>;
export const GetCurrentOperatorDocument = gql`
    query GetCurrentOperator {
  currentOperator {
    ...CurrentOperatorDetail
  }
}
    ${CurrentOperatorDetailFragmentDoc}`;

/**
 * __useGetCurrentOperatorQuery__
 *
 * To run a query within a React component, call `useGetCurrentOperatorQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentOperatorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentOperatorQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCurrentOperatorQuery(baseOptions?: Apollo.QueryHookOptions<GetCurrentOperatorQuery, GetCurrentOperatorQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCurrentOperatorQuery, GetCurrentOperatorQueryVariables>(GetCurrentOperatorDocument, options);
      }
export function useGetCurrentOperatorLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCurrentOperatorQuery, GetCurrentOperatorQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCurrentOperatorQuery, GetCurrentOperatorQueryVariables>(GetCurrentOperatorDocument, options);
        }
export type GetCurrentOperatorQueryHookResult = ReturnType<typeof useGetCurrentOperatorQuery>;
export type GetCurrentOperatorLazyQueryHookResult = ReturnType<typeof useGetCurrentOperatorLazyQuery>;
export type GetCurrentOperatorQueryResult = Apollo.QueryResult<GetCurrentOperatorQuery, GetCurrentOperatorQueryVariables>;
export const GetCustomerDocument = gql`
    query GetCustomer($customerId: ID!) {
  customer(id: $customerId) {
    id
    firstName
    firstNameFurigana
    lastName
    lastNameFurigana
    postalCode
    country
    prefCode
    address
    email
    phoneNumber
    workPlace
    description
    images
    status
    reservationList {
      items {
        id
        checkIn
        checkOut
        totalFee
        adultCount
        childCount
        babyCount
        plan {
          id
          name
        }
        roomType {
          id
          name
        }
      }
    }
  }
}
    `;

/**
 * __useGetCustomerQuery__
 *
 * To run a query within a React component, call `useGetCustomerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomerQuery({
 *   variables: {
 *      customerId: // value for 'customerId'
 *   },
 * });
 */
export function useGetCustomerQuery(baseOptions: Apollo.QueryHookOptions<GetCustomerQuery, GetCustomerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCustomerQuery, GetCustomerQueryVariables>(GetCustomerDocument, options);
      }
export function useGetCustomerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCustomerQuery, GetCustomerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCustomerQuery, GetCustomerQueryVariables>(GetCustomerDocument, options);
        }
export type GetCustomerQueryHookResult = ReturnType<typeof useGetCustomerQuery>;
export type GetCustomerLazyQueryHookResult = ReturnType<typeof useGetCustomerLazyQuery>;
export type GetCustomerQueryResult = Apollo.QueryResult<GetCustomerQuery, GetCustomerQueryVariables>;
export const CreateCustomerDocument = gql`
    mutation CreateCustomer($createCustomerInput: CreateCustomerInput!) {
  createCustomer(input: $createCustomerInput) {
    customer {
      id
      firstName
      firstNameFurigana
      lastName
      lastNameFurigana
      postalCode
      country
      prefCode
      address
      buildingName
      workPlace
      email
      phoneNumber
      birthday
      gender
      description
      images
      status
    }
  }
}
    `;
export type CreateCustomerMutationFn = Apollo.MutationFunction<CreateCustomerMutation, CreateCustomerMutationVariables>;

/**
 * __useCreateCustomerMutation__
 *
 * To run a mutation, you first call `useCreateCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCustomerMutation, { data, loading, error }] = useCreateCustomerMutation({
 *   variables: {
 *      createCustomerInput: // value for 'createCustomerInput'
 *   },
 * });
 */
export function useCreateCustomerMutation(baseOptions?: Apollo.MutationHookOptions<CreateCustomerMutation, CreateCustomerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCustomerMutation, CreateCustomerMutationVariables>(CreateCustomerDocument, options);
      }
export type CreateCustomerMutationHookResult = ReturnType<typeof useCreateCustomerMutation>;
export type CreateCustomerMutationResult = Apollo.MutationResult<CreateCustomerMutation>;
export type CreateCustomerMutationOptions = Apollo.BaseMutationOptions<CreateCustomerMutation, CreateCustomerMutationVariables>;
export const GetCustomerListDocument = gql`
    query GetCustomerList($hotelId: ID!) {
  hotel(id: $hotelId) {
    id
    customerList {
      items {
        id
        firstName
        firstNameFurigana
        lastName
        lastNameFurigana
        email
        phoneNumber
        status
      }
    }
  }
}
    `;

/**
 * __useGetCustomerListQuery__
 *
 * To run a query within a React component, call `useGetCustomerListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomerListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomerListQuery({
 *   variables: {
 *      hotelId: // value for 'hotelId'
 *   },
 * });
 */
export function useGetCustomerListQuery(baseOptions: Apollo.QueryHookOptions<GetCustomerListQuery, GetCustomerListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCustomerListQuery, GetCustomerListQueryVariables>(GetCustomerListDocument, options);
      }
export function useGetCustomerListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCustomerListQuery, GetCustomerListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCustomerListQuery, GetCustomerListQueryVariables>(GetCustomerListDocument, options);
        }
export type GetCustomerListQueryHookResult = ReturnType<typeof useGetCustomerListQuery>;
export type GetCustomerListLazyQueryHookResult = ReturnType<typeof useGetCustomerListLazyQuery>;
export type GetCustomerListQueryResult = Apollo.QueryResult<GetCustomerListQuery, GetCustomerListQueryVariables>;
export const GetReservationDashBoardByMonthDocument = gql`
    query GetReservationDashBoardByMonth($hotelId: ID!, $month: String) {
  hotel(id: $hotelId) {
    id
    rateOfOperation
    reservationListByMonth(month: $month) {
      id
      totalFee
      cancelFee
    }
  }
}
    `;

/**
 * __useGetReservationDashBoardByMonthQuery__
 *
 * To run a query within a React component, call `useGetReservationDashBoardByMonthQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReservationDashBoardByMonthQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReservationDashBoardByMonthQuery({
 *   variables: {
 *      hotelId: // value for 'hotelId'
 *      month: // value for 'month'
 *   },
 * });
 */
export function useGetReservationDashBoardByMonthQuery(baseOptions: Apollo.QueryHookOptions<GetReservationDashBoardByMonthQuery, GetReservationDashBoardByMonthQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetReservationDashBoardByMonthQuery, GetReservationDashBoardByMonthQueryVariables>(GetReservationDashBoardByMonthDocument, options);
      }
export function useGetReservationDashBoardByMonthLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetReservationDashBoardByMonthQuery, GetReservationDashBoardByMonthQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetReservationDashBoardByMonthQuery, GetReservationDashBoardByMonthQueryVariables>(GetReservationDashBoardByMonthDocument, options);
        }
export type GetReservationDashBoardByMonthQueryHookResult = ReturnType<typeof useGetReservationDashBoardByMonthQuery>;
export type GetReservationDashBoardByMonthLazyQueryHookResult = ReturnType<typeof useGetReservationDashBoardByMonthLazyQuery>;
export type GetReservationDashBoardByMonthQueryResult = Apollo.QueryResult<GetReservationDashBoardByMonthQuery, GetReservationDashBoardByMonthQueryVariables>;
export const CreateBankAccountDocument = gql`
    mutation CreateBankAccount($createBankAccountInput: CreateBankAccountInput!) {
  createBankAccount(input: $createBankAccountInput) {
    bankAccount {
      id
      bankCode
      bankName
      branchCode
      branchName
      accountNumber
      accountHolderName
    }
  }
}
    `;
export type CreateBankAccountMutationFn = Apollo.MutationFunction<CreateBankAccountMutation, CreateBankAccountMutationVariables>;

/**
 * __useCreateBankAccountMutation__
 *
 * To run a mutation, you first call `useCreateBankAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBankAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBankAccountMutation, { data, loading, error }] = useCreateBankAccountMutation({
 *   variables: {
 *      createBankAccountInput: // value for 'createBankAccountInput'
 *   },
 * });
 */
export function useCreateBankAccountMutation(baseOptions?: Apollo.MutationHookOptions<CreateBankAccountMutation, CreateBankAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateBankAccountMutation, CreateBankAccountMutationVariables>(CreateBankAccountDocument, options);
      }
export type CreateBankAccountMutationHookResult = ReturnType<typeof useCreateBankAccountMutation>;
export type CreateBankAccountMutationResult = Apollo.MutationResult<CreateBankAccountMutation>;
export type CreateBankAccountMutationOptions = Apollo.BaseMutationOptions<CreateBankAccountMutation, CreateBankAccountMutationVariables>;
export const ChangeBankAccountDocument = gql`
    mutation ChangeBankAccount($changeBankAccountInput: ChangeBankAccountInput!) {
  changeBankAccount(input: $changeBankAccountInput) {
    bankAccount {
      id
      bankCode
      bankName
      branchCode
      branchName
      accountNumber
      accountHolderName
    }
  }
}
    `;
export type ChangeBankAccountMutationFn = Apollo.MutationFunction<ChangeBankAccountMutation, ChangeBankAccountMutationVariables>;

/**
 * __useChangeBankAccountMutation__
 *
 * To run a mutation, you first call `useChangeBankAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeBankAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeBankAccountMutation, { data, loading, error }] = useChangeBankAccountMutation({
 *   variables: {
 *      changeBankAccountInput: // value for 'changeBankAccountInput'
 *   },
 * });
 */
export function useChangeBankAccountMutation(baseOptions?: Apollo.MutationHookOptions<ChangeBankAccountMutation, ChangeBankAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangeBankAccountMutation, ChangeBankAccountMutationVariables>(ChangeBankAccountDocument, options);
      }
export type ChangeBankAccountMutationHookResult = ReturnType<typeof useChangeBankAccountMutation>;
export type ChangeBankAccountMutationResult = Apollo.MutationResult<ChangeBankAccountMutation>;
export type ChangeBankAccountMutationOptions = Apollo.BaseMutationOptions<ChangeBankAccountMutation, ChangeBankAccountMutationVariables>;
export const UpdateStripeCompanyDocument = gql`
    mutation UpdateStripeCompany($input: UpdateStripeCompanyInput) {
  updateStripeCompany(input: $input)
}
    `;
export type UpdateStripeCompanyMutationFn = Apollo.MutationFunction<UpdateStripeCompanyMutation, UpdateStripeCompanyMutationVariables>;

/**
 * __useUpdateStripeCompanyMutation__
 *
 * To run a mutation, you first call `useUpdateStripeCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStripeCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStripeCompanyMutation, { data, loading, error }] = useUpdateStripeCompanyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateStripeCompanyMutation(baseOptions?: Apollo.MutationHookOptions<UpdateStripeCompanyMutation, UpdateStripeCompanyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateStripeCompanyMutation, UpdateStripeCompanyMutationVariables>(UpdateStripeCompanyDocument, options);
      }
export type UpdateStripeCompanyMutationHookResult = ReturnType<typeof useUpdateStripeCompanyMutation>;
export type UpdateStripeCompanyMutationResult = Apollo.MutationResult<UpdateStripeCompanyMutation>;
export type UpdateStripeCompanyMutationOptions = Apollo.BaseMutationOptions<UpdateStripeCompanyMutation, UpdateStripeCompanyMutationVariables>;
export const CreateStripePersonDocument = gql`
    mutation CreateStripePerson($input: CreateStripePersonInput) {
  createStripePerson(input: $input)
}
    `;
export type CreateStripePersonMutationFn = Apollo.MutationFunction<CreateStripePersonMutation, CreateStripePersonMutationVariables>;

/**
 * __useCreateStripePersonMutation__
 *
 * To run a mutation, you first call `useCreateStripePersonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateStripePersonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createStripePersonMutation, { data, loading, error }] = useCreateStripePersonMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateStripePersonMutation(baseOptions?: Apollo.MutationHookOptions<CreateStripePersonMutation, CreateStripePersonMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateStripePersonMutation, CreateStripePersonMutationVariables>(CreateStripePersonDocument, options);
      }
export type CreateStripePersonMutationHookResult = ReturnType<typeof useCreateStripePersonMutation>;
export type CreateStripePersonMutationResult = Apollo.MutationResult<CreateStripePersonMutation>;
export type CreateStripePersonMutationOptions = Apollo.BaseMutationOptions<CreateStripePersonMutation, CreateStripePersonMutationVariables>;
export const GetStripeAccountInfoDocument = gql`
    query GetStripeAccountInfo {
  currentOperator {
    id
    stripeAccount {
      hasCompany
      hasPerson
    }
  }
}
    `;

/**
 * __useGetStripeAccountInfoQuery__
 *
 * To run a query within a React component, call `useGetStripeAccountInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStripeAccountInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStripeAccountInfoQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetStripeAccountInfoQuery(baseOptions?: Apollo.QueryHookOptions<GetStripeAccountInfoQuery, GetStripeAccountInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStripeAccountInfoQuery, GetStripeAccountInfoQueryVariables>(GetStripeAccountInfoDocument, options);
      }
export function useGetStripeAccountInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStripeAccountInfoQuery, GetStripeAccountInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStripeAccountInfoQuery, GetStripeAccountInfoQueryVariables>(GetStripeAccountInfoDocument, options);
        }
export type GetStripeAccountInfoQueryHookResult = ReturnType<typeof useGetStripeAccountInfoQuery>;
export type GetStripeAccountInfoLazyQueryHookResult = ReturnType<typeof useGetStripeAccountInfoLazyQuery>;
export type GetStripeAccountInfoQueryResult = Apollo.QueryResult<GetStripeAccountInfoQuery, GetStripeAccountInfoQueryVariables>;
export const UpdateHotelDocument = gql`
    mutation UpdateHotel($updateHotelInput: UpdateHotelInput!) {
  updateHotel(input: $updateHotelInput) {
    hotel {
      id
      name
      nameFurigana
      images
      url
      type
      phoneNumber
      prefCode
      postalCode
      address
      buildingName
      licenseNumber
    }
  }
}
    `;
export type UpdateHotelMutationFn = Apollo.MutationFunction<UpdateHotelMutation, UpdateHotelMutationVariables>;

/**
 * __useUpdateHotelMutation__
 *
 * To run a mutation, you first call `useUpdateHotelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateHotelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateHotelMutation, { data, loading, error }] = useUpdateHotelMutation({
 *   variables: {
 *      updateHotelInput: // value for 'updateHotelInput'
 *   },
 * });
 */
export function useUpdateHotelMutation(baseOptions?: Apollo.MutationHookOptions<UpdateHotelMutation, UpdateHotelMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateHotelMutation, UpdateHotelMutationVariables>(UpdateHotelDocument, options);
      }
export type UpdateHotelMutationHookResult = ReturnType<typeof useUpdateHotelMutation>;
export type UpdateHotelMutationResult = Apollo.MutationResult<UpdateHotelMutation>;
export type UpdateHotelMutationOptions = Apollo.BaseMutationOptions<UpdateHotelMutation, UpdateHotelMutationVariables>;
export const CreateHotelDocument = gql`
    mutation CreateHotel($createHotelInput: CreateHotelInput!) {
  createHotel(input: $createHotelInput) {
    hotel {
      id
      name
      nameFurigana
      images
      url
      type
      phoneNumber
      prefCode
      postalCode
      address
      buildingName
      licenseNumber
    }
  }
}
    `;
export type CreateHotelMutationFn = Apollo.MutationFunction<CreateHotelMutation, CreateHotelMutationVariables>;

/**
 * __useCreateHotelMutation__
 *
 * To run a mutation, you first call `useCreateHotelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateHotelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createHotelMutation, { data, loading, error }] = useCreateHotelMutation({
 *   variables: {
 *      createHotelInput: // value for 'createHotelInput'
 *   },
 * });
 */
export function useCreateHotelMutation(baseOptions?: Apollo.MutationHookOptions<CreateHotelMutation, CreateHotelMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateHotelMutation, CreateHotelMutationVariables>(CreateHotelDocument, options);
      }
export type CreateHotelMutationHookResult = ReturnType<typeof useCreateHotelMutation>;
export type CreateHotelMutationResult = Apollo.MutationResult<CreateHotelMutation>;
export type CreateHotelMutationOptions = Apollo.BaseMutationOptions<CreateHotelMutation, CreateHotelMutationVariables>;
export const GetCurrentManageHotelSettingDocument = gql`
    query GetCurrentManageHotelSetting($hotelId: ID!) {
  hotel(id: $hotelId) {
    ...CurrentManageHotel
  }
}
    ${CurrentManageHotelFragmentDoc}`;

/**
 * __useGetCurrentManageHotelSettingQuery__
 *
 * To run a query within a React component, call `useGetCurrentManageHotelSettingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentManageHotelSettingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentManageHotelSettingQuery({
 *   variables: {
 *      hotelId: // value for 'hotelId'
 *   },
 * });
 */
export function useGetCurrentManageHotelSettingQuery(baseOptions: Apollo.QueryHookOptions<GetCurrentManageHotelSettingQuery, GetCurrentManageHotelSettingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCurrentManageHotelSettingQuery, GetCurrentManageHotelSettingQueryVariables>(GetCurrentManageHotelSettingDocument, options);
      }
export function useGetCurrentManageHotelSettingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCurrentManageHotelSettingQuery, GetCurrentManageHotelSettingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCurrentManageHotelSettingQuery, GetCurrentManageHotelSettingQueryVariables>(GetCurrentManageHotelSettingDocument, options);
        }
export type GetCurrentManageHotelSettingQueryHookResult = ReturnType<typeof useGetCurrentManageHotelSettingQuery>;
export type GetCurrentManageHotelSettingLazyQueryHookResult = ReturnType<typeof useGetCurrentManageHotelSettingLazyQuery>;
export type GetCurrentManageHotelSettingQueryResult = Apollo.QueryResult<GetCurrentManageHotelSettingQuery, GetCurrentManageHotelSettingQueryVariables>;
export const GetInvoicesDocument = gql`
    query getInvoices($hotelId: ID!) {
  hotel(id: $hotelId) {
    id
    invoices {
      id
      amount
      isPaid
      stripeInvoiceNum
      paidAt
    }
  }
}
    `;

/**
 * __useGetInvoicesQuery__
 *
 * To run a query within a React component, call `useGetInvoicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInvoicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInvoicesQuery({
 *   variables: {
 *      hotelId: // value for 'hotelId'
 *   },
 * });
 */
export function useGetInvoicesQuery(baseOptions: Apollo.QueryHookOptions<GetInvoicesQuery, GetInvoicesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetInvoicesQuery, GetInvoicesQueryVariables>(GetInvoicesDocument, options);
      }
export function useGetInvoicesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetInvoicesQuery, GetInvoicesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetInvoicesQuery, GetInvoicesQueryVariables>(GetInvoicesDocument, options);
        }
export type GetInvoicesQueryHookResult = ReturnType<typeof useGetInvoicesQuery>;
export type GetInvoicesLazyQueryHookResult = ReturnType<typeof useGetInvoicesLazyQuery>;
export type GetInvoicesQueryResult = Apollo.QueryResult<GetInvoicesQuery, GetInvoicesQueryVariables>;
export const GetInvoiceDocument = gql`
    query getInvoice($hotelId: ID!, $invoiceId: String!) {
  hotel(id: $hotelId) {
    id
    invoice(invoiceId: $invoiceId) {
      id
      amount
      isPaid
      stripeInvoiceNum
      paidAt
    }
  }
}
    `;

/**
 * __useGetInvoiceQuery__
 *
 * To run a query within a React component, call `useGetInvoiceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInvoiceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInvoiceQuery({
 *   variables: {
 *      hotelId: // value for 'hotelId'
 *      invoiceId: // value for 'invoiceId'
 *   },
 * });
 */
export function useGetInvoiceQuery(baseOptions: Apollo.QueryHookOptions<GetInvoiceQuery, GetInvoiceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetInvoiceQuery, GetInvoiceQueryVariables>(GetInvoiceDocument, options);
      }
export function useGetInvoiceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetInvoiceQuery, GetInvoiceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetInvoiceQuery, GetInvoiceQueryVariables>(GetInvoiceDocument, options);
        }
export type GetInvoiceQueryHookResult = ReturnType<typeof useGetInvoiceQuery>;
export type GetInvoiceLazyQueryHookResult = ReturnType<typeof useGetInvoiceLazyQuery>;
export type GetInvoiceQueryResult = Apollo.QueryResult<GetInvoiceQuery, GetInvoiceQueryVariables>;
export const GetReservationListByMonthDocument = gql`
    query GetReservationListByMonth($hotelId: ID!, $month: String) {
  hotel(id: $hotelId) {
    id
    reservationListByMonth(month: $month) {
      id
      checkIn
      checkOut
      firstName
      lastName
      stayStatus
      roomCount
      plan {
        id
        name
      }
      totalFee
      cancelFee
      closedAt
      adultCount
      childCount
      babyCount
      createdAt
      customer {
        id
        firstName
        firstNameFurigana
        lastName
        lastNameFurigana
      }
    }
  }
}
    `;

/**
 * __useGetReservationListByMonthQuery__
 *
 * To run a query within a React component, call `useGetReservationListByMonthQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReservationListByMonthQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReservationListByMonthQuery({
 *   variables: {
 *      hotelId: // value for 'hotelId'
 *      month: // value for 'month'
 *   },
 * });
 */
export function useGetReservationListByMonthQuery(baseOptions: Apollo.QueryHookOptions<GetReservationListByMonthQuery, GetReservationListByMonthQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetReservationListByMonthQuery, GetReservationListByMonthQueryVariables>(GetReservationListByMonthDocument, options);
      }
export function useGetReservationListByMonthLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetReservationListByMonthQuery, GetReservationListByMonthQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetReservationListByMonthQuery, GetReservationListByMonthQueryVariables>(GetReservationListByMonthDocument, options);
        }
export type GetReservationListByMonthQueryHookResult = ReturnType<typeof useGetReservationListByMonthQuery>;
export type GetReservationListByMonthLazyQueryHookResult = ReturnType<typeof useGetReservationListByMonthLazyQuery>;
export type GetReservationListByMonthQueryResult = Apollo.QueryResult<GetReservationListByMonthQuery, GetReservationListByMonthQueryVariables>;
export const GetMonthlyIncomeListDocument = gql`
    query GetMonthlyIncomeList($hotelId: ID!) {
  hotel(id: $hotelId) {
    id
    monthlyIncomeList {
      items {
        amount
        targetYearMonth
      }
    }
  }
}
    `;

/**
 * __useGetMonthlyIncomeListQuery__
 *
 * To run a query within a React component, call `useGetMonthlyIncomeListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMonthlyIncomeListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMonthlyIncomeListQuery({
 *   variables: {
 *      hotelId: // value for 'hotelId'
 *   },
 * });
 */
export function useGetMonthlyIncomeListQuery(baseOptions: Apollo.QueryHookOptions<GetMonthlyIncomeListQuery, GetMonthlyIncomeListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMonthlyIncomeListQuery, GetMonthlyIncomeListQueryVariables>(GetMonthlyIncomeListDocument, options);
      }
export function useGetMonthlyIncomeListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMonthlyIncomeListQuery, GetMonthlyIncomeListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMonthlyIncomeListQuery, GetMonthlyIncomeListQueryVariables>(GetMonthlyIncomeListDocument, options);
        }
export type GetMonthlyIncomeListQueryHookResult = ReturnType<typeof useGetMonthlyIncomeListQuery>;
export type GetMonthlyIncomeListLazyQueryHookResult = ReturnType<typeof useGetMonthlyIncomeListLazyQuery>;
export type GetMonthlyIncomeListQueryResult = Apollo.QueryResult<GetMonthlyIncomeListQuery, GetMonthlyIncomeListQueryVariables>;
export const UpdateOperatorDocument = gql`
    mutation updateOperator($updateOperatorInput: UpdateOperatorInput!) {
  updateOperator(input: $updateOperatorInput) {
    operator {
      id
      fullName
      fullNameFurigana
      email
      phoneNumber
    }
  }
}
    `;
export type UpdateOperatorMutationFn = Apollo.MutationFunction<UpdateOperatorMutation, UpdateOperatorMutationVariables>;

/**
 * __useUpdateOperatorMutation__
 *
 * To run a mutation, you first call `useUpdateOperatorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOperatorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOperatorMutation, { data, loading, error }] = useUpdateOperatorMutation({
 *   variables: {
 *      updateOperatorInput: // value for 'updateOperatorInput'
 *   },
 * });
 */
export function useUpdateOperatorMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOperatorMutation, UpdateOperatorMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOperatorMutation, UpdateOperatorMutationVariables>(UpdateOperatorDocument, options);
      }
export type UpdateOperatorMutationHookResult = ReturnType<typeof useUpdateOperatorMutation>;
export type UpdateOperatorMutationResult = Apollo.MutationResult<UpdateOperatorMutation>;
export type UpdateOperatorMutationOptions = Apollo.BaseMutationOptions<UpdateOperatorMutation, UpdateOperatorMutationVariables>;
export const GetOperatorDetailDocument = gql`
    query GetOperatorDetail($operatorId: ID!) {
  operator(id: $operatorId) {
    id
    fullName
    fullNameFurigana
    email
    phoneNumber
  }
}
    `;

/**
 * __useGetOperatorDetailQuery__
 *
 * To run a query within a React component, call `useGetOperatorDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOperatorDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOperatorDetailQuery({
 *   variables: {
 *      operatorId: // value for 'operatorId'
 *   },
 * });
 */
export function useGetOperatorDetailQuery(baseOptions: Apollo.QueryHookOptions<GetOperatorDetailQuery, GetOperatorDetailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOperatorDetailQuery, GetOperatorDetailQueryVariables>(GetOperatorDetailDocument, options);
      }
export function useGetOperatorDetailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOperatorDetailQuery, GetOperatorDetailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOperatorDetailQuery, GetOperatorDetailQueryVariables>(GetOperatorDetailDocument, options);
        }
export type GetOperatorDetailQueryHookResult = ReturnType<typeof useGetOperatorDetailQuery>;
export type GetOperatorDetailLazyQueryHookResult = ReturnType<typeof useGetOperatorDetailLazyQuery>;
export type GetOperatorDetailQueryResult = Apollo.QueryResult<GetOperatorDetailQuery, GetOperatorDetailQueryVariables>;
export const DeleteOperatorDocument = gql`
    mutation DeleteOperator($input: DeleteOperatorInput!) {
  deleteOperator(input: $input) {
    operator {
      id
    }
  }
}
    `;
export type DeleteOperatorMutationFn = Apollo.MutationFunction<DeleteOperatorMutation, DeleteOperatorMutationVariables>;

/**
 * __useDeleteOperatorMutation__
 *
 * To run a mutation, you first call `useDeleteOperatorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOperatorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOperatorMutation, { data, loading, error }] = useDeleteOperatorMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteOperatorMutation(baseOptions?: Apollo.MutationHookOptions<DeleteOperatorMutation, DeleteOperatorMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteOperatorMutation, DeleteOperatorMutationVariables>(DeleteOperatorDocument, options);
      }
export type DeleteOperatorMutationHookResult = ReturnType<typeof useDeleteOperatorMutation>;
export type DeleteOperatorMutationResult = Apollo.MutationResult<DeleteOperatorMutation>;
export type DeleteOperatorMutationOptions = Apollo.BaseMutationOptions<DeleteOperatorMutation, DeleteOperatorMutationVariables>;
export const CreateOperatorDocument = gql`
    mutation CreateOperator($createOperatorInput: CreateOperatorInput!) {
  createOperator(input: $createOperatorInput) {
    operator {
      id
      fullName
      fullNameFurigana
      email
      phoneNumber
      hotelList {
        items {
          id
          name
        }
      }
    }
  }
}
    `;
export type CreateOperatorMutationFn = Apollo.MutationFunction<CreateOperatorMutation, CreateOperatorMutationVariables>;

/**
 * __useCreateOperatorMutation__
 *
 * To run a mutation, you first call `useCreateOperatorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOperatorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOperatorMutation, { data, loading, error }] = useCreateOperatorMutation({
 *   variables: {
 *      createOperatorInput: // value for 'createOperatorInput'
 *   },
 * });
 */
export function useCreateOperatorMutation(baseOptions?: Apollo.MutationHookOptions<CreateOperatorMutation, CreateOperatorMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOperatorMutation, CreateOperatorMutationVariables>(CreateOperatorDocument, options);
      }
export type CreateOperatorMutationHookResult = ReturnType<typeof useCreateOperatorMutation>;
export type CreateOperatorMutationResult = Apollo.MutationResult<CreateOperatorMutation>;
export type CreateOperatorMutationOptions = Apollo.BaseMutationOptions<CreateOperatorMutation, CreateOperatorMutationVariables>;
export const CreateRegularOperatorDocument = gql`
    mutation CreateRegularOperator($createRegularOperatorInput: CreateRegularOperatorInput!) {
  createRegularOperator(input: $createRegularOperatorInput) {
    operator {
      id
      fullName
      fullNameFurigana
      email
      phoneNumber
      hotelList {
        items {
          id
          name
        }
      }
    }
  }
}
    `;
export type CreateRegularOperatorMutationFn = Apollo.MutationFunction<CreateRegularOperatorMutation, CreateRegularOperatorMutationVariables>;

/**
 * __useCreateRegularOperatorMutation__
 *
 * To run a mutation, you first call `useCreateRegularOperatorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRegularOperatorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRegularOperatorMutation, { data, loading, error }] = useCreateRegularOperatorMutation({
 *   variables: {
 *      createRegularOperatorInput: // value for 'createRegularOperatorInput'
 *   },
 * });
 */
export function useCreateRegularOperatorMutation(baseOptions?: Apollo.MutationHookOptions<CreateRegularOperatorMutation, CreateRegularOperatorMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateRegularOperatorMutation, CreateRegularOperatorMutationVariables>(CreateRegularOperatorDocument, options);
      }
export type CreateRegularOperatorMutationHookResult = ReturnType<typeof useCreateRegularOperatorMutation>;
export type CreateRegularOperatorMutationResult = Apollo.MutationResult<CreateRegularOperatorMutation>;
export type CreateRegularOperatorMutationOptions = Apollo.BaseMutationOptions<CreateRegularOperatorMutation, CreateRegularOperatorMutationVariables>;
export const GetOperatorListDocument = gql`
    query GetOperatorList($hotelId: ID!) {
  hotel(id: $hotelId) {
    id
    jammyPlanSubscription {
      id
      jammyPlan {
        id
        operatorLimit
        roomLimit
      }
    }
    operatorList {
      items {
        id
        fullName
        email
        phoneNumber
      }
      total
    }
  }
}
    `;

/**
 * __useGetOperatorListQuery__
 *
 * To run a query within a React component, call `useGetOperatorListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOperatorListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOperatorListQuery({
 *   variables: {
 *      hotelId: // value for 'hotelId'
 *   },
 * });
 */
export function useGetOperatorListQuery(baseOptions: Apollo.QueryHookOptions<GetOperatorListQuery, GetOperatorListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOperatorListQuery, GetOperatorListQueryVariables>(GetOperatorListDocument, options);
      }
export function useGetOperatorListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOperatorListQuery, GetOperatorListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOperatorListQuery, GetOperatorListQueryVariables>(GetOperatorListDocument, options);
        }
export type GetOperatorListQueryHookResult = ReturnType<typeof useGetOperatorListQuery>;
export type GetOperatorListLazyQueryHookResult = ReturnType<typeof useGetOperatorListLazyQuery>;
export type GetOperatorListQueryResult = Apollo.QueryResult<GetOperatorListQuery, GetOperatorListQueryVariables>;
export const UpdateOptionDocument = gql`
    mutation UpdateOption($updateOptionInput: UpdateOptionInput!) {
  updateOption(input: $updateOptionInput) {
    option {
      id
      name
      description
      unit
      fee
      images
      isPublic
    }
  }
}
    `;
export type UpdateOptionMutationFn = Apollo.MutationFunction<UpdateOptionMutation, UpdateOptionMutationVariables>;

/**
 * __useUpdateOptionMutation__
 *
 * To run a mutation, you first call `useUpdateOptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOptionMutation, { data, loading, error }] = useUpdateOptionMutation({
 *   variables: {
 *      updateOptionInput: // value for 'updateOptionInput'
 *   },
 * });
 */
export function useUpdateOptionMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOptionMutation, UpdateOptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOptionMutation, UpdateOptionMutationVariables>(UpdateOptionDocument, options);
      }
export type UpdateOptionMutationHookResult = ReturnType<typeof useUpdateOptionMutation>;
export type UpdateOptionMutationResult = Apollo.MutationResult<UpdateOptionMutation>;
export type UpdateOptionMutationOptions = Apollo.BaseMutationOptions<UpdateOptionMutation, UpdateOptionMutationVariables>;
export const DeleteOptionDocument = gql`
    mutation DeleteOption($deleteOptionInput: DeleteOptionInput!) {
  deleteOption(input: $deleteOptionInput) {
    option {
      id
      name
    }
  }
}
    `;
export type DeleteOptionMutationFn = Apollo.MutationFunction<DeleteOptionMutation, DeleteOptionMutationVariables>;

/**
 * __useDeleteOptionMutation__
 *
 * To run a mutation, you first call `useDeleteOptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOptionMutation, { data, loading, error }] = useDeleteOptionMutation({
 *   variables: {
 *      deleteOptionInput: // value for 'deleteOptionInput'
 *   },
 * });
 */
export function useDeleteOptionMutation(baseOptions?: Apollo.MutationHookOptions<DeleteOptionMutation, DeleteOptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteOptionMutation, DeleteOptionMutationVariables>(DeleteOptionDocument, options);
      }
export type DeleteOptionMutationHookResult = ReturnType<typeof useDeleteOptionMutation>;
export type DeleteOptionMutationResult = Apollo.MutationResult<DeleteOptionMutation>;
export type DeleteOptionMutationOptions = Apollo.BaseMutationOptions<DeleteOptionMutation, DeleteOptionMutationVariables>;
export const GetOptionDocument = gql`
    query GetOption($optionId: ID!) {
  option(id: $optionId) {
    ...OptionDetail
  }
}
    ${OptionDetailFragmentDoc}`;

/**
 * __useGetOptionQuery__
 *
 * To run a query within a React component, call `useGetOptionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOptionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOptionQuery({
 *   variables: {
 *      optionId: // value for 'optionId'
 *   },
 * });
 */
export function useGetOptionQuery(baseOptions: Apollo.QueryHookOptions<GetOptionQuery, GetOptionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOptionQuery, GetOptionQueryVariables>(GetOptionDocument, options);
      }
export function useGetOptionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOptionQuery, GetOptionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOptionQuery, GetOptionQueryVariables>(GetOptionDocument, options);
        }
export type GetOptionQueryHookResult = ReturnType<typeof useGetOptionQuery>;
export type GetOptionLazyQueryHookResult = ReturnType<typeof useGetOptionLazyQuery>;
export type GetOptionQueryResult = Apollo.QueryResult<GetOptionQuery, GetOptionQueryVariables>;
export const CreateOptionDocument = gql`
    mutation CreateOption($createOptionInput: CreateOptionInput!) {
  createOption(input: $createOptionInput) {
    option {
      id
      name
      description
      unit
      fee
    }
  }
}
    `;
export type CreateOptionMutationFn = Apollo.MutationFunction<CreateOptionMutation, CreateOptionMutationVariables>;

/**
 * __useCreateOptionMutation__
 *
 * To run a mutation, you first call `useCreateOptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOptionMutation, { data, loading, error }] = useCreateOptionMutation({
 *   variables: {
 *      createOptionInput: // value for 'createOptionInput'
 *   },
 * });
 */
export function useCreateOptionMutation(baseOptions?: Apollo.MutationHookOptions<CreateOptionMutation, CreateOptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOptionMutation, CreateOptionMutationVariables>(CreateOptionDocument, options);
      }
export type CreateOptionMutationHookResult = ReturnType<typeof useCreateOptionMutation>;
export type CreateOptionMutationResult = Apollo.MutationResult<CreateOptionMutation>;
export type CreateOptionMutationOptions = Apollo.BaseMutationOptions<CreateOptionMutation, CreateOptionMutationVariables>;
export const GetOptionListDocument = gql`
    query GetOptionList($hotelId: ID!) {
  hotel(id: $hotelId) {
    id
    optionList {
      items {
        id
        images
        description
        name
        fee
        unit
      }
    }
  }
}
    `;

/**
 * __useGetOptionListQuery__
 *
 * To run a query within a React component, call `useGetOptionListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOptionListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOptionListQuery({
 *   variables: {
 *      hotelId: // value for 'hotelId'
 *   },
 * });
 */
export function useGetOptionListQuery(baseOptions: Apollo.QueryHookOptions<GetOptionListQuery, GetOptionListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOptionListQuery, GetOptionListQueryVariables>(GetOptionListDocument, options);
      }
export function useGetOptionListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOptionListQuery, GetOptionListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOptionListQuery, GetOptionListQueryVariables>(GetOptionListDocument, options);
        }
export type GetOptionListQueryHookResult = ReturnType<typeof useGetOptionListQuery>;
export type GetOptionListLazyQueryHookResult = ReturnType<typeof useGetOptionListLazyQuery>;
export type GetOptionListQueryResult = Apollo.QueryResult<GetOptionListQuery, GetOptionListQueryVariables>;
export const CreatePaymentMethodForHotelDocument = gql`
    mutation CreatePaymentMethodForHotel($input: CreatePaymentMethodForHotelInput!) {
  createPaymentMethodForHotel(input: $input) {
    paymentMethodForHotel {
      id
      brand
      last4
    }
  }
}
    `;
export type CreatePaymentMethodForHotelMutationFn = Apollo.MutationFunction<CreatePaymentMethodForHotelMutation, CreatePaymentMethodForHotelMutationVariables>;

/**
 * __useCreatePaymentMethodForHotelMutation__
 *
 * To run a mutation, you first call `useCreatePaymentMethodForHotelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePaymentMethodForHotelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPaymentMethodForHotelMutation, { data, loading, error }] = useCreatePaymentMethodForHotelMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePaymentMethodForHotelMutation(baseOptions?: Apollo.MutationHookOptions<CreatePaymentMethodForHotelMutation, CreatePaymentMethodForHotelMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePaymentMethodForHotelMutation, CreatePaymentMethodForHotelMutationVariables>(CreatePaymentMethodForHotelDocument, options);
      }
export type CreatePaymentMethodForHotelMutationHookResult = ReturnType<typeof useCreatePaymentMethodForHotelMutation>;
export type CreatePaymentMethodForHotelMutationResult = Apollo.MutationResult<CreatePaymentMethodForHotelMutation>;
export type CreatePaymentMethodForHotelMutationOptions = Apollo.BaseMutationOptions<CreatePaymentMethodForHotelMutation, CreatePaymentMethodForHotelMutationVariables>;
export const SetDefaultPaymentMethodForHotelDocument = gql`
    mutation SetDefaultPaymentMethodForHotel($input: SetDefaultPaymentMethodInput!) {
  setDefaultPaymentMethodForHotel(input: $input)
}
    `;
export type SetDefaultPaymentMethodForHotelMutationFn = Apollo.MutationFunction<SetDefaultPaymentMethodForHotelMutation, SetDefaultPaymentMethodForHotelMutationVariables>;

/**
 * __useSetDefaultPaymentMethodForHotelMutation__
 *
 * To run a mutation, you first call `useSetDefaultPaymentMethodForHotelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetDefaultPaymentMethodForHotelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setDefaultPaymentMethodForHotelMutation, { data, loading, error }] = useSetDefaultPaymentMethodForHotelMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetDefaultPaymentMethodForHotelMutation(baseOptions?: Apollo.MutationHookOptions<SetDefaultPaymentMethodForHotelMutation, SetDefaultPaymentMethodForHotelMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetDefaultPaymentMethodForHotelMutation, SetDefaultPaymentMethodForHotelMutationVariables>(SetDefaultPaymentMethodForHotelDocument, options);
      }
export type SetDefaultPaymentMethodForHotelMutationHookResult = ReturnType<typeof useSetDefaultPaymentMethodForHotelMutation>;
export type SetDefaultPaymentMethodForHotelMutationResult = Apollo.MutationResult<SetDefaultPaymentMethodForHotelMutation>;
export type SetDefaultPaymentMethodForHotelMutationOptions = Apollo.BaseMutationOptions<SetDefaultPaymentMethodForHotelMutation, SetDefaultPaymentMethodForHotelMutationVariables>;
export const UpdatePlanDocument = gql`
    mutation UpdatePlan($updatePlanInput: UpdatePlanInput!) {
  updatePlan(input: $updatePlanInput) {
    plan {
      id
      name
      description
      checkInStartTime
      checkInEndTime
      checkOutStartTime
      checkOutEndTime
      isPublic
    }
  }
}
    `;
export type UpdatePlanMutationFn = Apollo.MutationFunction<UpdatePlanMutation, UpdatePlanMutationVariables>;

/**
 * __useUpdatePlanMutation__
 *
 * To run a mutation, you first call `useUpdatePlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePlanMutation, { data, loading, error }] = useUpdatePlanMutation({
 *   variables: {
 *      updatePlanInput: // value for 'updatePlanInput'
 *   },
 * });
 */
export function useUpdatePlanMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePlanMutation, UpdatePlanMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePlanMutation, UpdatePlanMutationVariables>(UpdatePlanDocument, options);
      }
export type UpdatePlanMutationHookResult = ReturnType<typeof useUpdatePlanMutation>;
export type UpdatePlanMutationResult = Apollo.MutationResult<UpdatePlanMutation>;
export type UpdatePlanMutationOptions = Apollo.BaseMutationOptions<UpdatePlanMutation, UpdatePlanMutationVariables>;
export const DeletePlanDocument = gql`
    mutation DeletePlan($deletePlanInput: DeletePlanInput!) {
  deletePlan(input: $deletePlanInput) {
    plan {
      id
      name
    }
  }
}
    `;
export type DeletePlanMutationFn = Apollo.MutationFunction<DeletePlanMutation, DeletePlanMutationVariables>;

/**
 * __useDeletePlanMutation__
 *
 * To run a mutation, you first call `useDeletePlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePlanMutation, { data, loading, error }] = useDeletePlanMutation({
 *   variables: {
 *      deletePlanInput: // value for 'deletePlanInput'
 *   },
 * });
 */
export function useDeletePlanMutation(baseOptions?: Apollo.MutationHookOptions<DeletePlanMutation, DeletePlanMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeletePlanMutation, DeletePlanMutationVariables>(DeletePlanDocument, options);
      }
export type DeletePlanMutationHookResult = ReturnType<typeof useDeletePlanMutation>;
export type DeletePlanMutationResult = Apollo.MutationResult<DeletePlanMutation>;
export type DeletePlanMutationOptions = Apollo.BaseMutationOptions<DeletePlanMutation, DeletePlanMutationVariables>;
export const CreatePlansOnOptionsDocument = gql`
    mutation CreatePlansOnOptions($input: CreatePlansOnOptionsInput!) {
  createPlansOnOptions(input: $input)
}
    `;
export type CreatePlansOnOptionsMutationFn = Apollo.MutationFunction<CreatePlansOnOptionsMutation, CreatePlansOnOptionsMutationVariables>;

/**
 * __useCreatePlansOnOptionsMutation__
 *
 * To run a mutation, you first call `useCreatePlansOnOptionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePlansOnOptionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPlansOnOptionsMutation, { data, loading, error }] = useCreatePlansOnOptionsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePlansOnOptionsMutation(baseOptions?: Apollo.MutationHookOptions<CreatePlansOnOptionsMutation, CreatePlansOnOptionsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePlansOnOptionsMutation, CreatePlansOnOptionsMutationVariables>(CreatePlansOnOptionsDocument, options);
      }
export type CreatePlansOnOptionsMutationHookResult = ReturnType<typeof useCreatePlansOnOptionsMutation>;
export type CreatePlansOnOptionsMutationResult = Apollo.MutationResult<CreatePlansOnOptionsMutation>;
export type CreatePlansOnOptionsMutationOptions = Apollo.BaseMutationOptions<CreatePlansOnOptionsMutation, CreatePlansOnOptionsMutationVariables>;
export const DeletePlansOnOptionsDocument = gql`
    mutation DeletePlansOnOptions($input: DeletePlansOnOptionsInput!) {
  deletePlansOnOptions(input: $input)
}
    `;
export type DeletePlansOnOptionsMutationFn = Apollo.MutationFunction<DeletePlansOnOptionsMutation, DeletePlansOnOptionsMutationVariables>;

/**
 * __useDeletePlansOnOptionsMutation__
 *
 * To run a mutation, you first call `useDeletePlansOnOptionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePlansOnOptionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePlansOnOptionsMutation, { data, loading, error }] = useDeletePlansOnOptionsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeletePlansOnOptionsMutation(baseOptions?: Apollo.MutationHookOptions<DeletePlansOnOptionsMutation, DeletePlansOnOptionsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeletePlansOnOptionsMutation, DeletePlansOnOptionsMutationVariables>(DeletePlansOnOptionsDocument, options);
      }
export type DeletePlansOnOptionsMutationHookResult = ReturnType<typeof useDeletePlansOnOptionsMutation>;
export type DeletePlansOnOptionsMutationResult = Apollo.MutationResult<DeletePlansOnOptionsMutation>;
export type DeletePlansOnOptionsMutationOptions = Apollo.BaseMutationOptions<DeletePlansOnOptionsMutation, DeletePlansOnOptionsMutationVariables>;
export const CreatePricePairingDocument = gql`
    mutation CreatePricePairing($input: CreatePricePairingInput!) {
  createPricePairing(input: $input) {
    pricePairing {
      id
      plan {
        id
        feeType
      }
    }
  }
}
    `;
export type CreatePricePairingMutationFn = Apollo.MutationFunction<CreatePricePairingMutation, CreatePricePairingMutationVariables>;

/**
 * __useCreatePricePairingMutation__
 *
 * To run a mutation, you first call `useCreatePricePairingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePricePairingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPricePairingMutation, { data, loading, error }] = useCreatePricePairingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePricePairingMutation(baseOptions?: Apollo.MutationHookOptions<CreatePricePairingMutation, CreatePricePairingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePricePairingMutation, CreatePricePairingMutationVariables>(CreatePricePairingDocument, options);
      }
export type CreatePricePairingMutationHookResult = ReturnType<typeof useCreatePricePairingMutation>;
export type CreatePricePairingMutationResult = Apollo.MutationResult<CreatePricePairingMutation>;
export type CreatePricePairingMutationOptions = Apollo.BaseMutationOptions<CreatePricePairingMutation, CreatePricePairingMutationVariables>;
export const DeletePricePairingDocument = gql`
    mutation DeletePricePairing($input: DeletePricePairingInput!) {
  deletePricePairing(input: $input)
}
    `;
export type DeletePricePairingMutationFn = Apollo.MutationFunction<DeletePricePairingMutation, DeletePricePairingMutationVariables>;

/**
 * __useDeletePricePairingMutation__
 *
 * To run a mutation, you first call `useDeletePricePairingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePricePairingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePricePairingMutation, { data, loading, error }] = useDeletePricePairingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeletePricePairingMutation(baseOptions?: Apollo.MutationHookOptions<DeletePricePairingMutation, DeletePricePairingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeletePricePairingMutation, DeletePricePairingMutationVariables>(DeletePricePairingDocument, options);
      }
export type DeletePricePairingMutationHookResult = ReturnType<typeof useDeletePricePairingMutation>;
export type DeletePricePairingMutationResult = Apollo.MutationResult<DeletePricePairingMutation>;
export type DeletePricePairingMutationOptions = Apollo.BaseMutationOptions<DeletePricePairingMutation, DeletePricePairingMutationVariables>;
export const GetPlanDocument = gql`
    query GetPlan($planId: ID!) {
  plan(id: $planId) {
    ...PlanDetail
  }
}
    ${PlanDetailFragmentDoc}`;

/**
 * __useGetPlanQuery__
 *
 * To run a query within a React component, call `useGetPlanQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPlanQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPlanQuery({
 *   variables: {
 *      planId: // value for 'planId'
 *   },
 * });
 */
export function useGetPlanQuery(baseOptions: Apollo.QueryHookOptions<GetPlanQuery, GetPlanQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPlanQuery, GetPlanQueryVariables>(GetPlanDocument, options);
      }
export function useGetPlanLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPlanQuery, GetPlanQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPlanQuery, GetPlanQueryVariables>(GetPlanDocument, options);
        }
export type GetPlanQueryHookResult = ReturnType<typeof useGetPlanQuery>;
export type GetPlanLazyQueryHookResult = ReturnType<typeof useGetPlanLazyQuery>;
export type GetPlanQueryResult = Apollo.QueryResult<GetPlanQuery, GetPlanQueryVariables>;
export const CreatePlanDocument = gql`
    mutation CreatePlan($createPlanInput: CreatePlanInput!) {
  createPlan(input: $createPlanInput) {
    plan {
      id
      name
      description
      images
      checkInStartTime
      checkInEndTime
      checkOutStartTime
      checkOutEndTime
      haveBreakfast
      haveDinner
      reservationConfirmationMethod
      reservationDeadline
      feeType
    }
  }
}
    `;
export type CreatePlanMutationFn = Apollo.MutationFunction<CreatePlanMutation, CreatePlanMutationVariables>;

/**
 * __useCreatePlanMutation__
 *
 * To run a mutation, you first call `useCreatePlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPlanMutation, { data, loading, error }] = useCreatePlanMutation({
 *   variables: {
 *      createPlanInput: // value for 'createPlanInput'
 *   },
 * });
 */
export function useCreatePlanMutation(baseOptions?: Apollo.MutationHookOptions<CreatePlanMutation, CreatePlanMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePlanMutation, CreatePlanMutationVariables>(CreatePlanDocument, options);
      }
export type CreatePlanMutationHookResult = ReturnType<typeof useCreatePlanMutation>;
export type CreatePlanMutationResult = Apollo.MutationResult<CreatePlanMutation>;
export type CreatePlanMutationOptions = Apollo.BaseMutationOptions<CreatePlanMutation, CreatePlanMutationVariables>;
export const CalculateTotalFeeDocument = gql`
    query CalculateTotalFee($roomTypeId: ID!, $planId: ID!, $roomCount: Int, $babyCount: Int, $childCount: Int, $adultCount: Int, $checkOut: Timestamp, $checkIn: Timestamp) {
  calculateTotalFee(
    roomTypeId: $roomTypeId
    planId: $planId
    roomCount: $roomCount
    babyCount: $babyCount
    childCount: $childCount
    adultCount: $adultCount
    checkOut: $checkOut
    checkIn: $checkIn
  )
}
    `;

/**
 * __useCalculateTotalFeeQuery__
 *
 * To run a query within a React component, call `useCalculateTotalFeeQuery` and pass it any options that fit your needs.
 * When your component renders, `useCalculateTotalFeeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCalculateTotalFeeQuery({
 *   variables: {
 *      roomTypeId: // value for 'roomTypeId'
 *      planId: // value for 'planId'
 *      roomCount: // value for 'roomCount'
 *      babyCount: // value for 'babyCount'
 *      childCount: // value for 'childCount'
 *      adultCount: // value for 'adultCount'
 *      checkOut: // value for 'checkOut'
 *      checkIn: // value for 'checkIn'
 *   },
 * });
 */
export function useCalculateTotalFeeQuery(baseOptions: Apollo.QueryHookOptions<CalculateTotalFeeQuery, CalculateTotalFeeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CalculateTotalFeeQuery, CalculateTotalFeeQueryVariables>(CalculateTotalFeeDocument, options);
      }
export function useCalculateTotalFeeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CalculateTotalFeeQuery, CalculateTotalFeeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CalculateTotalFeeQuery, CalculateTotalFeeQueryVariables>(CalculateTotalFeeDocument, options);
        }
export type CalculateTotalFeeQueryHookResult = ReturnType<typeof useCalculateTotalFeeQuery>;
export type CalculateTotalFeeLazyQueryHookResult = ReturnType<typeof useCalculateTotalFeeLazyQuery>;
export type CalculateTotalFeeQueryResult = Apollo.QueryResult<CalculateTotalFeeQuery, CalculateTotalFeeQueryVariables>;
export const GetPlanListByOperatorDocument = gql`
    query GetPlanListByOperator($hotelId: ID!, $limit: Int, $offset: Int) {
  hotel(id: $hotelId) {
    id
    jammyPlanSubscription {
      id
    }
    planListByOperator(limit: $limit, offset: $offset) {
      total
      items {
        ...PlanListItem
      }
    }
  }
}
    ${PlanListItemFragmentDoc}`;

/**
 * __useGetPlanListByOperatorQuery__
 *
 * To run a query within a React component, call `useGetPlanListByOperatorQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPlanListByOperatorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPlanListByOperatorQuery({
 *   variables: {
 *      hotelId: // value for 'hotelId'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetPlanListByOperatorQuery(baseOptions: Apollo.QueryHookOptions<GetPlanListByOperatorQuery, GetPlanListByOperatorQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPlanListByOperatorQuery, GetPlanListByOperatorQueryVariables>(GetPlanListByOperatorDocument, options);
      }
export function useGetPlanListByOperatorLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPlanListByOperatorQuery, GetPlanListByOperatorQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPlanListByOperatorQuery, GetPlanListByOperatorQueryVariables>(GetPlanListByOperatorDocument, options);
        }
export type GetPlanListByOperatorQueryHookResult = ReturnType<typeof useGetPlanListByOperatorQuery>;
export type GetPlanListByOperatorLazyQueryHookResult = ReturnType<typeof useGetPlanListByOperatorLazyQuery>;
export type GetPlanListByOperatorQueryResult = Apollo.QueryResult<GetPlanListByOperatorQuery, GetPlanListByOperatorQueryVariables>;
export const UpdatePricePairingDocument = gql`
    mutation UpdatePricePairing($input: UpdatePricePairingInput!) {
  updatePricePairing(input: $input) {
    pricePairing {
      id
      roomFeeArray
      personFeeArray
    }
  }
}
    `;
export type UpdatePricePairingMutationFn = Apollo.MutationFunction<UpdatePricePairingMutation, UpdatePricePairingMutationVariables>;

/**
 * __useUpdatePricePairingMutation__
 *
 * To run a mutation, you first call `useUpdatePricePairingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePricePairingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePricePairingMutation, { data, loading, error }] = useUpdatePricePairingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePricePairingMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePricePairingMutation, UpdatePricePairingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePricePairingMutation, UpdatePricePairingMutationVariables>(UpdatePricePairingDocument, options);
      }
export type UpdatePricePairingMutationHookResult = ReturnType<typeof useUpdatePricePairingMutation>;
export type UpdatePricePairingMutationResult = Apollo.MutationResult<UpdatePricePairingMutation>;
export type UpdatePricePairingMutationOptions = Apollo.BaseMutationOptions<UpdatePricePairingMutation, UpdatePricePairingMutationVariables>;
export const GetPricePairingDocument = gql`
    query GetPricePairing($pricePairingId: ID!) {
  pricePairing(id: $pricePairingId) {
    id
    roomFeeArray
    personFeeArray
    plan {
      id
      name
      feeType
    }
    roomType {
      id
      name
      limit
    }
  }
}
    `;

/**
 * __useGetPricePairingQuery__
 *
 * To run a query within a React component, call `useGetPricePairingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPricePairingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPricePairingQuery({
 *   variables: {
 *      pricePairingId: // value for 'pricePairingId'
 *   },
 * });
 */
export function useGetPricePairingQuery(baseOptions: Apollo.QueryHookOptions<GetPricePairingQuery, GetPricePairingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPricePairingQuery, GetPricePairingQueryVariables>(GetPricePairingDocument, options);
      }
export function useGetPricePairingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPricePairingQuery, GetPricePairingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPricePairingQuery, GetPricePairingQueryVariables>(GetPricePairingDocument, options);
        }
export type GetPricePairingQueryHookResult = ReturnType<typeof useGetPricePairingQuery>;
export type GetPricePairingLazyQueryHookResult = ReturnType<typeof useGetPricePairingLazyQuery>;
export type GetPricePairingQueryResult = Apollo.QueryResult<GetPricePairingQuery, GetPricePairingQueryVariables>;
export const GetReservationForEditDocument = gql`
    query GetReservationForEdit($reservationId: ID!) {
  reservation(id: $reservationId) {
    ...ReservationDetailForEdit
  }
}
    ${ReservationDetailForEditFragmentDoc}`;

/**
 * __useGetReservationForEditQuery__
 *
 * To run a query within a React component, call `useGetReservationForEditQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReservationForEditQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReservationForEditQuery({
 *   variables: {
 *      reservationId: // value for 'reservationId'
 *   },
 * });
 */
export function useGetReservationForEditQuery(baseOptions: Apollo.QueryHookOptions<GetReservationForEditQuery, GetReservationForEditQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetReservationForEditQuery, GetReservationForEditQueryVariables>(GetReservationForEditDocument, options);
      }
export function useGetReservationForEditLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetReservationForEditQuery, GetReservationForEditQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetReservationForEditQuery, GetReservationForEditQueryVariables>(GetReservationForEditDocument, options);
        }
export type GetReservationForEditQueryHookResult = ReturnType<typeof useGetReservationForEditQuery>;
export type GetReservationForEditLazyQueryHookResult = ReturnType<typeof useGetReservationForEditLazyQuery>;
export type GetReservationForEditQueryResult = Apollo.QueryResult<GetReservationForEditQuery, GetReservationForEditQueryVariables>;
export const UpdateReservationDocument = gql`
    mutation UpdateReservation($input: UpdateReservationInput!) {
  updateReservation(input: $input) {
    reservation {
      id
      checkIn
      checkInTime
      checkOut
    }
  }
}
    `;
export type UpdateReservationMutationFn = Apollo.MutationFunction<UpdateReservationMutation, UpdateReservationMutationVariables>;

/**
 * __useUpdateReservationMutation__
 *
 * To run a mutation, you first call `useUpdateReservationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateReservationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateReservationMutation, { data, loading, error }] = useUpdateReservationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateReservationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateReservationMutation, UpdateReservationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateReservationMutation, UpdateReservationMutationVariables>(UpdateReservationDocument, options);
      }
export type UpdateReservationMutationHookResult = ReturnType<typeof useUpdateReservationMutation>;
export type UpdateReservationMutationResult = Apollo.MutationResult<UpdateReservationMutation>;
export type UpdateReservationMutationOptions = Apollo.BaseMutationOptions<UpdateReservationMutation, UpdateReservationMutationVariables>;
export const DeleteReservationDocument = gql`
    mutation DeleteReservation($input: DeleteReservationInput!) {
  deleteReservation(input: $input) {
    reservation {
      id
    }
  }
}
    `;
export type DeleteReservationMutationFn = Apollo.MutationFunction<DeleteReservationMutation, DeleteReservationMutationVariables>;

/**
 * __useDeleteReservationMutation__
 *
 * To run a mutation, you first call `useDeleteReservationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteReservationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteReservationMutation, { data, loading, error }] = useDeleteReservationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteReservationMutation(baseOptions?: Apollo.MutationHookOptions<DeleteReservationMutation, DeleteReservationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteReservationMutation, DeleteReservationMutationVariables>(DeleteReservationDocument, options);
      }
export type DeleteReservationMutationHookResult = ReturnType<typeof useDeleteReservationMutation>;
export type DeleteReservationMutationResult = Apollo.MutationResult<DeleteReservationMutation>;
export type DeleteReservationMutationOptions = Apollo.BaseMutationOptions<DeleteReservationMutation, DeleteReservationMutationVariables>;
export const ChangeReservationStatusDocument = gql`
    mutation ChangeReservationStatus($input: ChangeReservationStatusInput!) {
  changeReservationStatus(input: $input)
}
    `;
export type ChangeReservationStatusMutationFn = Apollo.MutationFunction<ChangeReservationStatusMutation, ChangeReservationStatusMutationVariables>;

/**
 * __useChangeReservationStatusMutation__
 *
 * To run a mutation, you first call `useChangeReservationStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeReservationStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeReservationStatusMutation, { data, loading, error }] = useChangeReservationStatusMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChangeReservationStatusMutation(baseOptions?: Apollo.MutationHookOptions<ChangeReservationStatusMutation, ChangeReservationStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangeReservationStatusMutation, ChangeReservationStatusMutationVariables>(ChangeReservationStatusDocument, options);
      }
export type ChangeReservationStatusMutationHookResult = ReturnType<typeof useChangeReservationStatusMutation>;
export type ChangeReservationStatusMutationResult = Apollo.MutationResult<ChangeReservationStatusMutation>;
export type ChangeReservationStatusMutationOptions = Apollo.BaseMutationOptions<ChangeReservationStatusMutation, ChangeReservationStatusMutationVariables>;
export const PaymentReservationChargeDocument = gql`
    mutation PaymentReservationCharge($input: PaymentReservationChargeInput!) {
  paymentReservationCharge(input: $input)
}
    `;
export type PaymentReservationChargeMutationFn = Apollo.MutationFunction<PaymentReservationChargeMutation, PaymentReservationChargeMutationVariables>;

/**
 * __usePaymentReservationChargeMutation__
 *
 * To run a mutation, you first call `usePaymentReservationChargeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePaymentReservationChargeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [paymentReservationChargeMutation, { data, loading, error }] = usePaymentReservationChargeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePaymentReservationChargeMutation(baseOptions?: Apollo.MutationHookOptions<PaymentReservationChargeMutation, PaymentReservationChargeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PaymentReservationChargeMutation, PaymentReservationChargeMutationVariables>(PaymentReservationChargeDocument, options);
      }
export type PaymentReservationChargeMutationHookResult = ReturnType<typeof usePaymentReservationChargeMutation>;
export type PaymentReservationChargeMutationResult = Apollo.MutationResult<PaymentReservationChargeMutation>;
export type PaymentReservationChargeMutationOptions = Apollo.BaseMutationOptions<PaymentReservationChargeMutation, PaymentReservationChargeMutationVariables>;
export const PaymentCancelChargeDocument = gql`
    mutation PaymentCancelCharge($input: PaymentReservationChargeInput!) {
  paymentCancelCharge(input: $input)
}
    `;
export type PaymentCancelChargeMutationFn = Apollo.MutationFunction<PaymentCancelChargeMutation, PaymentCancelChargeMutationVariables>;

/**
 * __usePaymentCancelChargeMutation__
 *
 * To run a mutation, you first call `usePaymentCancelChargeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePaymentCancelChargeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [paymentCancelChargeMutation, { data, loading, error }] = usePaymentCancelChargeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePaymentCancelChargeMutation(baseOptions?: Apollo.MutationHookOptions<PaymentCancelChargeMutation, PaymentCancelChargeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PaymentCancelChargeMutation, PaymentCancelChargeMutationVariables>(PaymentCancelChargeDocument, options);
      }
export type PaymentCancelChargeMutationHookResult = ReturnType<typeof usePaymentCancelChargeMutation>;
export type PaymentCancelChargeMutationResult = Apollo.MutationResult<PaymentCancelChargeMutation>;
export type PaymentCancelChargeMutationOptions = Apollo.BaseMutationOptions<PaymentCancelChargeMutation, PaymentCancelChargeMutationVariables>;
export const CheckInReservationDocument = gql`
    mutation CheckInReservation($input: CheckInReservationInput!) {
  checkInReservation(input: $input)
}
    `;
export type CheckInReservationMutationFn = Apollo.MutationFunction<CheckInReservationMutation, CheckInReservationMutationVariables>;

/**
 * __useCheckInReservationMutation__
 *
 * To run a mutation, you first call `useCheckInReservationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCheckInReservationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [checkInReservationMutation, { data, loading, error }] = useCheckInReservationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCheckInReservationMutation(baseOptions?: Apollo.MutationHookOptions<CheckInReservationMutation, CheckInReservationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CheckInReservationMutation, CheckInReservationMutationVariables>(CheckInReservationDocument, options);
      }
export type CheckInReservationMutationHookResult = ReturnType<typeof useCheckInReservationMutation>;
export type CheckInReservationMutationResult = Apollo.MutationResult<CheckInReservationMutation>;
export type CheckInReservationMutationOptions = Apollo.BaseMutationOptions<CheckInReservationMutation, CheckInReservationMutationVariables>;
export const CancelReservationDocument = gql`
    mutation CancelReservation($input: CancelReservationInput!) {
  cancelReservation(input: $input)
}
    `;
export type CancelReservationMutationFn = Apollo.MutationFunction<CancelReservationMutation, CancelReservationMutationVariables>;

/**
 * __useCancelReservationMutation__
 *
 * To run a mutation, you first call `useCancelReservationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelReservationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelReservationMutation, { data, loading, error }] = useCancelReservationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCancelReservationMutation(baseOptions?: Apollo.MutationHookOptions<CancelReservationMutation, CancelReservationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CancelReservationMutation, CancelReservationMutationVariables>(CancelReservationDocument, options);
      }
export type CancelReservationMutationHookResult = ReturnType<typeof useCancelReservationMutation>;
export type CancelReservationMutationResult = Apollo.MutationResult<CancelReservationMutation>;
export type CancelReservationMutationOptions = Apollo.BaseMutationOptions<CancelReservationMutation, CancelReservationMutationVariables>;
export const GetReservationDocument = gql`
    query GetReservation($reservationId: ID!) {
  reservation(id: $reservationId) {
    id
    stayStatus
    checkIn
    checkInTime
    checkOut
    totalFee
    cancelFee
    adultCount
    childCount
    babyCount
    roomCount
    paymentType
    firstName
    firstNameFurigana
    lastName
    lastNameFurigana
    email
    phoneNumber
    closedAt
    optionSets
    plan {
      ...PlanDetailModal
    }
    roomType {
      ...RoomTypeDetail
    }
    rooms {
      id
      name
    }
    customer {
      ...ReservationCustomerDetail
    }
  }
}
    ${PlanDetailModalFragmentDoc}
${RoomTypeDetailFragmentDoc}
${ReservationCustomerDetailFragmentDoc}`;

/**
 * __useGetReservationQuery__
 *
 * To run a query within a React component, call `useGetReservationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReservationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReservationQuery({
 *   variables: {
 *      reservationId: // value for 'reservationId'
 *   },
 * });
 */
export function useGetReservationQuery(baseOptions: Apollo.QueryHookOptions<GetReservationQuery, GetReservationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetReservationQuery, GetReservationQueryVariables>(GetReservationDocument, options);
      }
export function useGetReservationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetReservationQuery, GetReservationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetReservationQuery, GetReservationQueryVariables>(GetReservationDocument, options);
        }
export type GetReservationQueryHookResult = ReturnType<typeof useGetReservationQuery>;
export type GetReservationLazyQueryHookResult = ReturnType<typeof useGetReservationLazyQuery>;
export type GetReservationQueryResult = Apollo.QueryResult<GetReservationQuery, GetReservationQueryVariables>;
export const CreateReservationByOperatorDocument = gql`
    mutation createReservationByOperator($input: CreateReservationByOperatorInput!) {
  createReservationByOperator(input: $input) {
    reservation {
      id
      checkIn
      checkInTime
      checkOut
      totalFee
    }
  }
}
    `;
export type CreateReservationByOperatorMutationFn = Apollo.MutationFunction<CreateReservationByOperatorMutation, CreateReservationByOperatorMutationVariables>;

/**
 * __useCreateReservationByOperatorMutation__
 *
 * To run a mutation, you first call `useCreateReservationByOperatorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateReservationByOperatorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createReservationByOperatorMutation, { data, loading, error }] = useCreateReservationByOperatorMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateReservationByOperatorMutation(baseOptions?: Apollo.MutationHookOptions<CreateReservationByOperatorMutation, CreateReservationByOperatorMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateReservationByOperatorMutation, CreateReservationByOperatorMutationVariables>(CreateReservationByOperatorDocument, options);
      }
export type CreateReservationByOperatorMutationHookResult = ReturnType<typeof useCreateReservationByOperatorMutation>;
export type CreateReservationByOperatorMutationResult = Apollo.MutationResult<CreateReservationByOperatorMutation>;
export type CreateReservationByOperatorMutationOptions = Apollo.BaseMutationOptions<CreateReservationByOperatorMutation, CreateReservationByOperatorMutationVariables>;
export const CreateReservationsOnRoomsDocument = gql`
    mutation CreateReservationsOnRooms($input: CreateReservationsOnRoomsInput!) {
  createReservationsOnRooms(input: $input)
}
    `;
export type CreateReservationsOnRoomsMutationFn = Apollo.MutationFunction<CreateReservationsOnRoomsMutation, CreateReservationsOnRoomsMutationVariables>;

/**
 * __useCreateReservationsOnRoomsMutation__
 *
 * To run a mutation, you first call `useCreateReservationsOnRoomsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateReservationsOnRoomsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createReservationsOnRoomsMutation, { data, loading, error }] = useCreateReservationsOnRoomsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateReservationsOnRoomsMutation(baseOptions?: Apollo.MutationHookOptions<CreateReservationsOnRoomsMutation, CreateReservationsOnRoomsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateReservationsOnRoomsMutation, CreateReservationsOnRoomsMutationVariables>(CreateReservationsOnRoomsDocument, options);
      }
export type CreateReservationsOnRoomsMutationHookResult = ReturnType<typeof useCreateReservationsOnRoomsMutation>;
export type CreateReservationsOnRoomsMutationResult = Apollo.MutationResult<CreateReservationsOnRoomsMutation>;
export type CreateReservationsOnRoomsMutationOptions = Apollo.BaseMutationOptions<CreateReservationsOnRoomsMutation, CreateReservationsOnRoomsMutationVariables>;
export const UpdateReservationsOnRoomsDocument = gql`
    mutation UpdateReservationsOnRooms($input: UpdateReservationsOnRoomsInput!) {
  updateReservationsOnRooms(input: $input)
}
    `;
export type UpdateReservationsOnRoomsMutationFn = Apollo.MutationFunction<UpdateReservationsOnRoomsMutation, UpdateReservationsOnRoomsMutationVariables>;

/**
 * __useUpdateReservationsOnRoomsMutation__
 *
 * To run a mutation, you first call `useUpdateReservationsOnRoomsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateReservationsOnRoomsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateReservationsOnRoomsMutation, { data, loading, error }] = useUpdateReservationsOnRoomsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateReservationsOnRoomsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateReservationsOnRoomsMutation, UpdateReservationsOnRoomsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateReservationsOnRoomsMutation, UpdateReservationsOnRoomsMutationVariables>(UpdateReservationsOnRoomsDocument, options);
      }
export type UpdateReservationsOnRoomsMutationHookResult = ReturnType<typeof useUpdateReservationsOnRoomsMutation>;
export type UpdateReservationsOnRoomsMutationResult = Apollo.MutationResult<UpdateReservationsOnRoomsMutation>;
export type UpdateReservationsOnRoomsMutationOptions = Apollo.BaseMutationOptions<UpdateReservationsOnRoomsMutation, UpdateReservationsOnRoomsMutationVariables>;
export const GetReservationListDocument = gql`
    query GetReservationList($hotelId: ID!, $limit: Int, $offset: Int, $checkIn: Timestamp, $checkOut: Timestamp, $status: StayStatusType) {
  hotel(id: $hotelId) {
    id
    bankAccount {
      id
      bankCode
      bankName
      branchCode
      branchName
      accountNumber
      accountHolderName
    }
    paymentMethodsForHotel {
      id
      brand
      last4
      stripeId
    }
    reservationList(
      limit: $limit
      offset: $offset
      checkIn: $checkIn
      checkOut: $checkOut
      status: $status
    ) {
      total
      items {
        id
        checkIn
        checkOut
        firstName
        lastName
        stayStatus
        roomCount
        paymentType
        plan {
          id
          name
        }
        totalFee
        adultCount
        childCount
        babyCount
        createdAt
        customer {
          id
          firstName
          firstNameFurigana
          lastName
          lastNameFurigana
        }
      }
    }
  }
}
    `;

/**
 * __useGetReservationListQuery__
 *
 * To run a query within a React component, call `useGetReservationListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReservationListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReservationListQuery({
 *   variables: {
 *      hotelId: // value for 'hotelId'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      checkIn: // value for 'checkIn'
 *      checkOut: // value for 'checkOut'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useGetReservationListQuery(baseOptions: Apollo.QueryHookOptions<GetReservationListQuery, GetReservationListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetReservationListQuery, GetReservationListQueryVariables>(GetReservationListDocument, options);
      }
export function useGetReservationListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetReservationListQuery, GetReservationListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetReservationListQuery, GetReservationListQueryVariables>(GetReservationListDocument, options);
        }
export type GetReservationListQueryHookResult = ReturnType<typeof useGetReservationListQuery>;
export type GetReservationListLazyQueryHookResult = ReturnType<typeof useGetReservationListLazyQuery>;
export type GetReservationListQueryResult = Apollo.QueryResult<GetReservationListQuery, GetReservationListQueryVariables>;
export const UpdateRoomTypeDocument = gql`
    mutation UpdateRoomType($updateRoomTypeInput: UpdateRoomTypeInput!) {
  updateRoomType(input: $updateRoomTypeInput) {
    roomType {
      id
      name
      description
      images
      isPublic
      limit
      roomSize
      canSmoking
      bedType
      bedNum
      amenityCodes
    }
  }
}
    `;
export type UpdateRoomTypeMutationFn = Apollo.MutationFunction<UpdateRoomTypeMutation, UpdateRoomTypeMutationVariables>;

/**
 * __useUpdateRoomTypeMutation__
 *
 * To run a mutation, you first call `useUpdateRoomTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRoomTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRoomTypeMutation, { data, loading, error }] = useUpdateRoomTypeMutation({
 *   variables: {
 *      updateRoomTypeInput: // value for 'updateRoomTypeInput'
 *   },
 * });
 */
export function useUpdateRoomTypeMutation(baseOptions?: Apollo.MutationHookOptions<UpdateRoomTypeMutation, UpdateRoomTypeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateRoomTypeMutation, UpdateRoomTypeMutationVariables>(UpdateRoomTypeDocument, options);
      }
export type UpdateRoomTypeMutationHookResult = ReturnType<typeof useUpdateRoomTypeMutation>;
export type UpdateRoomTypeMutationResult = Apollo.MutationResult<UpdateRoomTypeMutation>;
export type UpdateRoomTypeMutationOptions = Apollo.BaseMutationOptions<UpdateRoomTypeMutation, UpdateRoomTypeMutationVariables>;
export const DeleteRoomTypeDocument = gql`
    mutation DeleteRoomType($deleteRoomTypeInput: DeleteRoomTypeInput!) {
  deleteRoomType(input: $deleteRoomTypeInput) {
    roomType {
      id
    }
  }
}
    `;
export type DeleteRoomTypeMutationFn = Apollo.MutationFunction<DeleteRoomTypeMutation, DeleteRoomTypeMutationVariables>;

/**
 * __useDeleteRoomTypeMutation__
 *
 * To run a mutation, you first call `useDeleteRoomTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRoomTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRoomTypeMutation, { data, loading, error }] = useDeleteRoomTypeMutation({
 *   variables: {
 *      deleteRoomTypeInput: // value for 'deleteRoomTypeInput'
 *   },
 * });
 */
export function useDeleteRoomTypeMutation(baseOptions?: Apollo.MutationHookOptions<DeleteRoomTypeMutation, DeleteRoomTypeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteRoomTypeMutation, DeleteRoomTypeMutationVariables>(DeleteRoomTypeDocument, options);
      }
export type DeleteRoomTypeMutationHookResult = ReturnType<typeof useDeleteRoomTypeMutation>;
export type DeleteRoomTypeMutationResult = Apollo.MutationResult<DeleteRoomTypeMutation>;
export type DeleteRoomTypeMutationOptions = Apollo.BaseMutationOptions<DeleteRoomTypeMutation, DeleteRoomTypeMutationVariables>;
export const GetRoomTypeDocument = gql`
    query GetRoomType($roomTypeId: ID!) {
  roomType(id: $roomTypeId) {
    ...RoomTypeDetail
  }
}
    ${RoomTypeDetailFragmentDoc}`;

/**
 * __useGetRoomTypeQuery__
 *
 * To run a query within a React component, call `useGetRoomTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRoomTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRoomTypeQuery({
 *   variables: {
 *      roomTypeId: // value for 'roomTypeId'
 *   },
 * });
 */
export function useGetRoomTypeQuery(baseOptions: Apollo.QueryHookOptions<GetRoomTypeQuery, GetRoomTypeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRoomTypeQuery, GetRoomTypeQueryVariables>(GetRoomTypeDocument, options);
      }
export function useGetRoomTypeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRoomTypeQuery, GetRoomTypeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRoomTypeQuery, GetRoomTypeQueryVariables>(GetRoomTypeDocument, options);
        }
export type GetRoomTypeQueryHookResult = ReturnType<typeof useGetRoomTypeQuery>;
export type GetRoomTypeLazyQueryHookResult = ReturnType<typeof useGetRoomTypeLazyQuery>;
export type GetRoomTypeQueryResult = Apollo.QueryResult<GetRoomTypeQuery, GetRoomTypeQueryVariables>;
export const CreateRoomTypeDocument = gql`
    mutation CreateRoomType($createRoomTypeInput: CreateRoomTypeInput!) {
  createRoomType(input: $createRoomTypeInput) {
    roomType {
      id
      name
      description
      images
      isPublic
      limit
      roomSize
      canSmoking
      bedType
      bedNum
    }
  }
}
    `;
export type CreateRoomTypeMutationFn = Apollo.MutationFunction<CreateRoomTypeMutation, CreateRoomTypeMutationVariables>;

/**
 * __useCreateRoomTypeMutation__
 *
 * To run a mutation, you first call `useCreateRoomTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRoomTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRoomTypeMutation, { data, loading, error }] = useCreateRoomTypeMutation({
 *   variables: {
 *      createRoomTypeInput: // value for 'createRoomTypeInput'
 *   },
 * });
 */
export function useCreateRoomTypeMutation(baseOptions?: Apollo.MutationHookOptions<CreateRoomTypeMutation, CreateRoomTypeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateRoomTypeMutation, CreateRoomTypeMutationVariables>(CreateRoomTypeDocument, options);
      }
export type CreateRoomTypeMutationHookResult = ReturnType<typeof useCreateRoomTypeMutation>;
export type CreateRoomTypeMutationResult = Apollo.MutationResult<CreateRoomTypeMutation>;
export type CreateRoomTypeMutationOptions = Apollo.BaseMutationOptions<CreateRoomTypeMutation, CreateRoomTypeMutationVariables>;
export const GetRoomTypeListDocument = gql`
    query GetRoomTypeList($hotelId: ID!, $limit: Int, $offset: Int) {
  hotel(id: $hotelId) {
    id
    roomTypeList(limit: $limit, offset: $offset) {
      items {
        id
        name
        isPublic
        limit
        bedNum
        roomSize
        amenityCodes
        canSmoking
      }
      total
    }
  }
}
    `;

/**
 * __useGetRoomTypeListQuery__
 *
 * To run a query within a React component, call `useGetRoomTypeListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRoomTypeListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRoomTypeListQuery({
 *   variables: {
 *      hotelId: // value for 'hotelId'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetRoomTypeListQuery(baseOptions: Apollo.QueryHookOptions<GetRoomTypeListQuery, GetRoomTypeListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRoomTypeListQuery, GetRoomTypeListQueryVariables>(GetRoomTypeListDocument, options);
      }
export function useGetRoomTypeListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRoomTypeListQuery, GetRoomTypeListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRoomTypeListQuery, GetRoomTypeListQueryVariables>(GetRoomTypeListDocument, options);
        }
export type GetRoomTypeListQueryHookResult = ReturnType<typeof useGetRoomTypeListQuery>;
export type GetRoomTypeListLazyQueryHookResult = ReturnType<typeof useGetRoomTypeListLazyQuery>;
export type GetRoomTypeListQueryResult = Apollo.QueryResult<GetRoomTypeListQuery, GetRoomTypeListQueryVariables>;
export const UpdateRoomDocument = gql`
    mutation UpdateRoom($updateRoomInput: UpdateRoomInput!) {
  updateRoom(input: $updateRoomInput) {
    room {
      id
      name
      roomType {
        id
        name
      }
    }
  }
}
    `;
export type UpdateRoomMutationFn = Apollo.MutationFunction<UpdateRoomMutation, UpdateRoomMutationVariables>;

/**
 * __useUpdateRoomMutation__
 *
 * To run a mutation, you first call `useUpdateRoomMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRoomMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRoomMutation, { data, loading, error }] = useUpdateRoomMutation({
 *   variables: {
 *      updateRoomInput: // value for 'updateRoomInput'
 *   },
 * });
 */
export function useUpdateRoomMutation(baseOptions?: Apollo.MutationHookOptions<UpdateRoomMutation, UpdateRoomMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateRoomMutation, UpdateRoomMutationVariables>(UpdateRoomDocument, options);
      }
export type UpdateRoomMutationHookResult = ReturnType<typeof useUpdateRoomMutation>;
export type UpdateRoomMutationResult = Apollo.MutationResult<UpdateRoomMutation>;
export type UpdateRoomMutationOptions = Apollo.BaseMutationOptions<UpdateRoomMutation, UpdateRoomMutationVariables>;
export const DeleteRoomDocument = gql`
    mutation DeleteRoom($deleteRoomInput: DeleteRoomInput!) {
  deleteRoom(input: $deleteRoomInput) {
    room {
      id
      name
    }
  }
}
    `;
export type DeleteRoomMutationFn = Apollo.MutationFunction<DeleteRoomMutation, DeleteRoomMutationVariables>;

/**
 * __useDeleteRoomMutation__
 *
 * To run a mutation, you first call `useDeleteRoomMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRoomMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRoomMutation, { data, loading, error }] = useDeleteRoomMutation({
 *   variables: {
 *      deleteRoomInput: // value for 'deleteRoomInput'
 *   },
 * });
 */
export function useDeleteRoomMutation(baseOptions?: Apollo.MutationHookOptions<DeleteRoomMutation, DeleteRoomMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteRoomMutation, DeleteRoomMutationVariables>(DeleteRoomDocument, options);
      }
export type DeleteRoomMutationHookResult = ReturnType<typeof useDeleteRoomMutation>;
export type DeleteRoomMutationResult = Apollo.MutationResult<DeleteRoomMutation>;
export type DeleteRoomMutationOptions = Apollo.BaseMutationOptions<DeleteRoomMutation, DeleteRoomMutationVariables>;
export const GetRoomDocument = gql`
    query GetRoom($roomId: ID!) {
  room(id: $roomId) {
    ...RoomDetail
  }
}
    ${RoomDetailFragmentDoc}`;

/**
 * __useGetRoomQuery__
 *
 * To run a query within a React component, call `useGetRoomQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRoomQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRoomQuery({
 *   variables: {
 *      roomId: // value for 'roomId'
 *   },
 * });
 */
export function useGetRoomQuery(baseOptions: Apollo.QueryHookOptions<GetRoomQuery, GetRoomQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRoomQuery, GetRoomQueryVariables>(GetRoomDocument, options);
      }
export function useGetRoomLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRoomQuery, GetRoomQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRoomQuery, GetRoomQueryVariables>(GetRoomDocument, options);
        }
export type GetRoomQueryHookResult = ReturnType<typeof useGetRoomQuery>;
export type GetRoomLazyQueryHookResult = ReturnType<typeof useGetRoomLazyQuery>;
export type GetRoomQueryResult = Apollo.QueryResult<GetRoomQuery, GetRoomQueryVariables>;
export const CreateManyRoomDocument = gql`
    mutation CreateManyRoom($input: [CreateRoomInput!]) {
  createManyRoom(input: $input)
}
    `;
export type CreateManyRoomMutationFn = Apollo.MutationFunction<CreateManyRoomMutation, CreateManyRoomMutationVariables>;

/**
 * __useCreateManyRoomMutation__
 *
 * To run a mutation, you first call `useCreateManyRoomMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateManyRoomMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createManyRoomMutation, { data, loading, error }] = useCreateManyRoomMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateManyRoomMutation(baseOptions?: Apollo.MutationHookOptions<CreateManyRoomMutation, CreateManyRoomMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateManyRoomMutation, CreateManyRoomMutationVariables>(CreateManyRoomDocument, options);
      }
export type CreateManyRoomMutationHookResult = ReturnType<typeof useCreateManyRoomMutation>;
export type CreateManyRoomMutationResult = Apollo.MutationResult<CreateManyRoomMutation>;
export type CreateManyRoomMutationOptions = Apollo.BaseMutationOptions<CreateManyRoomMutation, CreateManyRoomMutationVariables>;
export const GetRoomListDocument = gql`
    query GetRoomList($hotelId: ID!, $limit: Int, $offset: Int) {
  hotel(id: $hotelId) {
    id
    jammyPlanSubscription {
      id
      jammyPlan {
        id
        roomLimit
      }
    }
    roomList(limit: $limit, offset: $offset) {
      items {
        id
        name
        roomType {
          id
          name
        }
      }
      total
    }
  }
}
    `;

/**
 * __useGetRoomListQuery__
 *
 * To run a query within a React component, call `useGetRoomListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRoomListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRoomListQuery({
 *   variables: {
 *      hotelId: // value for 'hotelId'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetRoomListQuery(baseOptions: Apollo.QueryHookOptions<GetRoomListQuery, GetRoomListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRoomListQuery, GetRoomListQueryVariables>(GetRoomListDocument, options);
      }
export function useGetRoomListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRoomListQuery, GetRoomListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRoomListQuery, GetRoomListQueryVariables>(GetRoomListDocument, options);
        }
export type GetRoomListQueryHookResult = ReturnType<typeof useGetRoomListQuery>;
export type GetRoomListLazyQueryHookResult = ReturnType<typeof useGetRoomListLazyQuery>;
export type GetRoomListQueryResult = Apollo.QueryResult<GetRoomListQuery, GetRoomListQueryVariables>;
export const GetHotelSubscriptionDocument = gql`
    query GetHotelSubscription($hotelId: ID!) {
  hotel(id: $hotelId) {
    id
    jammyPlanSubscription {
      id
      jammyPlan {
        id
        name
        operatorLimit
        roomLimit
        description
        fee
      }
    }
    paymentMethodsForHotel {
      id
      brand
      last4
    }
  }
}
    `;

/**
 * __useGetHotelSubscriptionQuery__
 *
 * To run a query within a React component, call `useGetHotelSubscriptionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHotelSubscriptionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHotelSubscriptionQuery({
 *   variables: {
 *      hotelId: // value for 'hotelId'
 *   },
 * });
 */
export function useGetHotelSubscriptionQuery(baseOptions: Apollo.QueryHookOptions<GetHotelSubscriptionQuery, GetHotelSubscriptionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetHotelSubscriptionQuery, GetHotelSubscriptionQueryVariables>(GetHotelSubscriptionDocument, options);
      }
export function useGetHotelSubscriptionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetHotelSubscriptionQuery, GetHotelSubscriptionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetHotelSubscriptionQuery, GetHotelSubscriptionQueryVariables>(GetHotelSubscriptionDocument, options);
        }
export type GetHotelSubscriptionQueryHookResult = ReturnType<typeof useGetHotelSubscriptionQuery>;
export type GetHotelSubscriptionLazyQueryHookResult = ReturnType<typeof useGetHotelSubscriptionLazyQuery>;
export type GetHotelSubscriptionQueryResult = Apollo.QueryResult<GetHotelSubscriptionQuery, GetHotelSubscriptionQueryVariables>;