const routes = {
  home: '/',
  login: '/login',
  lp: 'https://service.jammy.jp/',
  initialSetting: '/initial-setting',
  rooms: {
    index: '/rooms',
    detail: '/rooms/[roomId]',
    newMany: '/rooms/new-many',
    edit: '/rooms/[roomId]/edit',
  },
  reservations: {
    index: '/reservations',
    detail: '/reservations/[reservationId]',
    new: '/reservations/new',
    edit: '/reservations/[reservationId]/edit',
  },
  roomTypes: {
    index: '/room-types',
    detail: '/room-types/[roomTypeId]',
    new: '/room-types/new',
    edit: '/room-types/[roomTypeId]/edit',
  },
  operators: {
    index: '/operators',
    detail: '/operators/[operatorId]',
    register: '/companies/[companyId]/register',
    new: '/companies/[companyId]/operators/new',
    regularRegister: '/companies/[companyId]/hotels/[hotelId]/register',
    regularNew: '/companies/[companyId]/hotels/[hotelId]/regular-operators/new',
    edit: '/operators/edit',
  },
  plans: {
    index: '/plans',
    detail: '/plans/[planId]',
    new: '/plans/new',
    edit: '/plans/[planId]/edit',
  },
  plansOnOptions: {
    new: '/plans/[planId]/plans-on-options/new',
  },
  pricePairings: {
    new: '/plans/[planId]/price-pairings/new',
  },
  roomFees: {
    detail: '/price-pairings/[pricePairingId]/room-fees',
  },
  personFees: {
    detail: '/price-pairings/[pricePairingId]/person-fees',
  },
  customers: {
    index: '/customers',
    detail: '/customers/[customerId]',
  },
  options: {
    index: '/options',
    detail: '/options/[optionId]',
    new: '/options/new',
    edit: '/options/[optionId]/edit',
  },
  monthlyIncomes: {
    index: '/monthly-incomes',
    detail: '/monthly-incomes/[month]',
  },
  bankPayout: '/bank-payout',
  hotelSetting: {
    edit: '/hotel-setting/edit',
    paymentMethod: '/hotel-setting/payment-method',
    bankAccount: {
      detail: '/hotel-setting/bank-account',
      // 口座編集は未実装
      // edit: '/hotel-setting/bank-account/edit',
      stripeCompany: '/hotel-setting/bank-account/company',
      stripePerson: '/hotel-setting/bank-account/person',
    },
  },
  subscription: {
    detail: '/subscriptions',
  },
  invoice: {
    detail: '/invoices/[invoiceId]',
  },
  setting: { index: '/setting' },
  marketing: { index: '/marketing' },
  terms: 'terms',
  privacy: 'privacy',
  lawTokutei: 'law-tokutei',
}

export default routes
